import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';
import * as tableData from '../../assets/generated.json';
import { User } from '../model/user';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ServiceCapabilitiesDialogComponent } from '../service-capabilities-dialog/service-capabilities-dialog.component';
@Component({
  selector: 'app-manage-account',
  standalone: true,
  imports: [CommonModule,RouterLink ,ServiceCapabilitiesDialogComponent],
  templateUrl: './manage-account.component.html',
  styleUrl: './manage-account.component.scss',
})
export class ManageAccountComponent {
  id: string = '';
  user: User = {};
  fullName: string = '';
  projectName: string = '';
  tabState: string = 'rfp';

  activeTab: string = 'profile';

  activeBreadcrumb: any = [false, false, false, false, false];

  currentConversation: string = '';

  serviceCapabilitiesActive: boolean = false;
  serviceCapabilities: any = '';
  serviceCapabilitiesType: string = '';
  editNameToggle:boolean=false;
  editEmailToggle:boolean=false;
  editPhoneToggle:boolean=false;
  editAddressToggle:boolean=false;
  editCCInfoToggle:boolean=false;
editOrgNameToggle:boolean=false;
editOrgPhoneToggle:boolean=false;
editOrgEmailToggle:boolean=false;
editOrgAddressToggle:boolean=false;
editOrgAdditionalInfoToggle:boolean=false;
editZoneAndCapabilitiesToggle:boolean=false;
editZoneToggle:boolean=false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public router: Router
  ) {}

  ngOnInit(): void {
    // set tab state from url
    this.route.queryParams.subscribe((params) => {
      this.activeTab = params['tab'] || this.activeTab;
    });
    this.user = <User>JSON.parse(localStorage.getItem('user') ?? '');
    this.fullName = this.user.firstName + ' ' + this.user.lastName;
  }

  goBack() {
    window.history.back();
  }

  changeConversation(conversation: string) {
    this.currentConversation = conversation;
  }

  toggleTab(tab: string) {
    this.activeTab = tab;

    // add selected tab to end of url without adding to history
    this.location.replaceState(
      this.location.path().split('?')[0],
      `tab=${tab}`
    );
  }

  toggleServiceCapabilitiesModal() {
    this.serviceCapabilitiesActive = !this.serviceCapabilitiesActive;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
  }

  editName(){
    this.editNameToggle=!this.editNameToggle;
  }

  editEmail(){
    this.editEmailToggle=!this.editEmailToggle;
  }

  editPhone(){
    this.editPhoneToggle=!this.editPhoneToggle;
  }

  editCCInfo(){
    this.editCCInfoToggle=!this.editCCInfoToggle;
  }

editAddress(){
  this.editAddressToggle=!this.editAddressToggle;
}

  editOrgName(){
this.editOrgNameToggle=!this.editOrgNameToggle;
  }
  editOrgPhone(){
    this.editOrgPhoneToggle=!this.editOrgPhoneToggle;
  }
  editOrgEmail(){
    this.editOrgEmailToggle=!this.editOrgEmailToggle;
  }
  editOrgAddress(){
    this.editOrgAddressToggle=!this.editOrgAddressToggle;
  }
  editOrgAdditionalInfo(){
    this.editOrgAdditionalInfoToggle=!this.editOrgAdditionalInfoToggle;
  }

  editZoneAndCapabilities(){
this.editZoneAndCapabilitiesToggle=!this.editZoneAndCapabilitiesToggle;
  }

  editZone(){
this.editZoneToggle=!this.editZoneToggle;
  }

  saveName(){
    alert('Name saved successfully.');
    this.editNameToggle=!this.editNameToggle;
  }

  savePhone(){
    alert('Phone saved successfully.');
    this.editPhoneToggle=!this.editPhoneToggle;
  }
  
  saveEmail(){
    alert('Email saved successfully.');
    this.editEmailToggle=!this.editEmailToggle;
  }
saveAddress(){
    alert('Address saved successfully.');
    this.editAddressToggle=!this.editAddressToggle;
  }

  saveCCInfo(){
    alert('CCInfo saved successfully.');
    this.editCCInfoToggle=!this.editCCInfoToggle;
  }
  
  saveOrgName(){
    alert('Organization name saved successfully.');
    this.editOrgNameToggle=!this.editOrgNameToggle;
  }
saveOrgPhone(){
  alert('Organization phone saved successfully.');
    this.editOrgPhoneToggle=!this.editOrgPhoneToggle;
}

saveOrgEmail(){
  alert('Organization email saved successfully.');
    this.editOrgEmailToggle=!this.editOrgEmailToggle;
}

saveOrgAddress(){
  alert('Organization address saved successfully.');
    this.editOrgAddressToggle=!this.editOrgAddressToggle;
}

 saveOrgAdditionalInfo(){
  alert('Organization additional information saved successfully.');
    this.editOrgAdditionalInfoToggle=!this.editOrgAdditionalInfoToggle;
}

saveZoneAndCapabilities(){
  alert('Zone and capabilities saved successfully.')
  this.editZoneAndCapabilitiesToggle=!this.editZoneAndCapabilitiesToggle;
    }

    saveZone(){
      alert('Zone saved successfully.')
      this.editZoneToggle=!this.editZoneToggle;
        }
        
        deleteDocument(documentId:any){
        alert('Document deleted successfully.');
        }

        downloadDocument(documentPath:string){
        alert('Document downloaded successfully.');
        }
        uploadProfilePic(){
          alert('Profile picture uploaded successfully.');
        }
  //sample object, please remove this code while binding with original data/objects
  organization={
    name: "TestOrganization LLC.",
    email:"s.rogers-testorganization.com",
    phoneNumber:"(987)654-3210",
    phoneOS:"iOS",
    description:"Test Account Only",
    Website:"https://testorganizations.com",
    address: {
      street: "212 Judah st.",
      city: "Roseville",
      state: "California",
      country: "United States",
      zipCode: "95678"
    }
  }

  //sample object, please remove this code while binding with original data/objects
 documents= {
    "profile": {
      "id": "12345",
      "zones": [
        {
          "name": "Nevada",
          "zone": [
            {
              id:1,
              "name": "CrR_TestFile_1.pdf",
              "path": "https://test.creconnects.com/CrR_TestFile_1.pdf"
            },
            {
              id:2,
              "name": "CrR_TestFile_2.csv",
              "path": "https://test.creconnects.com/CrR_TestFile_2.csv"
            },
            {
              id:3,
              "name": "CrR_TestFile_3.tiff",
              "path": "https://test.creconnects.com/CrR_TestFile_3.tiff"
            },
            {
              id:4,
              "name": "CrR_TestFile_4.pdf",
              "path": "https://test.creconnects.com/CrR_TestFile_4.pdf"
            },
            {
              id:5,
              "name": "CrR_TestFile_5.pdf",
              "path": "https://test.creconnects.com/CrR_TestFile_5.pdf"
            }
          ]
        },
        {
          "name": "California",
          "zone": [
            {
              id:11,
              "name": "CrR_TestFile_1.pdf",
              "path": "https://test.creconnects.com/CrR_TestFile_1.pdf"
            },
            {
              id:12,
              "name": "CrR_TestFile_2.tiff",
              "path": "https://test.creconnects.com/CrR_TestFile_2.tiff"
            },
            {
              id:13,
              "name": "CrR_TestFile_3.csv",
              "path": "https://test.creconnects.com/CrR_TestFile_3.csv"
            },
            {
              id:14,
              "name": "CrR_TestFile_4.pdf",
              "path": "https://test.creconnects.com/CrR_TestFile_4.pdf"
            },
            {
              id:15,
              "name": "CrR_TestFile_5",
              "path": "https://test.creconnects.com/CrR_TestFile_5.pdf"
            }
          ]
        }
      ],
      "profileDocuments": [
        {
          id:21,
          "name": "CrR_TestFile_1.pdf",
          "path": "https://test.creconnects.com/CrR_TestFile_1.pdf"
        },
        {
          id:22,
          "name": "CrR_TestFile_2.csv",
          "path": "https://test.creconnects.com/CrR_TestFile_2.csv"
        },
        {
          id:23,
          "name": "CrR_TestFile_3.tiff",
          "path": "https://test.creconnects.com/CrR_TestFile_3.tiff"
        },
        {
          id:24,
          "name": "CrR_TestFile_4.pdf",
          "path": "https://test.creconnects.com/CrR_TestFile_4.pdf"
        },
        {
          id:25,
          "name": "CrR_TestFile_5.pdf",
          "path": "https://test.creconnects.com/CrR_TestFile_5.pdf"
        }
      ]
    }
  }
}
