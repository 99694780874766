import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'decline-success',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './decline-success.component.html',
  styleUrl: './decline-success.component.scss',
})
export class DeclineSuccessComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    this.closeDialog();
  }
}
