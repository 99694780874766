<div class="documents-dialog" *ngIf="active">
    <div class="documents-dialog__content">
        <button class="documents-dialog__content__close" (click)="closeDialog()"><span
                class="material-icons">close</span>Close</button>
        <h2>Bid Submitted!</h2>
        <ng-container>
            <div class="">
                <div class="mb-10">Bid you have been submitted and will be placed in <strong>proposal</strong>
                    status.
                </div>
                <br>
                <br>
                <div class="panelfields mt-30">
                    <button type="submit" class="btn btn-primary w-25" routerLink="/proposals"
                        routerLinkActive="router-link-active">View Proposals</button>
                    <button type="submit" class="btn btn-default w-25" (click)="closeDialog()">Back</button>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>