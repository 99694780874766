import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';

@Component({
	standalone: true,
  selector: 'page-header',
	imports: [RouterLink, CommonModule,NotificationDialogComponent],
  templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class Header{
	notificationsActive: boolean = false;

	calendar(){
		alert('calendar');
	}

	notifications(){
		alert('notifications');
	}

	myAccount(){
		alert('myAccount');
	}

	search(){
		alert('search');
	}
	
	toggleNotificationsModal() {
		this.notificationsActive = !this.notificationsActive;
	  }
}
