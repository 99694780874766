<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>Sign ATP</h2>
    <iframe [src]="atpDocumentPath" width="100%" height="550px"></iframe>
    <ng-container>
      <h3>e-Signature</h3>

      <div class=" ">
        <div class="signup-body mb-10">
          Typing your name as it exactly appears below signifies you are
          completing this form using an electronic signature. By electronically
          signing, you are certifying that you have read and understood
          Cretelligent’s Terms and Conditions shown above.
        </div>
        <form [formGroup]="signedAtpDetails">
          <div class="form-control mt-20">
            <label for="fullName" class="form-label"
              >Full Name <span class="req">* </span
              ><span
                *ngIf="
                  signedAtpDetails.controls['fullName']?.invalid &&
                  (signedAtpDetails.controls['fullName'].dirty ||
                    signedAtpDetails.controls['fullName'].touched)
                "
              >
                <span
                  *ngIf="signedAtpDetails.controls['fullName'].errors?.['required']"
                  class="error-message"
                >
                  Name is required.
                </span>
              </span></label
            ><br />
            <input
              type="text"
              class="emailinput"
              id="fullName"
              formControlName="fullName"
            />
          </div>

          <div class="create-form">
            <div class="form-control">
              <label for="title" class="form-label"
                >Job Title
                <span class="req">*</span>
                <span
                  *ngIf="
                    signedAtpDetails.controls['title']?.invalid &&
                    (signedAtpDetails.controls['title'].dirty ||
                      signedAtpDetails.controls['title'].touched)
                  "
                >
                  <span
                    *ngIf="signedAtpDetails.controls['title'].errors?.['required']"
                    class="error-message"
                  >
                    Title is required.
                  </span>
                </span> </label
              ><br />
              <input
                type="text"
                class="fname"
                id="title"
                formControlName="title"
              />
            </div>
            <div class="form-control" style="margin-left: 10px">
              <label for="signedDate" class="form-label">Date of Signing</label>
              <br />
              <input
                disabled
                [value]="formattedDate"
                type="text"
                class="dateofsign"
                id="signedDate"
              />
            </div>
          </div>

          <div class="panelfields">
            <button
              type="submit"
              class="btn btn-primary w-25"
              (click)="submit()"
              [disabled]="disableSubmitButton"
            >
              Agree & Sign Electronically
            </button>
            <button
              type="submit"
              style="width: 15%"
              class="btn btn-default w-25"
              (click)="toggleDeclineModal()"
            >
              Decline
            </button>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>

<decline-dialog
  [active]="isDeclineActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleDeclineModal()"
  (decline)="declineAtp($event)"
  [ngClass]="{ 'documents-dialog--active': isDeclineActive }"
></decline-dialog>
