import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'project-special-request-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-special-request-dialog.component.html',
  styleUrl: './project-special-request-dialog.component.scss',
})
export class ProjectSpecialRequestDialogComponent {
  @Input() active: boolean = false;
  @Input() projectName: string = '';
  @Input() specialQuoteRequirements: string = '';

  closeDialog = new EventEmitter<boolean>();

  close() {
    this.active = false;
    this.closeDialog.emit(false);
  }
}
