import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ProposalService } from './proposal.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  uploadPropsalDocuments(documents: any, proposal: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'proposal',
      new Blob([JSON.stringify(proposal)], {
        type: 'application/json',
      })
    );
    documents.forEach((docRequest: any) =>
      formData.append('files', docRequest.file)
    );
    return this.http.post<any>(
      environment.apiUrl + '/document/proposal',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  deleteProposalDocument(documentId: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.delete<any>(
      environment.apiUrl + `/document/proposal?documentId=${documentId}`,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  downloadDocument(downloadDocument: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    if (downloadDocument.type === 'ATP' && downloadDocument.documentPath) {
      window.open(downloadDocument.documentPath, '_blank');
      return;
    } else {
      return this.http
        .get<any>(
          environment.apiUrl +
            `/document/download?documentId=${downloadDocument.id}`,
          {
            headers,
            responseType: 'blob' as 'json',
          }
        )
        .subscribe((blob) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = downloadDocument.fileName;
          a.click();
          URL.revokeObjectURL(objectUrl);
          setTimeout(() => {
            window.URL.revokeObjectURL(objectUrl);
          }, 100);
        });
    }
  }
}
