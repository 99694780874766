import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';
import { SubmitForReviewDialogComponent } from '../submit-for-review-dialog/submit-for-review-dialog.component';
import { CommentsComponent } from '../comments/comments.component';
import { BidSavedDialogComponent } from '../bid-saved-dialog/bid-saved-dialog.component';
import { MapmodalDialogComponent } from '../mapmodal-dialog/mapmodal-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  selector: 'info-table-item',
  imports: [
    RouterLink,
    CommonModule,
    DocumentsDialog,
    DeclineDialogComponent,
    SubmitForReviewDialogComponent,
    CommentsComponent,
    BidSavedDialogComponent,
    MapmodalDialogComponent,
  ],
  templateUrl: './info-table-item.component.html',
  styleUrl: './info-table-item.component.scss',
})
export class InfoTableItem {
  serviceInfoToggle: boolean = false;
  documentsActive: boolean = false;
  editToggle: boolean = false;

  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: string = '';
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() serviceType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: string = '';
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';
  @Input() type: string = '';

  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showServiceType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showServiceInfo: boolean = true;
  @Input() showActions: boolean = true;

  isDeclineActive: boolean = false;
  submitForReviewActive: boolean = false;
  commentsActive: boolean = false;
  savedActive: boolean = false;
  mapActive: boolean = false;
  selectedSiteType: string = '';
  selectedSiteId: number = 0;

  constructor(private toastr: ToastrService) {}

  addressInfo() {
    alert('addressInfo');
  }

  serviceInfo() {
    this.serviceInfoToggle = !this.serviceInfoToggle;
  }
  toggleCommentsModal() {
    this.commentsActive = !this.commentsActive;
  }
  toggleDocumentsModal() {
    this.documentsActive = !this.documentsActive;
  }
  toggleDeclineModal() {
    this.isDeclineActive = !this.isDeclineActive;
  }
  toggleSubmitForReviewModal() {
    this.submitForReviewActive = !this.submitForReviewActive;
  }
  declineProposal() {
    alert('declineProposal');
  }

  editProposal() {
    if (this.editToggle) {
      this.toggleSaveModal();
    }
    this.editToggle = !this.editToggle;
  }

  viewProposal() {
    alert('viewProposal');
  }

  submitProposal() {
    this.toggleSubmitForReviewModal();
  }

  saveTurnTime() {
    this.toastr.success('Turn Time Saved');
  }

  saveBidAmount() {
    this.toastr.success('Bid Amount Saved');
  }
  toggleSaveModal() {
    this.savedActive = !this.savedActive;
  }

  toggleMapModal(siteType: string, siteId: number) {
    this.mapActive = !this.mapActive;
    this.selectedSiteType = siteType;
    this.selectedSiteId = siteId;
  }
}
