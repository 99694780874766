<div class="info-header" [ngClass]="{ expanded: expanded }">
  <div class="info-header__left">
    <button (click)="accordion()" class="info-header__left__button">
      <span class="material-icons">keyboard_arrow_down</span>
    </button>
    <h2 (click)="accordion()">{{ data.projectName }}</h2>
  </div>
  <div class="info-header__right">
    <a
      class="info-header__right__overview"
      [routerLink]="['/', 'project-overview', data.projectId]"
      >Project Overview</a
    >
    <button
      class="info-header__right__request"
      (click)="toggleSpecialRequestModal()"
      *ngIf="data.specialQuoteRequirements"
    >
      <span class="material-icons">assignment</span>Project Special Request
    </button>
    <button
      *ngIf="type === 'invitations' && !showAccept"
      class="info-header__right__atp"
      (click)="toggleSubmitAllBidsModal()"
      [disabled]="!enableSubmitAllBids"
    >
      <span class="material-icons">gavel</span>Submit All Bids
    </button>
    <button
      *ngIf="type === 'proposals' && showAtp"
      class="info-header__right__atp"
      (click)="generateATP()"
    >
      <span class="material-icons">login</span>View & Sign ATP
    </button>
    <button
      *ngIf="type === 'invitations' && showAccept"
      class="info-header__right__atp"
      (click)="toggleAcceptAllOfferedProposalModal()"
    >
      <span class="material-icons">fact_check</span>Accept All
    </button>
  </div>
</div>

<signatp-dialog
  [atpDocumentPath]="atpDocumentPath"
  [active]="signAtpActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleATPModal()"
  [ngClass]="{ 'documents-dialog--active': signAtpActive }"
  [modalHeaderTitle]="childModalHeaderTitle"
  [modalBody]="childModalBody"
  (signedAtpEvent)="signATP($event)"
  (declineAtpEvent)="declineATP($event)"
/>

<allbids-dialog
  [data]="data"
  [active]="allBidsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [numberOfBids]="numberOfBids"
  (modalClosed)="toggleSubmitAllBidsModal()"
  [ngClass]="{ 'documents-dialog--active': allBidsActive }"
/>

<project-special-request-dialog
  [active]="specialRequestToggle"
  [projectName]="data.projectName"
  [specialQuoteRequirements]="data.specialQuoteRequirements"
  (closeDialog)="toggleSpecialRequestModal()"
/>

<accept-offered-proposal-dialog
  [active]="acceptAllOffersActive"
  [data]="data"
  [multiple]="true"
  (modalClosed)="toggleAcceptAllOfferedProposalModal()"
  [ngClass]="{ 'documents-dialog--active': acceptAllOffersActive }"
/>
