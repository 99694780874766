import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private tokenKey = 'token';
  constructor(private http: HttpClient) {}
  public login(email: string, password: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/user/login',
      {
        email: email,
        password: password,
      },
      { responseType: 'json' }
    );
  }

  public register(register: User): Observable<any> {
    return this.http.post<User>(environment.apiUrl + '/user/register', {
      firstName: register.firstName,
      lastName: register.lastName,
      password: register.password,
      email: register.email,
      phone: register.phone,
      referredBy: register.referredBy,
    });
  }
  public getToken(): string | null {
    return this.isLoggedIn()
      ? localStorage.getItem(this.tokenKey) ||
          sessionStorage.getItem(this.tokenKey)
      : null;
  }
  public isLoggedIn(): boolean {
    let token =
      localStorage.getItem(this.tokenKey) ||
      sessionStorage.getItem(this.tokenKey);
    return !!token;
  }

  isTokenExpired(): boolean {
    var expiry = 0;
    let token =
      localStorage.getItem(this.tokenKey) ||
      sessionStorage.getItem(this.tokenKey);
    if (token != null) {
      expiry = JSON.parse(atob(token.split('.')[1])).exp;
    }
    return expiry - Math.floor(new Date().getTime() / 1000) <= 0;
  }

  public forgotPassword(email: String): Observable<any> {
    // making sure '+' is not converted to a space
    const encodedEmail = encodeURIComponent(email.toString());
    return this.http.post(
      environment.apiUrl +
        '/user/send-temp-password?email=' +
        encodedEmail +
        '',
      { responseType: 'json' }
    );
  }

  public resetPassword(email: string, password: String): Observable<any> {
    const encodedEmail = encodeURIComponent(email.toString());
    return this.http.put(
      environment.apiUrl +
        '/user/password?email=' +
        encodedEmail +
        '&password=' +
        password +
        '',
      { responseType: 'json' }
    );
  }
}
