import { Component, Input } from '@angular/core';
import { InfoTableItem } from '../info-table-item/info-table-item.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'info-table',
  imports: [InfoTableItem, CommonModule],
  templateUrl: './info-table.component.html',
  styleUrl: './info-table.component.scss',
})
export class InfoTable {
  @Input() data: any = '';
  @Input() type: string = '';
  @Input() expanded: boolean = true;

  statusSort: boolean = false;
  siteSort: boolean = false;
  addressSort: boolean = false;
  acreageSort: boolean = false;
  landUseSort: boolean = false;
  serviceTypeSort: boolean = false;
  dueDateSort: boolean = false;
  actionsSort: boolean = false;

  gridLayout: string =
    'var(--status) var(--site) var(--acreage) var(--landUse) var(--serviceType) var(--dueDate) var(--serviceInfo) var(--actions)';
  moreOpen: boolean = false;
  showStatus: boolean = true;
  showSite: boolean = true;
  showAcreage: boolean = true;
  showLandUse: boolean = true;
  showServiceType: boolean = true;
  showDueDate: boolean = true;
  showServiceInfo: boolean = false;
  showActions: boolean = true;

  ngOnInit() {
    this.siteSort = this.filterToggle(this.siteSort, 'site');
    this.adjustTable();
  }

  filterToggle(input: boolean, sortBy: string = '') {
    this.data.sort((a: any, b: any) => {
      let res = 0;
      if (sortBy !== 'dueDate') {
        if (a[sortBy] < b[sortBy]) res = -1;
        if (a[sortBy] > b[sortBy]) res = 1;
      } else {
        let dateA = new Date(a[sortBy]);
        let dateB = new Date(b[sortBy]);
        if (dateA < dateB) res = -1;
        if (dateA > dateB) res = 1;
      }
      if (input) res = -res;
      return res;
    });
    return !input;
  }

  more() {
    this.moreOpen = !this.moreOpen;
  }

  toggleColumn(input: boolean) {
    return !input;
  }

  adjustTable() {
    this.gridLayout = '';
    if (this.showStatus) this.gridLayout += 'var(--status) ';
    if (this.showSite) this.gridLayout += 'var(--site) ';
    if (this.showAcreage) this.gridLayout += 'var(--acreage) ';
    if (this.showLandUse) this.gridLayout += 'var(--land-use) ';
    if (this.showServiceType) this.gridLayout += 'var(--service-type) ';
    if (this.showDueDate) this.gridLayout += 'var(--due-date) ';
    if (this.showServiceInfo) this.gridLayout += 'var(--service-info) ';
    this.gridLayout += 'var(--documents) ';
    if (this.showActions) this.gridLayout += 'var(--action) ';
    this.gridLayout += 'var(--edit) ';
    this.gridLayout += 'var(--view) ';
    this.gridLayout = this.gridLayout.trim();
  }
}
