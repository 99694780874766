import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ChangeDetectionStrategy,  signal} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
@Component({
  selector: 'service-capabilities-dialog',
  standalone: true,
  imports: [CommonModule,MatExpansionModule],
  templateUrl: './service-capabilities-dialog.component.html',
  styleUrl: './service-capabilities-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceCapabilitiesDialogComponent {
  readonly panelOpenState = signal(false);
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() serviceCapabilities: any = '';
  @Input() serviceCapabilitiesType: string = '';
  @Input() expanded: boolean = false;
  openState: boolean = this.expanded;
  @Output() toggled = new EventEmitter<boolean>();
  
  ngOnInit() {
    this.openState = this.expanded;
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  submit() {
    this.closeDialog();
  }

  accordion() {
    this.openState = !this.openState;
    this.toggled.emit(this.openState);
  }
}
