import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../model/user';
import { PhoneMaskDirective } from '../phone-mask.directive';
import { ToastrService } from 'ngx-toastr';
import { RecaptchaModule } from 'ng-recaptcha';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    PhoneMaskDirective,
    RecaptchaModule,
  ],
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss',
})
export class CreateAccountComponent {
  captchaResponse: string | null = null;
  captchaResolved: boolean = false;
  recaptchaSiteKey: string = '';
  passwordRegx: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()_+=~`@$!%*?&])[A-Za-z\d#^()_+=~`@$!%*?&]{8,}$/;
  emailRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.recaptchaSiteKey = environment.recaptchaSiteKey;
  }

  protected createAccount = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegx),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(this.passwordRegx),
      ]),
    },
    { validators: this.customPasswordMatching.bind(this) }
  );

  ngOnInit() {}

  public customPasswordMatching(
    control: AbstractControl
  ): ValidationErrors | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    return password === confirmPassword
      ? null
      : { passwordMismatchError: true };
  }

  cancel() {
    this.router.navigate(['login']);
  }

  register() {
    if (this.createAccount.invalid) {
      this.createAccount.markAllAsTouched();
    }
    if (this.createAccount.valid) {
      let user = <User>this.createAccount.value;
      this.authService.register(user).subscribe((data) => {
        if (data.status === 'SUCCESS') {
          localStorage.setItem('user', JSON.stringify(data));
          this.router.navigate(['thanks']);
        } else {
          this.toastr.error(data.message);
        }
      });
    }
  }

  onCaptchaResolved(captchaResponse: any) {
    this.captchaResponse = captchaResponse;
    console.log('Captcha resolved with response:', captchaResponse);
  }
}
