<div class="info-header" [ngClass]="{ expanded: expanded }">
  <div class="info-header__left">
    <button class="info-header__left__back-button" (click)="goBack()">
      <span class="material-icons">keyboard_arrow_left</span>Back
    </button>
    <h2>{{ data.projectName }}</h2>
  </div>
  <div class="info-header__right">
    <button
      class="info-header__right__request"
      (click)="toggleSpecialRequestModal()"
      *ngIf="data.specialQuoteRequirements"
    >
      <span class="material-icons">assignment</span>Project Special Request
    </button>
    <button
      *ngIf="enableSubmitAllBids"
      class="info-header__right__atp"
      (click)="toggleSubmitAllBidsModal()"
    >
      <span class="material-icons">gavel</span>Submit All Bids
    </button>
    <button
      *ngIf="showAtp"
      class="info-header__right__atp"
      (click)="generateATP()"
    >
      <span class="material-icons">login</span>View & Sign ATP
    </button>
  </div>
</div>

<signatp-dialog
  [atpDocumentPath]="atpDocumentPath"
  [active]="signAtpActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleATPModal()"
  [ngClass]="{ 'documents-dialog--active': signAtpActive }"
  [modalHeaderTitle]="childModalHeaderTitle"
  [modalBody]="childModalBody"
  (signedAtpEvent)="signATP($event)"
  (declineAtpEvent)="declineATP($event)"
/>

<allbids-dialog
  [data]="data"
  [active]="allBidsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [numberOfBids]="numberOfBids"
  (modalClosed)="toggleSubmitAllBidsModal()"
  [ngClass]="{ 'documents-dialog--active': allBidsActive }"
/>

<project-special-request-dialog
  [active]="specialRequestToggle"
  [projectName]="data.projectName"
  [specialQuoteRequirements]="data.specialQuoteRequirements"
  (closeDialog)="toggleSpecialRequestModal()"
/>
