import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoGroup } from '../info-group/info-group.component';
import { InfoNavigation } from '../info-navigation/info-navigation.component';
import { ProjectService } from '../services/project.service';
import { User } from '../model/user';
import { AtpSignAcceptedDialogComponent } from '../atp-sign-accepted-dialog/atp-sign-accepted-dialog.component';
import { ModalService } from '../services/modal.service';
import { DeclineSuccessComponent } from '../decline-success/decline-success.component';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';
import { AcceptedOfferedProposalDialogComponent } from '../accepted-offered-proposal-dialog/accepted-offered-proposal-dialog.component';

@Component({
  standalone: true,
  selector: 'info-proposal',
  imports: [
    CommonModule,
    InfoGroup,
    InfoNavigation,
    AtpSignAcceptedDialogComponent,
    DeclineSuccessComponent,
  ],
  templateUrl: './proposals-page.component.html',
  styleUrl: './proposals-page.component.scss',
})
export class ProposalsPage {
  data: any = null;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  atpModalHeaderTitle: string = 'ATP Signed & Project Active';
  atpModalBody: string =
    'You have signed the ATP and the project can be viewed from the Active tab.';
  isATPSignedAccepted: boolean = false;
  isDeclineSuccessActive: boolean = false;
  isFixedPrice: boolean = false;
  isAtp: boolean = false;
  address: any = { streetAddress: '', cityStateZip: '' };
  siteType: string = '';
  loading: boolean = true;

  constructor(
    private projectService: ProjectService,
    private modalService: ModalService,
    private utilityService: CommonUtilityServiceService
  ) {}

  ngOnInit() {
    this.fetchData();
    this.modalService.isSignedAtpModalOpen.subscribe((isOpen) => {
      this.isATPSignedAccepted = isOpen;
    });
    this.modalService.isDeclineModalOpen.subscribe((detail) => {
      if (detail.isOpen) {
        this.isDeclineSuccessActive = true;
        this.isFixedPrice = detail.isFixedPrice;
        this.isAtp = detail.isAtp;
        if (detail.siteDetail.site) {
          this.address = this.utilityService.formatAddress(detail.siteDetail);
          this.siteType = detail.siteDetail.site.siteType;
        }
      }
    });
  }

  toggleCloseAtpAcceptedModal() {
    this.modalService.toggleCloseSignedAtpModal();
  }

  toggleCloseDeclineSuccessModal() {
    this.modalService.toggleCloseDeclineSuccessModal();
  }

  fetchData() {
    this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
      this.data = result
        .map((parent: any) => ({
          ...parent,
          sites: parent.sites.filter(
            (child: any) =>
              child.proposal.status === 'SUBMITTED' ||
              child.proposal.status === 'PROPOSAL' ||
              child.proposal.status === 'BID_LOST' ||
              child.proposal.status === 'BID_WON'
          ),
        }))
        .filter(
          (parent: any) =>
            parent.sites.length > 0 &&
            parent.projectId &&
            !parent.sites.every(
              (siteDetail: any) => siteDetail.proposal?.status === 'BID_LOST'
            )
        );
      this.loading = false;
    });
  }
}
