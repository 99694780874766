<div class="project-special-request-dialog" *ngIf="active">
  <div class="project-special-request-dialog__content">
    <button
      class="project-special-request-dialog__content__close"
      (click)="close()"
    >
      <span class="material-icons">close</span>Close
    </button>
    <h2>Special Request Notes</h2>

    <ng-container>
      <div class="">
        <div class="mb-10">
          {{ specialQuoteRequirements }}
        </div>
        <br />
        <div class="container mt-30">
          <div class="column column-left">
            <div
              class="project-special-request-dialog__content__footer__service-type"
            >
              <p
                class="project-special-request-dialog__content__footer__service-type__label"
              >
                Project
              </p>
              <p class="service-type">{{ projectName }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="project-special-request-dialog__background"
    (click)="close()"
  ></div>
</div>
