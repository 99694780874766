<div class="documents-dialog" *ngIf="active">
	<div class="documents-dialog__content">
		<button class="documents-dialog__content__close" (click)="closeDialog()">
			<span class="material-icons">close</span>Close</button>
		<span class="service-capabilities-title">Service Capabilities</span>
    <hr>
			<div class="service-capabilities-title2">Please select your service capabilities below:</div>
            <mat-accordion>
                <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                  <mat-expansion-panel-header>
                    <mat-panel-title> Environmental Site Assessments </mat-panel-title>
                    <mat-panel-description class="mat-panel-description">
                        0 Selected
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="panel-fields">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">Select All</span>
                        </div>
                         
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
                      <div class="save-btn">
                          <span class="material-icons edit-detals">save</span>
                              Save</div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title> Property Condition Assessments </mat-panel-title>
                    <mat-panel-description class="mat-panel-description">
                        7 Selected
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="panel-fields">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">Select All</span>
                        </div>
                         
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
                      <div class="save-btn">
                          <span class="material-icons edit-detals">save</span>
                              Save</div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                  <mat-expansion-panel-header>
                    <mat-panel-title> Environmental Subsurface</mat-panel-title>
                    <mat-panel-description class="mat-panel-description">
                        2 Selected
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="panel-fields">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">Select All</span>
                        </div>
                         
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" checked="checked">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
                      <div class="save-btn">
                          <span class="material-icons edit-detals">save</span>
                              Save</div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                  <mat-expansion-panel-header>
                    <mat-panel-title> Environmental Cleanup </mat-panel-title>
                    <mat-panel-description class="mat-panel-description">
                        0 Selected
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="panel-fields">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">Select All</span>
                        </div>
                         
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Complete ASTM</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Visit Only</span>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <span class="checkbox-label">PCA Report Writing</span>
                        </div>
                      </div>
                      <div class="save-btn">
                          <span class="material-icons edit-detals">save</span>
                              Save</div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
    <mat-accordion>
        <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title> Environmental Laboratory </mat-panel-title>
            <mat-panel-description class="mat-panel-description">
                0 Selected
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div class="panel-fields">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">Select All</span>
                </div>
                 
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>
              <div class="save-btn">
                  <span class="material-icons edit-detals">save</span>
                      Save</div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    <mat-accordion>
        <mat-expansion-panel class="accordian-title" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title> Environmental Real Estate Services </mat-panel-title>
            <mat-panel-description class="mat-panel-description">
                0 Selected
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div class="panel-fields">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">Select All</span>
                </div>
                 
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>

              <div class="panel-fields1">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Complete ASTM</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Visit Only</span>
                </div>
                <div class="checkbox">
                  <input type="checkbox" class="checkbox-field">
                  <span class="checkbox-label">PCA Report Writing</span>
                </div>
              </div>
              <div class="save-btn">
                  <span class="material-icons edit-detals">save</span>
                      Save</div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="proposal-details__footer">
        <div class="proposal-details__footer__right">
          <button class="proposal-details__footer__right__button-dark">
            Save & Close
          </button>
        </div>
      </div>
</div>
	<div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>