<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>Comments</h2>

    <textarea
      id="documents-dialog-file-notes"
      placeholder="Please feel free to include a comment with your bid to share any additional information you may have."
      required
      [(ngModel)]="bidNotes"
      [readonly]="
        data.proposal.status !== 'INVITATION' &&
        data.proposal.status !== 'SUBMITTED'
      "
      >{{ bidNotes }}</textarea
    >

    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Service Type
        </p>
        <p class="documents-dialog__content__footer__service-type__text">
          {{ siteType }}
        </p>
      </div>
      <div class="documents-dialog__content__footer__service-type">
        <p class="documents-dialog__content__footer__service-type__label">
          Location
        </p>
        <p class="documents-dialog__content__footer__location__text">
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>
      <div class="btnalign">
        <button
          *ngIf="
            data.proposal.status === 'INVITATION' ||
            data.proposal.status === 'SUBMITTED'
          "
          class="documents-dialog__content__footer__submit pull-right"
          (click)="submit()"
        >
          Save & Close
        </button>
        <button class="defaultbtn" (click)="closeDialog()">
          {{
            data.proposal.status === "INVITATION" ||
            data.proposal.status === "SUBMITTED"
              ? "Cancel"
              : "Close"
          }}
        </button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
