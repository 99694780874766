<div class="info-navigation">
	<div class="info-navigation__left">
		<a routerLink="/invitations"><button [ngClass]="{'active':type=='invitations'}"
				class="info-navigation__left__button info-navigation__left__button--invitation"><span
					class="material-icons">mail</span>Invitations</button></a>
		<a routerLink="/proposals"><button [ngClass]="{'active':type=='proposals'}"
				class="info-navigation__left__button info-navigation__left__button--proposal"><span
					class="material-icons">note_alt</span>Proposals</button></a>

		<a routerLink="/active"><button [ngClass]="{'active':type=='active'}"
				class="info-navigation__left__button info-navigation__left__button--active"><span
					class="material-icons">room_service</span>Active</button></a>
	</div>
	<div class="info-navigation__right">
		<button (click)="projectHistory()">Project History</button>
	</div>
</div>