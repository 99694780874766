import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AllBidsSubmittedDialogComponent } from '../allbids-submitted-dialog/allbids-submitted-dialog.component';
@Component({
  selector: 'allbids-dialog',
  standalone: true,
  imports: [RouterOutlet, CommonModule, AllBidsSubmittedDialogComponent],
  templateUrl: './allbids-dialog.component.html',
  styleUrl: './allbids-dialog.component.scss',
})
export class AllbidsDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = '';
  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';
  bidsubmittedActive: boolean = false;

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  toggleBidsubmittedModal() {
    this.bidsubmittedActive = !this.bidsubmittedActive;
  }
  submit() {
    this.toggleBidsubmittedModal();
    this.closeDialog();
  }
}
