<div class="documents-dialog" *ngIf="active">
	<div class="documents-dialog__content">
		<button class="documents-dialog__content__close" (click)="closeDialog()">
			<span class="material-icons">close</span>Close</button>
		<h2>Documents</h2>
		<div class="documents-dialog__content__form">
			<div class="documents-dialog__content__form__upload">
				<label for="documents-dialog-file-upload" class="btn">
					<span class="material-icons">upload</span>Upload a
					Document</label>
				<input id="documents-dialog-file-upload" type="file" required />
			</div>
			<div class="documents-dialog__content__form__notes">
				<label for="documents-dialog-file-notes">Notes</label>
				<textarea id="documents-dialog-file-notes" placeholder="Add a description" required></textarea>
			</div>
		</div>
		<ng-container *ngIf="documents.length > 0">
			<h3>RFP Documents</h3>
			<div class="documents-dialog__content__documents">
				<div class="documents-dialog__content__documents__item" *ngFor="let document of documents">
					<div class="documents-dialog__content__documents__item__content">
						<p class="documents-dialog__content__documents__item__content__title">{{document.title}}</p>
						<p class="documents-dialog__content__documents__item__content__description">Site Files</p>
					</div>
					<a href="{{document.link}}" download><span class="material-icons">download</span></a>
				</div>
			</div>
		</ng-container>
		<div class="documents-dialog__content__footer">
			<div class="documents-dialog__content__footer__service-type">
				<p class="documents-dialog__content__footer__service-type__label">Service Type</p>
				<p class="documents-dialog__content__footer__service-type__text">{{ serviceType }}</p>
			</div>
			<div class="documents-dialog__content__footer__location">
				<p class="documents-dialog__content__footer__location__label">Location</p>
				<p class="documents-dialog__content__footer__location__text">{{ address }}</p>
			</div>
			<button (click)="submit()" class="documents-dialog__content__footer__submit">Save & Close</button>
		</div>
	</div>
	<div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>