import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignAtpDialogComponent } from '../sign-atp-dialog/sign-atp-dialog.component';
import { AllbidsDialogComponent } from '../allbids-dialog/allbids-dialog.component';
import { User } from '../model/user';
import { ProjectService } from '../services/project.service';
import { ProjectOverviewRowItemComponent } from '../project-overview-row-item/project-overview-row-item.component';

@Component({
  selector: 'app-projecthistory',
  standalone: true,
  imports: [
    CommonModule,
    SignAtpDialogComponent,
    AllbidsDialogComponent,
    ProjectOverviewRowItemComponent,
  ],
  templateUrl: './projecthistory.component.html',
  styleUrl: './projecthistory.component.scss',
})
export class ProjecthistoryComponent {
  //@Input() data: any = '';
  @Input() type: string = '';
  @Input() expanded: boolean = true;
  @Input() index: number = 0;

  statusSort: boolean = false;
  siteSort: boolean = false;
  addressSort: boolean = false;
  acreageSort: boolean = false;
  landUseSort: boolean = false;
  siteTypeSort: boolean = false;
  dueDateSort: boolean = false;
  actionsSort: boolean = false;

  gridLayout: string =
    'var(--status) var(--site) var(--acreage) var(--landUse) var(--siteType) var(--dueDate) var(--actions)';
  moreOpen: boolean = false;
  showStatus: boolean = true;
  showSite: boolean = true;
  showAcreage: boolean = true;
  showLandUse: boolean = true;
  showsiteType: boolean = true;
  showDueDate: boolean = true;
  showServiceInfo: boolean = false;
  showActions: boolean = true;

  @Input() title: string = '';
  @Output() toggled = new EventEmitter<boolean>();
  openState: boolean = this.expanded;
  serviceInfoToggle: boolean = false;
  signAtpActive: boolean = false;
  allBidsActive: boolean = false;
  editToggle: boolean = false;

  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: string = '';
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() serviceType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: string = '';
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';

  @Input() showServiceType: boolean = true;
  childModalHeaderTitle: string = 'ATP Signed & Proposal/S Accepted';
  childModalBody: string =
    "You have signed all ATP's and are available for view in the active projects.";
  tableAItemName: string = '';
  tableAItemValue: string = '';
  serviceTypeSort: boolean = false;
  data: any = null;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.fetchData();
    this.openState = this.expanded;
    this.siteSort = this.filterToggle(this.siteSort, 'site');
    this.adjustTable();
  }

  toggleSubmitAllBidsModal() {
    this.allBidsActive = !this.allBidsActive;
  }
  toggleATPModal() {
    this.signAtpActive = !this.signAtpActive;
  }
  projectOverview() {
    alert('projectOverview');
  }

  accordion() {
    this.openState = !this.openState;
    this.toggled.emit(this.openState);
  }

  fetchData() {
    this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
      this.data = result.map((parent: any) => ({
        sites: parent.sites.filter(
          (child: any) => child.proposal.status === 'COMPLETED'
        ),
      }))[0];
    });
  }

  filterToggle(input: boolean, sortBy: string = '') {
    if (this.data) {
      this.data.sort((a: any, b: any) => {
        let res = 0;
        if (sortBy !== 'dueDate') {
          if (a[sortBy] < b[sortBy]) res = -1;
          if (a[sortBy] > b[sortBy]) res = 1;
        } else {
          let dateA = new Date(a[sortBy]);
          let dateB = new Date(b[sortBy]);
          if (dateA < dateB) res = -1;
          if (dateA > dateB) res = 1;
        }
        if (input) res = -res;
        return res;
      });
    }
    return !input;
  }

  more() {
    this.moreOpen = !this.moreOpen;
  }

  toggleColumn(input: boolean) {
    return !input;
  }

  adjustTable() {
    this.gridLayout = '';
    if (this.showStatus) this.gridLayout += 'var(--status) ';
    if (this.showSite) this.gridLayout += 'var(--site) ';
    if (this.showAcreage) this.gridLayout += 'var(--acreage) ';
    if (this.showLandUse) this.gridLayout += 'var(--land-use) ';
    if (this.showsiteType) this.gridLayout += 'var(--service-type) ';
    //if (this.showDueDate) this.gridLayout += 'var(--due-date) ';commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75
    //if (this.showServiceInfo) this.gridLayout += 'var(--service-info) ';
    this.gridLayout += 'var(--documents) ';
    if (this.showActions) this.gridLayout += 'var(--action) ';
    this.gridLayout += 'var(--edit) ';
    this.gridLayout += 'var(--view) ';
    this.gridLayout = this.gridLayout.trim();
  }
  goBack() {
    window.history.back();
  }
}
