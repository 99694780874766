<div class="info-table-item">
  <ng-container *ngIf="showStatus">
    <ng-container *ngIf="type == 'proposals'">
      <p class="status --proposal" *ngIf="status == 'proposal'">Proposal</p>
      <p class="status --bid-won" *ngIf="status == 'bid-won'">Bid Won</p>
      <p class="status --bid-lost" *ngIf="status == 'lost-bid'">Lost Bid</p>
    </ng-container>
    <ng-container *ngIf="type == 'active'">
      <p class="status --active">Active</p>
    </ng-container>
    <ng-container *ngIf="type == 'invitations'">
      <p class="status --invitation">Invitation</p>
    </ng-container>
  </ng-container>
  <div class="address" *ngIf="showSite">
    <p class="address__site">#{{ site }}</p>
    <div class="address__address"><span class="material-icons address__pointer"
        (click)="toggleMapModal(type,id)">info</span>
      <div class="address__pointer" [routerLink]="['/', type, id]">
        <p>{{ address }}</p>
      </div>
    </div>
  </div>
  <p *ngIf="showAcreage">{{ acreage.toLocaleString() }}</p>
  <p *ngIf="showLandUse">
    {{ landUse.charAt(0).toUpperCase() + landUse.slice(1) }} Building
  </p>
  <div class="address__pointer" [routerLink]="['/', type, id]">
    <div *ngIf="showServiceType" class="service-type">
      <span class="material-icons" (mouseenter)="serviceInfo()" (mouseleave)="serviceInfo()">info</span>
      <p>{{ serviceType }}</p>
      <div class="service-type__info" [ngClass]="{ active: serviceInfoToggle }">
        <p class="service-type__info__label">Table A</p>
        <p class="service-type__info__content">{{ tableA }}</p>
        <p class="service-type__info__label">Item 20</p>
        <p class="service-type__info__content">{{ item20 }}</p>
      </div>
    </div>
  </div>
  <p style="width: 100px" *ngIf="showDueDate">{{ dueDate }}</p>
  <div class="docs-info">
    <div class="documents" (click)="toggleDocumentsModal()">
      <span class="material-icons">upload</span>
      <div *ngIf="documents.length > 0" class="documents__number">
        {{ documents.length }}
      </div>
    </div>
    <span class="documents-label">Documents</span>
  </div>
  <ng-container *ngIf="showActions">
    <div *ngIf="type == 'proposals' && !editToggle" class="proposals-actions">
      <div class="turn-time">
        <p>Turn Time</p>
        <p>{{ turnTime }} Days</p>
      </div>
      <div class="bid-amount">
        <p>Bid Amount</p>
        <p>{{ bidAmount }}</p>
      </div>
    </div>
    <div *ngIf="type == 'invitations' || (type == 'proposals' && editToggle)" class="info-table-actions-edit">
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Turn Time</p>
        <div class="info-table-actions-edit__item__input">
          <div *ngIf="type == 'invitations'">
            <input type="text" value="" (blur)="saveTurnTime()" />
          </div>
          <div *ngIf="type == 'proposals'">
            <input type="text" value="{{ turnTime }}" (blur)="saveTurnTime()" />
          </div>
        </div>
      </div>
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Bid Amount</p>
        <div class="info-table-actions-edit__item__input">
          <div *ngIf="type == 'invitations'">
            <input type="text" value="" (blur)="saveBidAmount()" />
          </div>
          <div *ngIf="type == 'proposals'">
            <input type="text" value="{{ bidAmount }}" (blur)="saveBidAmount()" />
          </div>
        </div>
      </div>
      <div class="decline-icon" *ngIf="!editToggle">
        <div class="status --decline">
          <span style="margin-bottom: -7px" class="material-icons" title="Decline"
            (click)="toggleDeclineModal()">close</span>
        </div>
        <span class="decline" (click)="toggleDeclineModal()">Decline</span>
      </div>
    </div>
    <div *ngIf="type == 'active'" class="info-table-review submit-review-align">
      <button *ngIf="reviewStatus == 'submit'" (click)="submitProposal()">
        Submit for Review
      </button>
      <p class="pending-review-label" *ngIf="reviewStatus == 'pending'">
        Pending Review
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="type == 'proposals'">
    <div class="info-table-button" (click)="editProposal()" *ngIf="status == 'proposal'">
      <span *ngIf="!editToggle" class="material-icons">edit</span>
      <p *ngIf="!editToggle">Edit</p>
      <span *ngIf="editToggle" class="material-icons">check</span>
    </div>
    <div *ngIf="status == 'lost-bid' || status == 'bid-won'"></div>
  </ng-container>
  <div *ngIf="type != 'proposals'"></div>
  <div class="info-table-button" [routerLink]="['/', type, id]">
    <span class="material-icons">visibility</span>
    <p>View</p>
  </div>
  <mapmodal-dialog [active]="mapActive" [siteType]="selectedSiteType" [siteId]="selectedSiteId"
    (modalClosed)="toggleMapModal(type,id)" [ngClass]="{'mapmodal-dialog--active': mapActive}"></mapmodal-dialog>
</div>
<decline-dialog [active]="isDeclineActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
  (modalClosed)="toggleDeclineModal()" [ngClass]="{ 'documents-dialog--active': isDeclineActive }"></decline-dialog>

<submit-for-review-dialog [active]="submitForReviewActive" [documents]="documents" [serviceType]="serviceType"
  [address]="address" (modalClosed)="toggleSubmitForReviewModal()"
  [ngClass]="{ 'documents-dialog--active': submitForReviewActive }"></submit-for-review-dialog>

<documents-dialog [active]="documentsActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
  (modalClosed)="toggleDocumentsModal()" [ngClass]="{ 'documents-dialog--active': documentsActive }"></documents-dialog>

<comments-dialog [active]="commentsActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
  (modalClosed)="toggleCommentsModal()" [ngClass]="{ 'documents-dialog--active': commentsActive }"></comments-dialog>

<bid-saved-dialog [active]="savedActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
  (modalClosed)="toggleSaveModal()" [ngClass]="{ 'documents-dialog--active': savedActive }"></bid-saved-dialog>