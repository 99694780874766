import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';

@Component({
  selector: 'mapmodal-dialog',
  standalone: true,
  imports: [CommonModule, RouterLink, GoogleMapsModule],
  templateUrl: './mapmodal-dialog.component.html',
  styleUrl: './mapmodal-dialog.component.scss',
})
export class MapmodalDialogComponent {
  @Input() active: boolean = false;
  @Input() siteType: any = '';
  @Input() siteId: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() data: any = {};

  @Output() modalClosed = new EventEmitter<boolean>();

  // google map
  center: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 };
  zoom: number = 4;
  mapOptions: google.maps.MapOptions = {};
  polygonPaths: google.maps.LatLngLiteral[] = [];
  polygonOptions: google.maps.PolygonOptions = {
    fillColor: '#6296ff',
    fillOpacity: 0.25,
    strokeColor: '#537ed5',
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    editable: false,
    draggable: false,
  };

  constructor(private utilityService: CommonUtilityServiceService) {}

  ngOnInit(): void {
    this.mapOptions = {
      mapTypeId: google.maps.MapTypeId.HYBRID,
      mapTypeControl: false,
    };
    this.center = this.utilityService.determineMapCenter(
      this.data.site.parcels
    );
    this.zoom = this.utilityService.determineMapZoomLevel(
      this.data.site.totalAcreage
    );
    this.polygonPaths = this.utilityService.detemineMapBounderies(this.data);
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    this.closeDialog();
  }
}
