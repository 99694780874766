import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../model/user';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  isValid: boolean = true;
  passwordRegx: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()_+=~`@$!%*?&])[A-Za-z\d#^()_+=~`@$!%*?&]{8,}$/;
  emailRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.appService.getMessage.subscribe((message) => {
      if (message != '') {
        this.toastr.success(message);
        this.appService.setMessage('');
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    let keepMeSigned = localStorage.getItem('keepMeSignedIn');
    if (keepMeSigned) {
      const user = <User>JSON.parse(localStorage.getItem('user') ?? '');
      let password = localStorage.getItem('keepMeSignedInPassword');
      this.loginForm.patchValue({
        email: user.email,
        password: password,
        keepMeSigned: 'true',
      });
      if (user) {
        this.router.navigate(['invitations']);
      }
    }
  }

  protected loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegx),
    ]),
    password: new FormControl('', [Validators.required]),
    keepMeSigned: new FormControl(''),
  });

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
    }
    if (
      this.loginForm.value.password != '' &&
      this.loginForm.value.email != ''
    ) {
      if (this.loginForm.value.email?.match(this.emailRegx) === null) {
        this.isValid = false;
      }
    }
    if (
      this.loginForm.value.password != '' &&
      this.loginForm.value.password?.match(this.passwordRegx) === null
    ) {
      if (localStorage.getItem('user') != null) {
        const user = <User>JSON.parse(localStorage.getItem('user') ?? '').data;
        if (user) {
          if (
            Math.floor(
              new Date(user.tempPasswordExpiration ?? '').getTime() -
                new Date().getTime()
            ) /
              1000 /
              60 <
            0
          ) {
            this.toastr.error('Your temporary password has expired.');
            return;
          }
        }
      }
    }
    if (this.loginForm.valid) {
      if (this.loginForm.value.email && this.loginForm.value.password) {
        let password = this.loginForm.value.password;
        this.authService
          .login(this.loginForm.value.email, this.loginForm.value.password)
          .subscribe((data) => {
            if (
              this.loginForm.value.password != '' &&
              this.loginForm.value.password?.match(this.passwordRegx) != null
            ) {
              localStorage.setItem('user', JSON.stringify(data.data));
            }
            if (this.loginForm.value.keepMeSigned) {
              localStorage.setItem('keepMeSignedIn', 'true');
              localStorage.setItem('keepMeSignedInPassword', password);
            } else {
              localStorage.removeItem('keepMeSignedIn');
              localStorage.removeItem('keepMeSignedInPassword');
            }
            if (data.status === 'FAILURE') {
              this.toastr.error(data.message);
            }
            if (data.code === 302) {
              localStorage.setItem('email', this.loginForm.value.email ?? ''),
                this.router.navigate(['resetpassword']);
            } else if (data.code === 200) {
              localStorage.setItem('token', data.token);
              sessionStorage.setItem('token', data.token);
              this.router.navigate(['invitations']);
            }
          });
      }
    }
  }
  navigateRegister() {
    this.router.navigate(['createaccount']);
  }
  clearValidations() {
    this.isValid = true;
  }
}
