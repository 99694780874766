<div class="container">
  <div class="left-navigation">
      <div class="back-btn"><span class="material-icons">arrow_back_ios</span> <a (click)="goBack()"
              class="back-btn">Back</a>
      </div>
      <hr>
      <div class="my-account">My Account</div>
      <div class="left-nav" [routerLink]="'/manageaccount'"><span class="material-icons">person</span> <a class="left-links">My
              Profile</a>
      </div>
      <div class="left-nav" [routerLink]="'/settings'"><span class="material-icons">settings</span> <a class="left-links">Account
              Settings</a></div>
      <hr>
      <div class="left-nav" [routerLink]="'/contact'" routerLinkActive="router-link-active" ><span class="material-icons">info</span> <a class="left-links">Contact
              Us</a>
      </div>
      <div class="left-nav"><span class="material-icons">logout</span> <a (click)="logout()"
              class="left-links">Logout</a></div>
  </div>
  <div class="content-area">
      <div class=" ">
          <div class="proposal-details__header">
              <div class="proposal-details__header__info mainheader">
                  <h1><span class="material-icons">person</span> {{ fullName }}</h1>
                  <div class="documents-dialog__content__form__upload1">
                    <label for="profile-pic-upload">
                        <span class="material-icons profile-pic">person</span></label>
                    <input id="profile-pic-upload" class="profile-pic-upload" type="file" required />
                </div>
              </div>
              <div class="proposal-details__header__tabs">
                  <button (click)="toggleTab('profile')" [class.active]="activeTab=='profile'">Profile</button>
                  <button (click)="toggleTab('organization')"
                      [class.active]="activeTab=='organization'">Organization</button>
                  <button (click)="toggleTab('servicezones')" [class.active]="activeTab=='servicezones'">Services & Zones</button>
                 
                  <button (click)="toggleTab('documents')" [class.active]="activeTab=='documents'">Documents</button>
              </div>
          </div>
              <div class="profile" *ngIf="activeTab=='profile'">
                  <div class="tabcontent">
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                          <tbody>
                              <tr>
                                  <td width="40%">
                                      <div class="tabcard">
                                          <div class="tab-header">Name</div>
                                          <div class="btndiv" *ngIf="!editNameToggle" (click)="editName()"><span
                                                  class="material-icons edit-detals">border_color</span>
                                              Edit</div>
                                              <div class="btn-save" *ngIf="editNameToggle" (click)="saveName()"><span class="material-icons">save</span>
                                                Save</div>
                                      </div>
                                      <div class="user-details">
                                          <div class="left">
                                              <p class="small-txt">First Name</p>
                                              <p *ngIf="!editNameToggle" class="lbl-value">{{user.firstName}}</p>
                                              <input  *ngIf="editNameToggle" id="firstName" type="text" value="{{user.firstName}}" class="input" formControlName="firstName" maxlength="50" autocomplete="disabled" />
                                          </div>
                                          <div class="right">
                                              <p class="small-txt">Last Name</p>
                                              <p *ngIf="!editNameToggle" class="lbl-value">{{user.lastName}}</p>
                                              <input *ngIf="editNameToggle" id="lastName" type="text" value="{{user.lastName}}" class="input" formControlName="lastName" maxlength="50" autocomplete="disabled" />
                                          </div>
                                      </div>
                                  </td>
                                  <td width="10%">&nbsp;</td>
                                  <td class="profile-email">
                                    <div class="tabcard">
                                        <div class="tab-header">Email</div>
                                        <div class="btndiv" *ngIf="!editEmailToggle" (click)="editEmail()"><span
                                                class="material-icons edit-detals">border_color</span>
                                            Edit</div>
                                            <div class="btn-save" *ngIf="editEmailToggle" (click)="saveEmail()"><span class="material-icons">save</span>
                                              Save</div>
                                    </div>
                                    <div class="user-details1">
                                        <div class="left">
                                            <p class="small-txt">Login Email</p>
                                            <p *ngIf="!editEmailToggle" class="lbl-value">{{user.email}}</p>
                                            <input *ngIf="editEmailToggle" id="phone" type="text" value="{{user.email}}" class="input" formControlName="email" maxlength="50" autocomplete="disabled" />
                                        </div>
                                    </div>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                          <tbody>
                              <tr>
                                  <td width="40%">
                                      <div class="tabcard">
                                          <div class="tab-header">Phone</div>
                                          <div class="btndiv" *ngIf="!editPhoneToggle" (click)="editPhone()"><span
                                                  class="material-icons edit-detals">border_color</span>
                                              Edit</div>
                                              <div class="btn-save" *ngIf="editPhoneToggle" (click)="savePhone()"><span class="material-icons">save</span>
                                                Save</div>
                                      </div>
                                      <div class="user-details1">
                                          <div class="left">
                                              <p class="small-txt">Phone Number</p>
                                              <p *ngIf="!editPhoneToggle" class="lbl-value">{{user.phone}}</p>
                                              <input *ngIf="editPhoneToggle" id="phone" type="text" value="{{user.phone}}" class="input" formControlName="phone" maxlength="50" autocomplete="disabled" />
                                          </div>
                                          <div class="right">
                                              <p class="small-txt">Phone OS</p>
                                              <p *ngIf="!editPhoneToggle" class="lbl-value">{{user.phoneOs}}</p>
                                              <input *ngIf="editPhoneToggle" id="phoneOS" type="text" value="{{user.phoneOs}}" class="input" formControlName="phoneOS" maxlength="50" autocomplete="disabled" />
                                         
                                          </div>
                                      </div>
                                  </td>
                                  <td width="10%">&nbsp;</td>
                                  <td>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                          <tbody>
                              <tr>
                                  <td width="40%">
                                      <div class="tabcard">
                                          <div class="tab-header">Address</div>
                                          <div class="btndiv" *ngIf="!editAddressToggle" (click)="editAddress()"><span
                                            class="material-icons edit-detals">border_color</span>
                                        Edit</div>
                                          <div class="btn-save" *ngIf="editAddressToggle" (click)="saveAddress()"><span class="material-icons">save</span>
                                              Save</div>
                                      </div>
                                      <br>
                                      <div class="create-form">
                                          <div class="form-control">
                                              <label for="exampleFormControlInput1" class="form-label">Address 1 <span
                                                      class="req">*</span></label>
                                              <div class="address">
                                                <div class="left">
                                                    <p *ngIf="!editAddressToggle" class="lbl-value">{{user.location?.street}}</p>
                                                    <input *ngIf="editAddressToggle" id="street" type="text" value="{{user.location?.street}}" class="input" formControlName="street" maxlength="100" autocomplete="disabled" />
                                                </div>
                                            </div>
                                          </div>
                                          <div class="form-control" style="margin-left: 10px;">
                                              <label for="exampleFormControlInput1" class="form-label">Address 2 <span
                                                      class="req">*</span></label>
                                              <div class="address">
                                                <div class="left">
                                                    <p *ngIf="!editAddressToggle" class="lbl-value">{{user.location?.street}}</p>
                                                    <input *ngIf="editAddressToggle" id="street2" type="text" value="{{user.location?.street}}" class="input" formControlName="street2" maxlength="100" autocomplete="disabled" />
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                      <div class="create-form">
                                          <div class="form-control">
                                              <label for="exampleFormControlInput1" class="form-label">City <span
                                                      class="req">*</span></label>
                                              <div class="address">
                                                <div class="left">
                                                    <p *ngIf="!editAddressToggle" class="lbl-value">{{user.location?.city}}</p>
                                                    <input *ngIf="editAddressToggle" id="city" type="text" value="{{user.location?.city}}" class="input" formControlName="city" maxlength="100" autocomplete="disabled" />
                                                </div>
                                            </div>
                                          </div>
                                          <div class="form-control" style="margin-left: 10px;">
                                              <label for="exampleFormControlInput1" class="form-label">State/Province
                                                  <span class="req">*</span></label>
                                              <br>
                                              <select class="form-select" [disabled]="!editAddressToggle">
                                                  <option value="volvo">{{user.location?.state}}</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div class="create-form">
                                          <div class="form-control">
                                              <label for="exampleFormControlInput1" class="form-label">Zip Code <span
                                                      class="req">*</span></label>
                                              <div class="address">
                                                <div class="left">
                                                    <p *ngIf="!editAddressToggle" class="lbl-value">{{user.location?.zipcode}}</p>
                                                    <input *ngIf="editAddressToggle" id="zipcode" type="text" value="{{user.location?.zipcode}}" class="input" formControlName="zipcode" maxlength="100" autocomplete="disabled" />
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                              </tr>
                          </tbody>
                      </table>
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                          <tbody>
                              <tr>
                                  <td width="40%">
                                      <div class="tabcard">
                                          <div class="tab-header">CC Info</div>
                                          <div class="btndiv" *ngIf="!editCCInfoToggle" (click)="editCCInfo()"><span
                                                  class="material-icons edit-detals">border_color</span>
                                              Edit</div>
                                              <div class="btn-save" *ngIf="editCCInfoToggle" (click)="saveCCInfo()"><span class="material-icons">save</span>
                                                Save</div>
                                      </div>
                                      <div class="user-details">
                                          <div class="left" [ngClass]="{ 'after-edit': editCCInfoToggle,'before-edit':!editCCInfoToggle }">
                                              <p class="small-txt">CC Name 1</p>
                                              <p *ngIf="!editCCInfoToggle" class="small-txt">{{user.ccName1}}</p>
                                              <input *ngIf="editCCInfoToggle" id="phone" type="text" value="{{user.ccName1}}" class="input" formControlName="ccName1" maxlength="50" autocomplete="disabled" />
                                          </div>
                                          <div class="right" [ngClass]="{ 'after-edit': editCCInfoToggle,'before-edit':!editCCInfoToggle }">
                                              <p class="small-txt">CC Name 2</p>
                                              <p *ngIf="!editCCInfoToggle" class="small-txt">{{user.ccName2}}</p>
                                              <input *ngIf="editCCInfoToggle" id="phone" type="text" value="{{user.ccName2}}" class="input" formControlName="ccName2" maxlength="50" autocomplete="disabled" />
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          <div class="organization" *ngIf="activeTab=='organization'">
              <div class="tabcontent">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="100%">
                                  <div class="tabcard">
                                      <div class="tab-header">organization</div>
                                      <div class="btndiv" *ngIf="!editOrgNameToggle" (click)="editOrgName()"><span class="material-icons edit-detals">border_color</span>
                                          Edit
                                      </div>
                                      <div class="btn-save" *ngIf="editOrgNameToggle" (click)="saveOrgName()"><span class="material-icons">save</span>
                                        Save</div>
                                  </div>
                                  <div class="user-details1">
                                      <div class="left">
                                          <p class="small-txt">Organization Name</p>
                                          <p *ngIf="!editOrgNameToggle" class="lbl-value">{{organization.name}}</p>
                                          <input *ngIf="editOrgNameToggle" id="orgName" type="text" value="{{organization.name}}" class="input" formControlName="orgName" maxlength="50" autocomplete="disabled" />
                                      </div>

                                  </div>
                              </td>


                          </tr>
                      </tbody>
                  </table>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="40%">
                                  <div class="tabcard">
                                      <div class="tab-header">Phone</div>
                                      <div class="btndiv" *ngIf="!editOrgPhoneToggle" (click)="editOrgPhone()"><span class="material-icons edit-detals">border_color</span>
                                          Edit
                                      </div>
                                      <div class="btn-save" *ngIf="editOrgPhoneToggle" (click)="saveOrgPhone()"><span class="material-icons">save</span>
                                        Save</div>
                                  </div>
                                  <div class="user-details1">
                                      <div class="left">
                                          <p class="small-txt">Phone Number</p>
                                          <p *ngIf="!editOrgPhoneToggle" class="lbl-value">{{organization.phoneNumber}}</p>
                                          <input *ngIf="editOrgPhoneToggle" id="orgPhone" type="text" value="{{organization.phoneNumber}}" class="input" formControlName="orgPhone" maxlength="50" autocomplete="disabled" />
                                      </div>
                                      <div class="right">
                                          <p class="small-txt">Phone OS</p>
                                          <p *ngIf="!editOrgPhoneToggle" class="lbl-value">{{organization.phoneOS}}</p>
                                          <input *ngIf="editOrgPhoneToggle" id="orgPhoneOS" type="text" value="{{organization.phoneOS}}" class="input" formControlName="orgPhoneOS" maxlength="50" autocomplete="disabled" />
                                      </div>
                                  </div>
                              </td>
                              <td width="10%">&nbsp;</td>
                              <td class="organization-email">
                                  <div class="tabcard">
                                      <div class="tab-header">Email</div>
                                      <div class="btndiv" *ngIf="!editOrgEmailToggle" (click)="editOrgEmail()"><span class="material-icons edit-detals">border_color</span>
                                          Edit
                                      </div>
                                      <div class="btn-save" *ngIf="editOrgEmailToggle" (click)="saveOrgEmail()"><span class="material-icons">save</span>
                                        Save</div>
                                  </div>
                                  <div class="user-details1">
                                      <div class="left">
                                          <p class="small-txt">Organization Email</p>
                                          <p *ngIf="!editOrgEmailToggle" class="lbl-value">{{organization.email}}</p>
                                          <input *ngIf="editOrgEmailToggle" id="orgEmail" type="text" value="{{organization.email}}" class="input" formControlName="orgEmail" maxlength="50" autocomplete="disabled" />
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="40%">
                                  <div class="tabcard">
                                      <div class="tab-header">Address</div>
                                      <div class="btndiv" *ngIf="!editOrgAddressToggle" (click)="editOrgAddress()"><span
                                              class="material-icons edit-detals">border_color</span>Edit
                                      </div>
                                      <div class="btn-save" *ngIf="editOrgAddressToggle" (click)="saveOrgAddress()"><span class="material-icons">save</span>
                                        Save</div>
                                  </div>

                                  <div class="user-details1">
                                      <div class="left">
                                          <p class="small-txt">Address 1</p>
                                          <p *ngIf="!editOrgAddressToggle" class="lbl-value">{{organization.address.street}}, {{organization.address.city}}, {{organization.address.state}}, {{organization.address.zipCode}} </p>
                                              <input *ngIf="editOrgAddressToggle" id="orgAddress" type="text" value="{{organization.address.street}}, {{organization.address.city}}, {{organization.address.state}}, {{organization.address.zipCode}}" class="input" formControlName="orgAddress" maxlength="50" autocomplete="disabled" />
                                      </div>

                                  </div>



                          </tr>
                      </tbody>
                  </table>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="40%">
                                  <div class="tabcard">
                                      <div class="tab-header">Additional Information</div>
                                      <div class="btndiv" *ngIf="!editOrgAdditionalInfoToggle" (click)="editOrgAdditionalInfo()"><span class="material-icons edit-detals">border_color</span>
                                          Edit
                                      </div>
                                      <div class="btn-save" *ngIf="editOrgAdditionalInfoToggle" (click)="saveOrgAdditionalInfo()"><span class="material-icons">save</span>
                                        Save</div>
                                  </div>
                                  <div class="user-details">
                                      <div class="left" [ngClass]="{ 'after-edit': editOrgAdditionalInfoToggle,'before-edit':!editOrgAdditionalInfoToggle }">
                                          <p class="small-txt">Website</p>
                                          <p *ngIf="!editOrgAdditionalInfoToggle" class="lbl-value">{{organization.Website}}</p>
                                          <input *ngIf="editOrgAdditionalInfoToggle" id="orgWebsite" type="text" value="{{organization.Website}}" class="input" formControlName="orgWebsite" maxlength="50" autocomplete="disabled" />
                                      </div>
                                      <div class="right" [ngClass]="{ 'after-edit': editOrgAdditionalInfoToggle,'before-edit':!editOrgAdditionalInfoToggle }">
                                          <p class="small-txt">Description</p>
                                          <p *ngIf="!editOrgAdditionalInfoToggle" class="lbl-value">{{organization.description}}</p>
                                          <input *ngIf="editOrgAdditionalInfoToggle" id="orgDescription" type="text" value="{{organization.description}}" class="input" formControlName="orgDescription" maxlength="50" autocomplete="disabled" />
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <div class="servicezones" *ngIf="activeTab=='servicezones'">
              <div class="tabcontent">
                  
                  <p class="psz-text">Please show the areas that you are willing to perform services by entering
                      below. You may enter zip codes with radii around those zip codes. You may also enter entire
                      states.<br> Note: Services that are not location specific (i.e. Report Reviews, Report Writing)
                      are not affected by the service zones listed below.
                  </p>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="100%" valign="top">
                                  <div class="tabcard">
                                      <div class="tab-header">1. Service Capabilities</div>
                                      <div class="btndiv" (click)="toggleServiceCapabilitiesModal()"><span class="material-icons edit-detals">border_color</span>
                                          Edit
                                      </div>
                                  </div>
                                  <div class="insurance-details">
                                      <div class="txt">7 capabilities selected.</div>
                                  </div>
                                  <div class="insurance-details">
                                      <div class="txt">Note: Services that are not location specific (I.e. Report
                                          Reviews, Report Writing) are not affected by the services nones listed.
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="100%" valign="top">
                                  <div class="tabcard">
                                      <div class="tab-header">2. Zones</div>
                                      <div class="add-row">
                                          <span class="material-icons">add</span>
                                          <p class="add-row-text">Add a Zone</p>
                                      </div>
                                  </div>
                                  <div class="insurance-details">
                                      <div class="txt mb-10">Add a zone and check capabilities from the selected services.
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                <div  class="zones-card">
                  <div class="zone-actions">
                    <div class="doc-txt">Documents needed</div>
                    <div class="fix-btn" (click)="toggleTab('documents')"> Fix</div>
                          <div class="btndiv" *ngIf="!editZoneAndCapabilitiesToggle" (click)="editZoneAndCapabilities()"><span class="material-icons edit-detals">border_color</span>
                            Edit
                        </div><div class="btn-save" *ngIf="editZoneAndCapabilitiesToggle" (click)="saveZoneAndCapabilities()"><span class="material-icons">save</span>
                          Save</div>
                  </div>
                   <div class="container">
                    <div class="first-block"> <div class="form-control">
                      <label for="exampleFormControlInput1" class="form-label">State <span class="req">*</span></label><br>
                      <select class="form-select">
                        <option value="volvo">California</option>
                      </select>
                    </div>
                  
                    <div class="form-control">
                      <label for="exampleFormControlInput1" class="form-label">County  </label><br>
                      <select class="form-select">
                        <option value="volvo">All Countries</option>
                      </select>
                    </div>
                  </div>
                    <div class="second-block">
                      <div class="txt-heading">SERVICES</div>
                      <div class="panel-fields">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field" />
                          <label>Select All</label>
                        </div>
                         
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                          <input type="checkbox" class="checkbox-field">
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
                    </div>
                   </div>
                </div>
                <div  class="zones-card-secondary">
                  <div class="zone-actions">
                    <div class="doc-txt">Documents needed</div>
                    <div class="fix-btn" (click)="toggleTab('documents')"> Fix</div>  
                    <div class="btndiv" *ngIf="!editZoneToggle" (click)="editZone()"><span class="material-icons edit-detals">border_color</span>
                        Edit
                    </div>
                    <div class="btn-save" *ngIf="editZoneToggle" (click)="saveZone()"><span class="material-icons">save</span>
                      Save</div>
                  </div>
               
                   <div class="container">
                    <div class="first-block-heading">  
                  <label class="first-txt">Nationwide</label>
                  </div>
                    <div class="second-block">
                      <div class="txt-heading">SERVICES</div>
                     
      
                      <div class="panel-fields1">
                        <div class="checkbox">
                         
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                         
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                         
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
                      <br>
                      <div class="panel-fields1">
                        <div class="checkbox">
                         
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                         
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                         
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
      <br>
                      <div class="panel-fields1">
                        <div class="checkbox">
                          
                          <label>PCA Complete ASTM</label>
                        </div>
                        <div class="checkbox">
                          
                          <label>PCA Visit Only</label>
                        </div>
                        <div class="checkbox">
                           
                          <label>PCA Report Writing</label>
                        </div>
                      </div>
                    </div>
                   </div>
                </div>
                 
                </div>
            </div>
          <div class="documents" *ngIf="activeTab=='documents'">
              <div class="tabcontent">
                <div  class="profile-documents">
                    <div class="zone-actions">
                            <div class="documents-dialog__content__form__upload">
                                <label for="documents-dialog-file-upload" class="btn"><span
                                        class="material-icons">upload</span>Upload</label>
                                <input id="documents-dialog-file-upload" type="file" required />
                            </div>
                    </div>
                     <div class="documents-container">
                      <div class="documents-first-block">  
                    <label class="first-txt">Profile Documents</label>
                    </div>
                      <div class="second-block" *ngFor="let item of this.documents.profile.profileDocuments">
                            <div class="card">
                                <div class="card-row"><a (click)="downloadDocument(item.path)"><span class="material-icons">download</span></a></div>
                                <div class="card-row">
                                    {{item.name}}</div>
                                <div class="card-row"><div class="btn-delete" (click)="deleteDocument(item.id)"><span class="material-icons"
                                    title="Delete">delete</span>
                            </div></div>
                            </div>
                        </div>
                     </div>
                  </div>
               
                <div class="zone-documents-label">
                    Zone Documents
                </div>
                <div  class="profile-documents" *ngFor="let zone of documents.profile.zones">
                    <div class="zone-actions">
                      <div *ngIf="zone.name==='Nevada'" class="doc-txt">Documents needed</div> <!--use proper condition for red marked type docments in *ngIf="zone.name==='Nevada'" -->
                            <div class="documents-dialog__content__form__upload">
                                <label for="documents-dialog-file-upload" class="btn" [ngClass]="{'doc-need-upload': zone.name==='Nevada'}"> <!--use proper condition for red marked type docments in [ngClass]="{'doc-need-upload': zone.name==='Nevada'}" -->
                                    <span class="material-icons">upload</span>Upload</label>
                                <input id="documents-dialog-file-upload" type="file" required />
                            </div>
                    </div>
                     <div class="documents-container">
                      <div class="documents-first-block">  
                    <label class="first-txt">{{zone.name}}</label>
                    </div>
                      <div class="second-block" *ngFor="let item of zone.zone">
                            <div class="card">
                                <div class="card-row"><a (click)="downloadDocument(item.path)"><span class="material-icons">download</span></a></div>
                                <div class="card-row">
                                    {{item.name}}</div>
                                <div class="card-row"><div class="btn-delete" (click)="deleteDocument(item.id)"><span class="material-icons"
                                    title="Delete">delete</span>
                            </div></div>
                            </div>
                        </div>
                     </div>
                  </div>
            </div>
          </div>
      </div>
  </div>
</div>
<service-capabilities-dialog [active]="serviceCapabilitiesActive"
[serviceCapabilities]="serviceCapabilities"
[serviceCapabilitiesType]="serviceCapabilitiesType"
(modalClosed)="toggleServiceCapabilitiesModal()"
[ngClass]="{ 'documents-dialog--active': serviceCapabilitiesActive }"></service-capabilities-dialog>