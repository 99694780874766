<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>Decline RFP Invitation</h2>

    <div class="form-control">
      <label for="exampleFormControlInput1" class="reason-label">Reason </label>
      <br />
      <br />
      <select
        name="reason"
        id="reason"
        class="form-select"
        (change)="selectReason($event)"
      >
        <option *ngFor="let reason of declineReasons" [value]="reason.value">
          {{ reason.label }}
        </option>
      </select>
    </div>
    <div *ngIf="reasonSelectedIndex === 5">
      <div class="error-message">
        *Required (please give more information below)
      </div>
      <input
        type="text"
        placeholder="Reason"
        [(ngModel)]="otherReason"
        (input)="checkOtherReason()"
      />
    </div>
    <textarea
      class="text-area"
      id="documents-dialog-file-notes"
      placeholder="Comments"
      [(ngModel)]="comments"
    ></textarea>
    <div class="documents-dialog__content__footer">
      <div class="documents-dialog__content__footer__service-type">
        <div *ngIf="siteType">
          <p class="documents-dialog__content__footer__service-type__label">
            Service Type
          </p>
          <p class="service-type">{{ siteType }}</p>
        </div>
      </div>
      <div class="documents-dialog__content__footer__service-type">
        <div *ngIf="address">
          <p class="documents-dialog__content__footer__service-type__label">
            Location
          </p>
          <p class="location">
            <span *ngIf="address.streetAddress"
              >{{ address.streetAddress }}<br
            /></span>
            {{ address.cityStateZip }}
          </p>
        </div>
      </div>
      <div class="btnalign">
        <button
          class="documents-dialog__content__footer__submit pull-right"
          [disabled]="isDisabled"
          (click)="declineInvitation()"
        >
          Decline
        </button>
        <button class="defaultbtn" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
