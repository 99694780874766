import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  private tokenKey = 'token';

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public saveProposal(proposal: any): void {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    this.http
      .put<any>(environment.apiUrl + '/proposal', JSON.stringify(proposal), {
        headers,
        responseType: 'json',
      })
      .pipe(
        tap((response) => {
          let apiResponse: any = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        })
      )
      .subscribe();
  }

  public submitBid(projectDetail: any, userId: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      environment.apiUrl + `/proposal/submit-bid/${userId}`,
      JSON.stringify(projectDetail),
      {
        headers,
        responseType: 'json',
      }
    );
  }

  public editBid(proposal: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http
      .put<any>(
        environment.apiUrl + '/proposal/edit-bid',
        JSON.stringify(proposal),
        {
          headers,
          responseType: 'json',
        }
      )
      .pipe(
        tap((response) => {
          const apiResponse: any = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        })
      )
      .subscribe();
  }

  public declineInvitation(proposal: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      environment.apiUrl + '/proposal/decline-invitation',
      JSON.stringify(proposal),
      {
        headers,
        responseType: 'json',
      }
    );
  }

  public submitReadyForReview(proposalId: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      `${environment.apiUrl}/proposal/submit-review/${proposalId}`,
      {},
      { headers, responseType: 'json' }
    );
  }

  public submitRevision(proposalId: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(
      `${environment.apiUrl}/proposal/submit-review-revision/${proposalId}`,
      {},
      { headers, responseType: 'json' }
    );
  }
}
