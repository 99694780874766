import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private tokenKey = 'token';
  constructor(private http: HttpClient, private router: Router) {}
  public login(email: string, password: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/user/login',
      {
        email: email?.toLowerCase(),
        password: password,
      },
      { responseType: 'json' }
    );
  }

  public register(register: User): Observable<any> {
    return this.http.post<User>(environment.apiUrl + '/user/register', {
      firstName: register.firstName,
      lastName: register.lastName,
      password: register.password,
      email: register.email?.toLowerCase(),
      phone: register.phone,
      referredBy: register.referredBy,
    });
  }

  public getToken(): string | null {
    return this.isLoggedIn()
      ? localStorage.getItem(this.tokenKey) ||
          sessionStorage.getItem(this.tokenKey)
      : null;
  }
  public isLoggedIn(): boolean {
    let token =
      localStorage.getItem(this.tokenKey) ||
      sessionStorage.getItem(this.tokenKey);
    return !!token;
  }

  isTokenExpired(): boolean {
    var expiry = 0;
    let token =
      localStorage.getItem(this.tokenKey) ||
      sessionStorage.getItem(this.tokenKey);
    if (token != null) {
      expiry = JSON.parse(atob(token.split('.')[1])).exp;
    }
    return expiry - Math.floor(new Date().getTime() / 1000) <= 0;
  }

  public forgotPassword(email: String): Observable<any> {
    // making sure '+' is not converted to a space
    const encodedEmail = encodeURIComponent(email.toString());
    return this.http.post(
      environment.apiUrl +
        '/user/send-temp-password?email=' +
        encodedEmail?.toLowerCase() +
        '',
      { responseType: 'json' }
    );
  }

  public resetPassword(email: string, password: String): Observable<any> {
    const encodedEmail = encodeURIComponent(email.toString());
    return this.http.put(
      environment.apiUrl + '/user/password',
      { email: email?.toLowerCase(), password: password },
      { responseType: 'json' }
    );
  }

  public newPassword(
    email: string,
    password: string,
    newPassword: string
  ): Observable<any> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const encodedEmail = encodeURIComponent(email.toString());
    return this.http.put(
      environment.apiUrl + '/user/new-password',
      {
        email: email?.toLowerCase(),
        password: password,
        newPassword: newPassword,
      },
      { headers: headers, responseType: 'json' }
    );
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
  }
}
