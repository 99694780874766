<div class="menu" *ngIf="active">
  <div class="notifications-title">Notifications</div>
  <button class="documents-dialog__content__close" (click)="closeDialog()">
    <span class="material-icons">close</span>
  </button>
  <div class="menu-header">
    <div class="proposal-details__header__tabs">
      <button
        (click)="toggleTab('unread'); unread()"
        class="tab-btn"
        [class.active]="activeTab == 'unread'"
      >
        Unread
      </button>
      <button
        (click)="toggleTab('read'); read()"
        class="tab-btn"
        [class.active]="activeTab == 'read'"
      >
        Read
      </button>
      <button
        (click)="toggleTab('markallread'); markAllAsRead()"
        class="tab-btn"
        [class.active]="activeTab == 'markallread'"
      >
        Mark All As Read
      </button>
    </div>
  </div>
  <div class="menu-body">
    <div class=" " *ngIf="activeTab == 'unread'">
      <div class="tab-content" *ngFor="let notification of unreadNotifications">
        <div
          class="info-navigation__left__button info-navigation__left__button--proposal"
          (click)="markAsRead(notification)"
        >
          <span class="material-icons">{{ notification.icon }}</span>
          <a
            ><p>{{ notification.message }} - {{ notification.timeAgo }}</p></a
          >
        </div>
      </div>
    </div>
    <div class=" " *ngIf="activeTab == 'read'">
      <div class="tab-content" *ngFor="let notification of readNotifications">
        <div
          class="info-navigation__left__button info-navigation__left__button--proposal"
        >
          <span class="material-icons">{{ notification.icon }}</span>
          <a
            ><p>{{ notification.message }} - {{ notification.timeAgo }}</p></a
          >
        </div>
      </div>
    </div>
    <div class=" " *ngIf="activeTab == 'markallread'">
      <div
        class="tab-content"
        *ngFor="let notification of markAllNotifications"
      >
        <div
          class="info-navigation__left__button info-navigation__left__button--proposal"
        >
          <span class="material-icons">{{ notification.icon }}</span>
          <a
            ><p>{{ notification.message }} - {{ notification.timeAgo }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
