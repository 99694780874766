<div class="back-btn">
  <span class="material-icons">keyboard_arrow_left</span>
  <a routerLink="/login" class="back-btn">Return To Login</a>
</div>
<div class="login-container">
  <form>
    <a routerLink="/invitations"
      ><img class="login-logo" src="../../assets/img/logo.png" alt="logo"
    /></a>
    <div class="title-header">Thank you for registering!</div>
    <div class="title">Your account is now active.</div>
    <hr />
    <div class="sm-text">
      <p>If you have any questions, email us at connect&#64;cretelligent.com</p>
      <p class="text-bold">Thank you!<br />Connect Team</p>
    </div>

    <hr />

    <div class="panel-fields">
      <button type="submit" class="btn btn-primary" (click)="gotoLogin()">
        Return To Login
      </button>
    </div>
  </form>
</div>
