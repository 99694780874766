import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';
import { AtpSignAcceptedDialogComponent } from '../atp-sign-accepted-dialog/atp-sign-accepted-dialog.component';

@Component({
  selector: 'signatp-dialog',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    DeclineDialogComponent,
    AtpSignAcceptedDialogComponent,
  ],
  templateUrl: './sign-atp-dialog.component.html',
  styleUrl: './sign-atp-dialog.component.scss',
})
export class SignAtpDialogComponent {
  isDeclineActive: boolean = false;
  isATPSignedAccepted: boolean = false;
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';
  @Input() modalHeaderTitle: string = '';
  @Input() modalBody: string = '';
  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    this.closeDialog();
    this.toggleATPAcceptedModal();
  }
  toggleATPAcceptedModal() {
    this.isATPSignedAccepted = !this.isATPSignedAccepted;
  }
  toggleDeclineModal() {
    this.isDeclineActive = !this.isDeclineActive;
  }
  decline() {
    this.closeDialog();
    this.toggleDeclineModal();
  }
}
