import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoGroup } from '../info-group/info-group.component';
import { InfoNavigation } from '../info-navigation/info-navigation.component';
import { ProjectService } from '../services/project.service';
import { User } from '../model/user';
import { AllBidsSubmittedDialogComponent } from '../allbids-submitted-dialog/allbids-submitted-dialog.component';
import { ModalService } from '../services/modal.service';
import { DeclineSuccessComponent } from '../decline-success/decline-success.component';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';
import { AcceptedOfferedProposalDialogComponent } from '../accepted-offered-proposal-dialog/accepted-offered-proposal-dialog.component';

@Component({
  standalone: true,
  selector: 'info-invitation',
  imports: [
    CommonModule,
    InfoGroup,
    InfoNavigation,
    AllBidsSubmittedDialogComponent,
    DeclineSuccessComponent,
    AcceptedOfferedProposalDialogComponent,
  ],
  templateUrl: './invitations-page.component.html',
  styleUrl: './invitations-page.component.scss',
})
export class InvitationsPage {
  data: any = null;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  bidSubmittedActive: boolean = false;
  declineSuccessActive: boolean = false;
  isOfferAcceptedActive: boolean = false;
  address: any = { streetAddress: '', cityStateZip: '' };
  siteType: string = '';
  loading: boolean = true;
  isFixedPrice: boolean = false;
  isAtp: boolean = false;

  constructor(
    private projectService: ProjectService,
    private modalService: ModalService,
    private utilityService: CommonUtilityServiceService
  ) {}

  ngOnInit() {
    this.fetchData();
    this.modalService.isSubmittedAllBidsModalOpen.subscribe(
      (isOpen) => (this.bidSubmittedActive = isOpen)
    );
    this.modalService.isDeclineModalOpen.subscribe((detail) => {
      if (detail.isOpen) {
        this.declineSuccessActive = true;
        this.isFixedPrice = detail.isFixedPrice;
        this.isAtp = detail.isAtp;
        if (detail.siteDetail.site) {
          this.address = this.utilityService.formatAddress(detail.siteDetail);
          this.siteType = detail.siteDetail.site.siteType;
        }
      }
    });
    this.modalService.isOfferAcceptedModalOpen.subscribe((isOpen) => {
      this.isOfferAcceptedActive = isOpen;
    });
  }

  toggleCloseSubmittedAllBidsModal() {
    this.modalService.toggleCloseSubmittedAllBidsModal();
  }

  toggleCloseDeclineSuccessModal() {
    this.modalService.toggleCloseDeclineSuccessModal();
  }

  toggleCloseOfferAcceptedModal() {
    this.modalService.toggleCloseOfferAcceptedModal();
  }

  fetchData() {
    this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
      this.data = result
        .map((parent: any) => ({
          ...parent,
          sites: parent.sites.filter(
            (child: any) =>
              child.proposal.status === 'INVITATION' ||
              child.proposal.status === 'OFFERED'
          ),
        }))
        .filter((parent: any) => parent.sites.length > 0 && parent.projectId);
      this.loading = false;
    });
  }
}
