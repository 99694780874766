import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignAtpDialogComponent } from '../sign-atp-dialog/sign-atp-dialog.component';
import { AllbidsDialogComponent } from '../allbids-dialog/allbids-dialog.component';
import { SiteService } from '../services/site.service';
import { User } from '../model/user';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectService } from '../services/project.service';
import { ModalService } from '../services/modal.service';
import { ProjectSpecialRequestDialogComponent } from '../project-special-request-dialog/project-special-request-dialog.component';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'info-header',
  imports: [
    CommonModule,
    SignAtpDialogComponent,
    AllbidsDialogComponent,
    ProjectSpecialRequestDialogComponent,RouterLink
  ],
  templateUrl: './info-header.component.html',
  styleUrl: './info-header.component.scss',
})
export class InfoHeader {
  @Input() expanded: boolean = false;
  @Input() data: any = '';
  @Input() type: string = '';
  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() siteType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: number = 0;
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';
  @Input() atpDocumentPath: SafeResourceUrl = '';
  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showSiteType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showActions: boolean = true;
  @Input() numberOfBids: number = 0;

  @Output() toggled = new EventEmitter<boolean>();

  openState: boolean = this.expanded;
  serviceInfoToggle: boolean = false;
  signAtpActive: boolean = false;
  allBidsActive: boolean = false;
  editToggle: boolean = false;
  showAtp: boolean = true;
  atpDocument: any;
  childModalHeaderTitle: string = 'ATP Signed & Proposals Accepted';
  childModalBody: string =
    'You have signed the ATP and proposals are available for view in Active projects.';
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  enableSubmitAllBids: boolean = false;
  specialRequestToggle: boolean = false;

  constructor(
    private siteService: SiteService,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.openState = this.expanded;
    this.showAtp = this.data?.sites?.some(
      (site: { proposal: { status: string } }) =>
        site.proposal?.status === 'BID_WON'
    );
    this.enableSubmitAllBids = this.numberOfBids = this.data.sites.filter(
      (siteDetail: any) =>
        siteDetail.proposal.bid > 0 && siteDetail.proposal.proposedTurnTime > 0
    ).length;
  }

  toggleSubmitAllBidsModal() {
    this.allBidsActive = !this.allBidsActive;
  }

  toggleAtpAcceptedModal() {
    this.modalService.toggleOpenSignedAtpModal();
  }

  toggleSpecialRequestModal() {
    this.specialRequestToggle = !this.specialRequestToggle;
  }

  projectOverview() {
    alert('projectOverview');
  }

  accordion() {
    this.openState = !this.openState;
    this.toggled.emit(this.openState);
  }

  generateATP() {
    this.toastr.info(
      'Please wait a moment while we generate your ATP document. This process may take a few seconds.'
    );
    let atpDocumentResponse: any = null;
    this.siteService
      .generateAtp(this.data, this.user.id ?? '')
      .subscribe((response) => {
        if (response) {
          atpDocumentResponse = response;
          if (
            atpDocumentResponse.status === 'SUCCESS' &&
            atpDocumentResponse.data
          ) {
            this.atpDocument = atpDocumentResponse.data;
            if (this.atpDocument.documentPath) {
              this.atpDocumentPath =
                this.sanitizer.bypassSecurityTrustResourceUrl(
                  this.atpDocument.documentPath
                );
              this.toggleATPModal();
            } else {
              this.toastr.error(
                'There was an issue with genereating your ATP document. Please try again.'
              );
            }
          } else {
            this.toastr.error(atpDocumentResponse.message);
          }
        } else {
          this.toastr.error(
            'There was an issue with genereating your ATP document. Please try again.'
          );
        }
      });
  }

  signATP(event: { fullName: string; title: string }) {
    this.toastr.info('Accepting your signed ATP...');
    this.siteService
      .signAtp(
        {
          projectDetail: this.data,
          atpDocument: this.atpDocument,
          atpSignedBy: event.fullName,
          atpSignedByTitle: event.title,
        },
        this.user.id ?? ''
      )
      .subscribe((response) => {
        const updatedProjectDetail = response.data;
        this.projectService.updateProjectDataSubjectWithProjectDetail(
          updatedProjectDetail
        );
        this.toggleAtpAcceptedModal();
      });
  }

  toggleATPModal() {
    this.signAtpActive = !this.signAtpActive;
  }

  declineATP(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    const updatedSiteDetails = this.data.sites.map((siteDetail: any) => {
      const updatedProposal = {
        ...siteDetail.proposal,
        declineReason: event.declineReason,
        declineOtherReason: event.declineOtherReason,
        declineComments: event.declineComments,
      };
      return { ...siteDetail, proposal: updatedProposal };
    });
    this.siteService
      .cancel({ ...this.data, sites: updatedSiteDetails })
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProjectDetail = response.data;
          if (updatedProjectDetail) {
            this.projectService.updateProjectDataSubjectWithProjectDetail(
              updatedProjectDetail
            );
            this.modalService.toggleOpenDeclineSuccessModal({});
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
