import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { User } from '../model/user';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ServiceCapabilitiesDialogComponent } from '../service-capabilities-dialog/service-capabilities-dialog.component';
import { MiscService } from '../services/misc.service';
import { ToastrService } from 'ngx-toastr';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from '../services/user.service';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';
import { DocumentService } from '../services/document.service';
@Component({
  selector: 'app-manage-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ServiceCapabilitiesDialogComponent,
    FormsModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  templateUrl: './manage-account.component.html',
  styleUrl: './manage-account.component.scss',
})
export class ManageAccountComponent {
  tabState: string = 'rfp';
  activeTab: string = 'profile';
  emailRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  id: string = '';
  user: User = {};
  // profile data
  protected nameUpdate = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
  });

  protected emailUpdate = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegx),
    ]),
  });
  phone: string = '';
  phoneOs: string = '';
  streetAddress: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  ccName1: string = '';
  ccName2: string = '';
  ccEmail1: string = '';
  ccEmail2: string = '';
  ccPhone1: string = '';
  ccPhone2: string = '';
  bio: string = '';
  timezone: any = {};

  // organization data
  orgName: string = '';
  orgEmail: string = '';
  orgPhone: string = '';
  orgStreetAddress: string = '';
  orgCity: string = '';
  orgState: string = '';
  orgDecodedState: string = '';
  orgZipcode: string = '';
  orgWebsite: string = '';
  orgDescription: string = '';

  currentConversation: string = '';

  // services and capabilities
  serviceCapabilitiesActive: boolean = false;
  serviceCapabilitiesType: string = '';
  stateCountyDetails: any = [];
  serviceZoneStateCountyDetails: any = [];
  serviceZoneCounties: any = [];
  timeZones: any = [];
  serviceTypes: any = [];
  toggleNewServiceZone: boolean = false;
  allNewZoneServiceTypesSelected: boolean = false;
  newZoneServiceTypes: any = [];
  selectedNewZoneState: any = '';
  selectedNewCounties: any = [];
  currentServiceZone: any = '';
  allUpdateZoneServiceTypesSelected: boolean = false;

  editNameToggle: boolean = false;
  editEmailToggle: boolean = false;
  editPhoneToggle: boolean = false;
  editAddressToggle: boolean = false;
  editCcInfoToggle: boolean = false;
  editProfileAdditionalInfoToggle: boolean = false;
  editOrgInfoToggle: boolean = false;
  editOrgAddressToggle: boolean = false;
  editOrgAdditionalInfoToggle: boolean = false;
  editZoneAndCapabilitiesToggle: boolean = false;
  editZoneToggle: boolean = false;
  updateSucess: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private miscService: MiscService,
    private toastr: ToastrService,
    private userService: UserService,
    private utilityService: CommonUtilityServiceService,
    private documentService: DocumentService,
    public router: Router
  ) {}

  ngOnInit(): void {
    // set tab state from url
    this.route.queryParams.subscribe((params) => {
      this.activeTab = params['tab'] || this.activeTab;
    });
    this.user = <User>JSON.parse(localStorage.getItem('user') ?? '');
    this.miscService.fetchStateCountyDetails().subscribe((result: any) => {
      this.stateCountyDetails = result;
      const updatedStateCounties = result.map((stateCountyDetail: any) => {
        return {
          ...stateCountyDetail,
          counties: ['All Counties', ...stateCountyDetail.counties],
        };
      });
      this.serviceZoneStateCountyDetails = [
        { state: 'NATIONWIDE', name: 'Nationwide', counties: [] },
        ...updatedStateCounties,
      ];
      this.selectedNewZoneState = this.serviceZoneStateCountyDetails[0];
    });

    this.miscService.fetchTimeZones().subscribe((result: any) => {
      this.timeZones = result;
    });

    this.miscService.fetchServiceTypes().subscribe((result: any) => {
      if (result) {
        this.serviceTypes = result.map((serviceType: any) => {
          return {
            ...serviceType,
            checked:
              this.user?.selectedServiceTypes?.some(
                (userServiceType: any) =>
                  userServiceType.type === serviceType.type
              ) || false,
          };
        });
        this.newZoneServiceTypes = result.filter((serviceType: any) =>
          this.user?.selectedServiceTypes?.some(
            (uerServiceType: any) => uerServiceType.type === serviceType.type
          )
        );
      }
    });
    if (this.user) {
      this.nameUpdate.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      });
      this.emailUpdate.patchValue({
        email: this.user.email,
      });
      this.phone = this.user.phone ?? '';
      this.phoneOs = this.user.phoneOs ?? '';
      // populating the user's address
      const location = this.user.location;
      if (location) {
        this.streetAddress = location.street ?? '';
        this.city = location.city ?? '';
        if (location.state) {
          this.state = location.state;
        }
        this.zipcode = location.zipcode ?? '';
      }
      this.ccName1 = this.user.ccName1 ?? '';
      this.ccName2 = this.user.ccName2 ?? '';
      this.ccEmail1 = this.user.ccEmail1 ?? '';
      this.ccEmail2 = this.user.ccEmail2 ?? '';
      this.ccPhone1 = this.user.ccPhone1 ?? '';
      this.ccPhone2 = this.user.ccPhone2 ?? '';
      this.bio = this.user.bio ?? '';
      if (this.user.timezone) {
        this.timezone = this.user.timezone;
      }
      const organization = this.user.organization;
      if (organization) {
        this.orgName = organization.name;
        this.orgEmail = organization.email;
        this.orgPhone = organization.phone;
        const orgLocation = organization.location;
        if (orgLocation) {
          this.orgStreetAddress = orgLocation.street;
          this.orgCity = orgLocation.city;
          this.orgState = orgLocation.state;
          this.orgZipcode = orgLocation.zipcode;
        }
        this.orgWebsite = organization.website;
        this.orgDescription = organization.description;
      }
    }
  }

  decodeState(state: string): string {
    return this.utilityService.decodeUsState(state);
  }

  formatCounty(counties: any) {
    return counties?.join(', ');
  }

  goBack() {
    this.router.navigate(['invitations']);
  }

  changeConversation(conversation: string) {
    this.currentConversation = conversation;
  }

  toggleTab(tab: string) {
    this.activeTab = tab;

    // add selected tab to end of url without adding to history
    this.location.replaceState(
      this.location.path().split('?')[0],
      `tab=${tab}`
    );
  }

  toggleServiceCapabilitiesModal() {
    this.serviceCapabilitiesActive = !this.serviceCapabilitiesActive;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
  }

  editName() {
    this.editNameToggle = !this.editNameToggle;
  }

  editEmail() {
    this.editEmailToggle = !this.editEmailToggle;
  }

  editPhone() {
    this.editPhoneToggle = !this.editPhoneToggle;
  }

  editCCInfo() {
    this.editCcInfoToggle = !this.editCcInfoToggle;
  }

  editAddress() {
    this.editAddressToggle = !this.editAddressToggle;
  }

  editProfileAddtionalInfo() {
    this.editProfileAdditionalInfoToggle =
      !this.editProfileAdditionalInfoToggle;
  }

  editOrgInfo() {
    this.editOrgInfoToggle = !this.editOrgInfoToggle;
  }

  editOrgAddress() {
    this.editOrgAddressToggle = !this.editOrgAddressToggle;
  }

  editOrgAdditionalInfo() {
    this.editOrgAdditionalInfoToggle = !this.editOrgAdditionalInfoToggle;
  }

  addNewServiceZone() {
    this.toggleNewServiceZone = true;
  }

  editServiceZone(serviceZone: any) {
    let currentStateCounty;
    if (serviceZone.nationwide) {
      currentStateCounty = this.serviceZoneStateCountyDetails[0];
    } else {
      currentStateCounty = this.serviceZoneStateCountyDetails.find(
        (stateCounty: any) => stateCounty.state === serviceZone.state
      );
    }
    const pendingUpdates = {
      selectedState: currentStateCounty,
      selectedCounties: serviceZone.counties,
      selectedServices: serviceZone.services,
    };
    this.currentServiceZone = {
      ...pendingUpdates,
      state: serviceZone.nationwide ? 'NATIONWIDE' : serviceZone.state,
    };
    this.allUpdateZoneServiceTypesSelected =
      this.currentServiceZone?.selectedServices.every(
        (service: any) => service.enabledForZone
      );
    this.editZoneToggle = !this.editZoneToggle;
  }

  saveName() {
    if (!this.nameUpdate.valid) {
      this.toastr.error('First name and last name are required.');
    } else if (
      this.nameUpdate.value.firstName !== this.user.firstName ||
      this.nameUpdate.value.lastName !== this.user.lastName
    ) {
      this.updateUser({
        ...this.user,
        firstName: this.nameUpdate.value.firstName ?? '',
        lastName: this.nameUpdate.value.lastName ?? '',
      });
      this.editNameToggle = !this.editNameToggle;
    } else {
      this.editNameToggle = !this.editNameToggle;
    }
  }

  savePhone() {
    if (this.phone !== this.user.phone || this.phoneOs !== this.user.phoneOs) {
      this.updateUser({
        ...this.user,
        phone: this.phone,
        phoneOs: this.phoneOs,
      });
    }
    this.editPhoneToggle = !this.editPhoneToggle;
  }

  saveEmail() {
    if (!this.emailUpdate.valid) {
      this.toastr.error('Please enter a valid email.');
    } else if (
      this.emailUpdate.value.email &&
      this.emailUpdate.value.email !== this.user.email
    ) {
      this.userService
        .updateUserEmail({
          ...this.user,
          email: this.emailUpdate.value.email.toLowerCase(),
        })
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            const token = response.token;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              localStorage.setItem('token', token);
              sessionStorage.setItem('token', token);
              this.toastr.success(apiResponse.message);
              this.editEmailToggle = !this.editEmailToggle;
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    } else {
      this.editEmailToggle = !this.editEmailToggle;
    }
  }

  saveAddress() {
    const location = this.user.location;
    if (
      !location ||
      this.streetAddress !== location?.street ||
      this.city !== location?.city ||
      (this.state && this.state !== location?.state) ||
      this.zipcode !== location?.zipcode
    ) {
      let updatedLocation = {
        ...location,
        street: this.streetAddress,
        city: this.city,
        zipcode: this.zipcode,
      };
      if (this.state) {
        updatedLocation = { ...updatedLocation, state: this.state };
      }
      this.updateUser({ ...this.user, location: updatedLocation });
    }
    this.editAddressToggle = !this.editAddressToggle;
  }

  saveCCInfo() {
    if (
      this.ccName1 !== this.user.ccName1 ||
      this.ccName2 !== this.user.ccName2 ||
      this.ccEmail1 !== this.user.ccEmail1 ||
      this.ccEmail2 !== this.user.ccEmail2 ||
      this.ccPhone1 !== this.user.ccPhone1 ||
      this.ccPhone2 !== this.user.ccPhone2
    ) {
      this.updateUser({
        ...this.user,
        ccName1: this.ccName1,
        ccName2: this.ccName2,
        ccEmail1: this.ccEmail1,
        ccEmail2: this.ccEmail2,
        ccPhone1: this.ccPhone1,
        ccPhone2: this.ccPhone2,
      });
    }
    this.editCcInfoToggle = !this.editCcInfoToggle;
  }

  saveProfileAdditionalInfo() {
    if (
      this.bio !== this.user.bio ||
      this.timezone.id !== this.user.timezone?.id
    ) {
      this.updateUser({ ...this.user, bio: this.bio, timezone: this.timezone });
    }
    this.editProfileAdditionalInfoToggle =
      !this.editProfileAdditionalInfoToggle;
  }

  saveOrgInfo() {
    if (
      this.orgName !== this.user.organization?.name ||
      this.orgEmail !== this.user.organization?.email ||
      this.orgPhone !== this.user.organization?.phone
    ) {
      const updatedOrg = {
        ...this.user.organization,
        name: this.orgName,
        email: this.orgEmail,
        phone: this.orgPhone,
      };
      this.updateUser({ ...this.user, organization: updatedOrg });
    }
    this.editOrgInfoToggle = !this.editOrgInfoToggle;
  }

  saveOrgAddress() {
    const orgLocation = this.user.organization?.location;
    if (
      !orgLocation ||
      this.orgStreetAddress !== orgLocation?.street ||
      this.orgCity !== orgLocation?.city ||
      (this.orgState && this.orgState !== orgLocation?.state) ||
      this.orgZipcode !== orgLocation?.zipcode
    ) {
      let updatedLocation = {
        ...orgLocation,
        street: this.orgStreetAddress,
        city: this.orgCity,
        zipcode: this.orgZipcode,
      };
      if (this.orgState) {
        updatedLocation = { ...updatedLocation, state: this.orgState };
      }
      const updatedOrg = {
        ...this.user.organization,
        location: updatedLocation,
      };
      this.updateUser({ ...this.user, organization: updatedOrg });
    }
    this.editOrgAddressToggle = !this.editOrgAddressToggle;
  }

  saveOrgAdditionalInfo() {
    if (
      this.orgDescription !== this.user.organization?.description ||
      this.orgWebsite !== this.user.organization?.website
    ) {
      const updatedOrg = {
        ...this.user.organization,
        description: this.orgDescription,
        website: this.orgWebsite,
      };
      this.updateUser({ ...this.user, organization: updatedOrg });
    }
    this.editOrgAdditionalInfoToggle = !this.editOrgAdditionalInfoToggle;
  }

  saveServiceTypeCheckboxChange(serviceType: any) {
    let deleted = false;
    if (serviceType.checked) {
      this.newZoneServiceTypes = [
        ...this.newZoneServiceTypes,
        { ...serviceType, enabledForZone: false },
      ];
    } else {
      // user is removing service type
      deleted = true;
      this.newZoneServiceTypes = this.newZoneServiceTypes?.filter(
        (currentServiceType: any) =>
          currentServiceType.type !== serviceType.type
      );
    }
    this.userService
      .updateServiceCapability(deleted, this.user, serviceType.type)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedUser = response.data;
          if (updatedUser) {
            this.user = { ...this.user, ...updatedUser };
            localStorage.setItem('user', JSON.stringify(this.user));
            this.userService.updateUserSubject(this.user);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  handleSelectAllNewZoneServiceTypes() {
    this.newZoneServiceTypes = this.newZoneServiceTypes.map(
      (currentServiceType: any) => {
        return {
          ...currentServiceType,
          enabledForZone: this.allNewZoneServiceTypesSelected,
        };
      }
    );
  }

  handleSelectAllUpdateZoneServiceTypes() {
    const updatedServices = this.currentServiceZone?.selectedServices?.map(
      (currentServiceType: any) => {
        return {
          ...currentServiceType,
          enabledForZone: this.allUpdateZoneServiceTypesSelected,
        };
      }
    );
    this.currentServiceZone = {
      ...this.currentServiceZone,
      selectedServices: updatedServices,
    };
  }

  handleNewZoneServiceTypeCheckboxChange() {
    this.allNewZoneServiceTypesSelected = this.newZoneServiceTypes.every(
      (serviceType: any) => serviceType.enabledForZone
    );
  }

  handleUpdateZoneServiceTypeCheckboxChange() {
    this.allUpdateZoneServiceTypesSelected =
      this.currentServiceZone.selectedServices.every(
        (serviceType: any) => serviceType.enabledForZone
      );
  }

  handleNewZoneStateChange() {
    this.serviceZoneCounties = this.selectedNewZoneState.counties;
    this.selectedNewCounties = [];
  }

  handleUpdateZoneStateChange() {
    this.currentServiceZone = {
      ...this.currentServiceZone,
      selectedCounties: [],
    };
  }

  isOptionDisabled(counties: any, option: string) {
    return (
      (counties.some((county: string) => county === 'All Counties') &&
        option !== 'All Counties') ||
      (counties.some((county: string) => county !== 'All Counties') &&
        option === 'All Counties')
    );
  }

  saveNewServiceZone() {
    if (
      !this.newZoneServiceTypes.some(
        (serviceType: any) => serviceType.enabledForZone === true
      )
    ) {
      this.toastr.error('Please select at least one service.');
    } else if (
      this.selectedNewZoneState.state !== 'NATIONWIDE' &&
      this.selectedNewCounties?.length === 0
    ) {
      this.toastr.error('Please select at least one county.');
    } else if (
      this.user?.serviceZones?.some(
        (serviceZone: any) =>
          serviceZone.state === this.selectedNewZoneState.state
      )
    ) {
      this.toastr.error('A service zone already exists for this state.');
    } else if (
      this.selectedNewZoneState.state === 'NATIONWIDE' &&
      this.user?.serviceZones?.some(
        (serviceZone: any) => serviceZone.nationwide
      )
    ) {
      this.toastr.error('A natiowide service zone already exists.');
    } else {
      let newServiceZone = {};
      if (this.selectedNewZoneState.state === 'NATIONWIDE') {
        newServiceZone = {
          nationwide: true,
          services: this.newZoneServiceTypes,
        };
      } else {
        newServiceZone = {
          state: this.selectedNewZoneState.state,
          counties: this.selectedNewCounties,
          services: this.newZoneServiceTypes,
        };
      }
      this.userService
        .addNewServiceZone(this.user, newServiceZone)
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
      this.toggleNewServiceZone = false;
      this.resetNewServiceZoneValues();
    }
  }

  saveUpdatedServiceZone() {
    if (
      this.currentServiceZone.selectedState.state === 'NATIONWIDE' &&
      this.currentServiceZone.state !== 'NATIONWIDE' &&
      this.user.serviceZones?.some((serviceZone: any) => serviceZone.nationwide)
    ) {
      this.toastr.error('A natiowide service zone already exists.');
    } else if (
      this.currentServiceZone.selectedState.state !==
        this.currentServiceZone.state &&
      this.user.serviceZones?.some(
        (serviceZone: any) =>
          serviceZone.state === this.currentServiceZone.selectedState.state
      )
    ) {
      this.toastr.error('A service zone already exists for this state.');
    } else if (
      this.currentServiceZone.selectedState.state !== 'NATIONWIDE' &&
      this.currentServiceZone.selectedCounties?.length === 0
    ) {
      this.toastr.error('Please select at least one county');
    } else if (
      !this.currentServiceZone.selectedServices?.some(
        (service: any) => service.enabledForZone
      )
    ) {
      this.toastr.error('Please select at least one service');
    } else {
      let updatedServiceZone = {};
      if (this.currentServiceZone.selectedState.state === 'NATIONWIDE') {
        updatedServiceZone = {
          nationwide: true,
          services: this.currentServiceZone.selectedServices,
        };
      } else {
        updatedServiceZone = {
          state: this.currentServiceZone.selectedState.state,
          counties: this.currentServiceZone.selectedCounties,
          services: this.currentServiceZone.selectedServices,
        };
      }
      this.userService
        .updateServiceZone(
          this.user,
          updatedServiceZone,
          this.currentServiceZone.state
        )
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
      this.editZoneToggle = !this.editZoneToggle;
      this.resetUpdateServiceZoneValues();
    }
  }

  removeServiceZone(serviceZone: any) {
    if (this.user.id) {
      let serviceZoneIdentifier;
      if (serviceZone.nationwide) {
        serviceZoneIdentifier = 'NATIONWIDE';
      } else {
        serviceZoneIdentifier = serviceZone.state;
      }
      this.userService
        .removeServiceZone(this.user.id, serviceZoneIdentifier)
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  cancelNewServiceZone() {
    this.toggleNewServiceZone = false;
    this.resetNewServiceZoneValues();
  }

  cancelUpdateServiceZone() {
    this.editZoneToggle = !this.editZoneToggle;
    this.resetUpdateServiceZoneValues();
  }

  uploadProfileDocument(event: any) {
    if (event.target.files) {
      this.toastr.info('Uploading profile documents...');
      this.documentService
        .uploadProfileDocuments(this.user, event.target.files)
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  uploadZoneDocument(serviceZone: any, event: any) {
    if (event.target.files) {
      this.toastr.info('Uploading service zone documents...');
      this.documentService
        .uploadServiceZoneDocuments(this.user, serviceZone, event.target.files)
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  deleteProfileDocument(documentId: string) {
    if (this.user.id) {
      this.documentService
        .deleteProfileDocument(this.user.id, documentId)
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  deleteZoneDocument(serviceZone: any, documentId: string) {
    if (this.user.id) {
      this.documentService
        .deleteZoneDocument(
          this.user.id,
          documentId,
          serviceZone.nationwide ? 'NATIONWIDE' : serviceZone.state
        )
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser) {
              this.user = { ...this.user, ...updatedUser };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  downloadDocument(document: any) {
    this.toastr.info('Downloading document...');
    this.documentService.downloadDocument(document);
  }

  uploadProfilePic(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.userService
        .uploadProfilePicture(this.user, event.target.files[0])
        .subscribe((response: any) => {
          const apiResponse = response;
          if (apiResponse.status === 'FAILURE') {
            if (apiResponse.message) {
              this.toastr.error(apiResponse.message);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          } else {
            const updatedUser = response.data;
            if (updatedUser && updatedUser.photo) {
              this.user = { ...this.user, photo: updatedUser.photo };
              localStorage.setItem('user', JSON.stringify(this.user));
              this.toastr.success(apiResponse.message);
              this.userService.updateUserSubject(this.user);
            } else {
              this.toastr.error('An error occurred. Please try again.');
            }
          }
        });
    }
  }

  private updateUser(user: User) {
    this.userService.updateUser(user).subscribe((response: any) => {
      const apiResponse = response;
      if (apiResponse.status === 'FAILURE') {
        if (apiResponse.message) {
          this.toastr.error(apiResponse.message);
        } else {
          this.toastr.error('An error occurred. Please try again.');
        }
      } else {
        const updatedUser = response.data;
        if (updatedUser) {
          this.user = { ...this.user, ...updatedUser };
          localStorage.setItem('user', JSON.stringify(this.user));
          this.toastr.success(apiResponse.message);
        } else {
          this.toastr.error('An error occurred. Please try again.');
        }
      }
    });
  }

  private resetNewServiceZoneValues() {
    this.selectedNewZoneState = this.serviceZoneStateCountyDetails[0];
    this.selectedNewCounties = [];
    this.newZoneServiceTypes = this.newZoneServiceTypes.map(
      (currentServiceType: any) => {
        return { ...currentServiceType, enabledForZone: false };
      }
    );
    this.allNewZoneServiceTypesSelected = false;
  }

  private resetUpdateServiceZoneValues() {
    this.currentServiceZone = {};
    this.allUpdateZoneServiceTypesSelected = false;
  }
}
