import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeclineSuccessComponent } from '../decline-success/decline-success.component';

@Component({
  selector: 'decline-dialog',
  standalone: true,
  imports: [CommonModule, DeclineSuccessComponent],
  templateUrl: './decline-dialog.component.html',
  styleUrl: './decline-dialog.component.scss',
})
export class DeclineDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';
  declineSuccessActive: boolean = false;
  reasonSelectedIndex = 0;
  isDisabled: boolean = true;
  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  toggleDeclineSuccessModal() {
    this.declineSuccessActive = !this.declineSuccessActive;
  }
  declineInvitation() {
    this.isDisabled = true;
    this.closeDialog();
    this.toggleDeclineSuccessModal();
  }
  selectReason(val: any) {
    this.reasonSelectedIndex = val.target.selectedIndex;
    if (this.reasonSelectedIndex > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
}
