<div class="documents-dialog" *ngIf="active">
	<div class="documents-dialog__content">
		<button class="documents-dialog__content__close" (click)="closeDialog()"><span
				class="material-icons">close</span>Close</button>
		<h2>Submit This Order For Review?</h2>
		<p>This action will send this project for review. Mauris in aliquam sem fringilla ut. Elit ut aliquam purus sit
			amet. Faucibus scelerisque eleifend donec pretium. Cras ornare arcu dui vivamus arcu felis bibendum.


			<br> <br>Do you wish to continue?
		</p>

		<br> <br>

		<div class="documents-dialog__content__footer">
			<div class="documents-dialog__content__footer__service-type">
				<div class="documents-dialog__content__footer__service-type__label">Service Type</div>
				<div class="service-type">ALTA/NSPS Land <br>Title Survey</div>
			</div>
			<div class="documents-dialog__content__footer__service-type">
				<div class="documents-dialog__content__footer__service-type__label">Location</div>
				<div class="location">3600 Prospect Street,<br>
					Hill Valley, CA 95420</div>
			</div>
			<div class="btnalign">
				<button class="documents-dialog__content__footer__submit pull-right" (click)="submit()">Submit for
					Review</button>
				<button class="defaultbtn" (click)="closeDialog()">Back</button>
			</div>
		</div>
	</div>
	<div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>

<projectreview-completed [active]="reviewCompletedActive" (modalClosed)="toggleReviewCompletedActiveModal()"
	[ngClass]="{'documents-dialog--active': reviewCompletedActive}"></projectreview-completed>

<review-order-submitted-dialog [active]="isReviewOrderSubmitted" [documents]="documents" [serviceType]="serviceType"
	[address]="address" (modalClosed)="toggleReviewOrderSubmittedModal()"
	[ngClass]="{'documents-dialog--active': isReviewOrderSubmitted}"></review-order-submitted-dialog>