import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ProposalService } from './proposal.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private tokenKey = 'token';

  constructor(private http: HttpClient) {}

  uploadPropsalDocuments(documents: any, proposal: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'proposal',
      new Blob([JSON.stringify(proposal)], {
        type: 'application/json',
      })
    );
    documents.forEach((docRequest: any) =>
      formData.append('files', docRequest.file)
    );
    return this.http.post<any>(
      environment.apiUrl + '/document/proposal',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  uploadProfileDocuments(user: any, files: FileList) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'user',
      new Blob([JSON.stringify(user)], {
        type: 'application/json',
      })
    );
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return this.http.post<any>(
      environment.apiUrl + '/document/profile',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  uploadServiceZoneDocuments(user: any, serviceZone: any, files: FileList) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const formData = new FormData();
    formData.set(
      'user',
      new Blob([JSON.stringify(user)], {
        type: 'application/json',
      })
    );
    formData.set(
      'serviceZone',
      new Blob([JSON.stringify(serviceZone)], {
        type: 'application/json',
      })
    );
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return this.http.post<any>(
      environment.apiUrl + '/document/zone',
      formData,
      {
        headers,
        responseType: 'json',
      }
    );
  }

  deleteProposalDocument(documentId: string) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    const params = { documentId: documentId };
    return this.http.delete<any>(environment.apiUrl + '/document/proposal', {
      headers,
      responseType: 'json',
      params: params,
    });
  }

  deleteProfileDocument(userId: string, documentId: string) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const params = { userId: userId, documentId: documentId };
    return this.http.delete<any>(environment.apiUrl + '/document/profile', {
      headers,
      responseType: 'json',
      params: params,
    });
  }

  deleteZoneDocument(
    userId: string,
    documentId: string,
    serviceZoneIdentifier: string
  ) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const params = {
      userId: userId,
      documentId: documentId,
      serviceZoneIdentifier: serviceZoneIdentifier,
    };
    return this.http.delete<any>(environment.apiUrl + '/document/zone', {
      headers,
      responseType: 'json',
      params: params,
    });
  }

  downloadDocument(downloadDocument: any) {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    if ((downloadDocument.type === 'ATP' || downloadDocument.type === 'SURVEY_CHECKLIST') && downloadDocument.documentPath) {
      window.open(downloadDocument.documentPath, '_blank');
      return;
    } else { 
      return this.http
        .get<any>(
          environment.apiUrl +
            `/document/download?documentId=${downloadDocument.id}`,
          {
            headers,
            responseType: 'blob' as 'json',
          }
        )
        .subscribe((blob) => {
          const data = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.download = downloadDocument.fileName;
          link.href = data;
          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(data);
          }, 100);
        });
    }
  }

  async downloadPdfToViewer(downloadDocument: any): Promise<{ url: string, filename: string }> {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    if ((downloadDocument.type === 'ATP' || downloadDocument.type === 'SURVEY_CHECKLIST') && downloadDocument.documentPath) {
      return { url: downloadDocument.documentPath, filename: downloadDocument.fileName };
    } else {
      try {
        const blob = await lastValueFrom(
          this.http.get(environment.apiUrl + `/document/download?documentId=${downloadDocument.id}`, {
            headers,
            responseType: 'blob' as 'json',
          })
        ) as Blob; 
        const data = window.URL.createObjectURL(blob);
         return { url: data, filename: downloadDocument.fileName };
      } catch (error) {
        throw error;
      }
    }
  }
}
