import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BidSubmittedDialogComponent } from '../bid-submitted-dialog/bid-submitted-dialog.component';
import { ProposalService } from '../services/proposal.service';
import { ProjectService } from '../services/project.service';
import { ModalService } from '../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../model/user';
@Component({
  selector: 'bid-dialog',
  standalone: true,
  imports: [CommonModule, BidSubmittedDialogComponent],
  templateUrl: './bid-dialog.component.html',
  styleUrl: './bid-dialog.component.scss',
})
export class BidDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = '';
  @Input() documents: any = '';
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  bidSubmittedActive: boolean = false;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(
    private proposalService: ProposalService,
    private projectService: ProjectService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.modalService.isSubmittedBidModalOpen.subscribe(
      (isOpen) => (this.bidSubmittedActive = isOpen)
    );
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  toggleOpenSubmittedBidModal() {
    this.modalService.toggleOpenSubmittedBidModal();
  }

  toggleCloseSubmittedBidModal() {
    this.modalService.toggleCloseSubmittedBidModal();
  }

  submit() {
    this.proposalService
      .submitBid(this.data, this.user.id)
      .subscribe((response: any) => {
        let apiResponse: any = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const data = apiResponse.data;
          if (data) {
            this.projectService.updateProjectDataSubjectWithSingleSite(data);
            this.toggleOpenSubmittedBidModal();
            this.closeDialog();
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
