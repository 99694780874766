import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-create-account-thanks',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './create-account-thanks.component.html',
  styleUrl: './create-account-thanks.component.scss',
})
export class CreateAccountThanksComponent {
  constructor(public router: Router) {}
  gotoLogin() {
    this.router.navigate(['login']);
  }
}
