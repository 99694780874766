<div class="documents-dialog" *ngIf="active">
	<div class="documents-dialog__content">
		<button class="documents-dialog__content__close" (click)="closeDialog()"><span
				class="material-icons">close</span>Close</button>
		<h2>Comments</h2>


		<textarea id="documents-dialog-file-notes"
			placeholder="Survey of Site, Setup of Equipment Required. Initial contact must be established"
			required></textarea>

		<div class="documents-dialog__content__footer">
			<div class="documents-dialog__content__footer__service-type">
				<p class="documents-dialog__content__footer__service-type__label">Service Type</p>
				<p class="service-type">ALTA/NSPS Land Title Survey</p>
			</div>
			<div class="documents-dialog__content__footer__service-type">
				<p class="documents-dialog__content__footer__service-type__label">Location</p>
				<p class="location">742 Evergreen Terrace,<br> Springfield, IL 62704</p>
			</div>
			<div class="btnalign">
				<button class="documents-dialog__content__footer__submit pull-right" (click)="submit()">Save &
					Close</button>
				<button class="defaultbtn" (click)="closeDialog()">Cancel</button>
			</div>
		</div>
	</div>
	<div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>