<div class="container">
    <div class="left-navigation">
        <div class="back-btn"><span class="material-icons">arrow_back_ios</span> <a (click)="goBack()"
                class="back-btn">Back</a>
        </div>
        <hr>
        <div class="my-account">My Account</div>
        <div class="left-nav" [routerLink]="'/manageaccount'"><span class="material-icons">person</span> <a class="left-links">My
                Profile</a>
        </div>
        <div class="left-nav" [routerLink]="'/settings'"><span class="material-icons">settings</span> <a class="left-links">Account
                Settings</a></div>
        <hr>
        <div class="left-nav" [routerLink]="'/contact'" routerLinkActive="router-link-active" ><span class="material-icons">info</span> <a class="left-links">Contact
                Us</a>
        </div>
        <div class="left-nav"><span class="material-icons">logout</span> <a (click)="logout()"
                class="left-links">Logout</a></div>
    </div>
    <div class="content-area">
    <div class="contact-us-title">
    We'd Like To Hear From You
  </div>
  <div class="contact-us-sub-title">
    Contact us with any questions you may have.
  </div>
  
  <div class="social-media-icons" style="align-items: center;">
    <a href="https://www.linkedin.com" target="_blank" class="social-icon linkedin">
        <span class="material-icons">linkedin</span>
    </a>
    <a href="https://www.facebook.com" target="_blank" class="social-icon facebook">
        <span class="material-icons">facebook</span>
    </a>
    <a href="https://twitter.com" target="_blank" class="social-icon twitter">
        <span class="material-icons">twitter</span>
    </a>
  </div>  
  
  <div class="split left">
    <div class="name-fields-container">
        <div class="field">
            <label class="form-label">First Name<span class="req">*</span></label>
            <input type="text" id="first-name" name="first-name">
        </div>
        <div class="field">
            <label for="last-name">Last name<span class="req">*</span></label>
            <input type="text" id="last-name" name="last-name" >
        </div>
    </div> 
    <div class="name-fields-container">
        <div class="field">
            <label for="Email">Email<span class="req">*</span></label>
            <input type="text" id="Email" name="Email">
        </div>
        <div class="field">
            <label for="phone-number">Phone Number<span class="req">*</span></label>
            <input type="text" id="phone-number" name="phone-number">
        </div>
    </div>
    <div class=".company-name-input">
        <label for="company-name">Company Name</label><br>
        <input type="text" id="company-name"  name="company-name">
    </div>
    <div class="field"> 
        <label for="reason">Please describe your reason for contacting</label>
        <textarea id="reason" name="reason" class="input-textarea" rows="10"></textarea>
    </div>
    <div class="button">Submit</div>
    <div class="footer">
      <p>By providing a telephone number and submitting this form, you consent to be contacted by SMS text message. 
        Very rarely does CREtelligent do that.<br>
        Message & data rates may apply. You can reply STOP to opt-out of further messaging.</p>
    </div>
  </div>
  
  <div class="split right">
    <div class="contact-info">
        <div class="location">
        <span class="material-icons">location_on</span>
        <p>11344 Coloma Road, Gold River, CA 95670.</p></div>
        <div class="call">
        <span class="material-icons">call</span>
        <p> (866) 348-1780</p>  </div>
        <div class="hours">
        <span class="material-icons">access_alarm</span>
        <p> Operation Hours</p> </div>
    </div>
  
    <div class="operation-hours"> 
        <table>
            <tr>
                <td>Monday</td>
                <td>6:00 AM - 5:00 PM / PST</td>
            </tr>
            <tr>
                <td>Tuesday</td>
                <td>6:00 AM - 5:00 PM / PST</td>
            </tr>
            <tr>
                <td>Wednesday</td>
                <td>6:00 AM - 5:00 PM / PST</td>
            </tr>
            <tr>
                <td>Thursday</td>
                <td>6:00 AM - 5:00 PM / PST</td>
            </tr>
            <tr>
                <td>Friday</td>
                <td>6:00 AM - 5:00 PM / PST</td>
            </tr>
        </table>
    </div>
  </div>
    </div>
</div>