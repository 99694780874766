import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoGroup } from '../info-group/info-group.component';
import { InfoNavigation } from '../info-navigation/info-navigation.component';

import * as tableData from '../../assets/generated.json';

@Component({
	standalone: true,
  selector: 'info-page',
	imports: [CommonModule, InfoGroup, InfoNavigation],
  templateUrl: './invitations-page.component.html',
	styleUrl: './invitations-page.component.scss',
})

export class InvitationsPage {
	data: any = tableData;

	filteredInvitations(){
		return this.data.groups.filter((group: any) => group.type === 'invitation');
	}
}
