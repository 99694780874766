import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignAtpDialogComponent } from '../sign-atp-dialog/sign-atp-dialog.component';
import { AllbidsDialogComponent } from '../allbids-dialog/allbids-dialog.component';
import { User } from '../model/user';
import { ProjectService } from '../services/project.service';
import { ProjectOverviewRowItemComponent } from '../project-overview-row-item/project-overview-row-item.component';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from '../services/site.service';
import { ProposalService } from '../services/proposal.service';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'project-overview',
  standalone: true,
  imports: [
    CommonModule,
    SignAtpDialogComponent,
    AllbidsDialogComponent,
    ProjectOverviewRowItemComponent
  ],
  templateUrl: './project-overview.component.html',
  styleUrl: './project-overview.component.scss',
})
export class ProjectOverviewComponent {
  @Input() type: string = '';
  @Input() expanded: boolean = true;
  @Input() index: number = 0;

  statusSort: boolean = false;
  siteSort: boolean = false;
  addressSort: boolean = false;
  acreageSort: boolean = false;
  landUseSort: boolean = false;
  siteTypeSort: boolean = false;
  dueDateSort: boolean = false;
  actionsSort: boolean = false;

  gridLayout: string =
    'var(--status) var(--site) var(--acreage) var(--landUse) var(--siteType) var(--dueDate) var(--actions)';
  moreOpen: boolean = false;
  showStatus: boolean = true;
  showSite: boolean = true;
  showAcreage: boolean = true;
  showLandUse: boolean = true;
  showsiteType: boolean = true;
  showDueDate: boolean = true;
  showServiceInfo: boolean = false;
  showActions: boolean = true;

  @Input() title: string = '';
  @Output() toggled = new EventEmitter<boolean>();
  openState: boolean = this.expanded;
  serviceInfoToggle: boolean = false;
  signAtpActive: boolean = false;
  allBidsActive: boolean = false;
  editToggle: boolean = false;

  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() serviceType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: string = '';
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';

  @Input() showServiceType: boolean = true;
  childModalHeaderTitle: string = 'ATP Signed & Proposal/S Accepted';
  childModalBody: string =
    "You have signed all ATP's and are available for view in the active projects.";
  tableAItemName: string = '';
  tableAItemValue: string = '';
  serviceTypeSort: boolean = false;
  data: any = null;

    isATPSignedAccepted: boolean = false;
    isDeclineSuccessActive: boolean = false;
    address: any = { streetAddress: '', cityStateZip: '' };
    siteType: string = '';
    @Input() atpDocumentPath: SafeResourceUrl = '';
    atpDocument: any;
    isDeclineActive: boolean = false;

  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(private projectService: ProjectService,
     private siteService: SiteService,
     private proposalService: ProposalService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.fetchData();
    this.openState = this.expanded;
    this.siteSort = this.filterToggle(this.siteSort, 'site');
    this.adjustTable();
  }

  toggleSubmitAllBidsModal() {
    this.allBidsActive = !this.allBidsActive;
  }
  toggleATPModal() {
    this.signAtpActive = !this.signAtpActive;
  }
  projectOverview() {
    alert('projectOverview');
  }

  accordion() {
    this.openState = !this.openState;
    this.toggled.emit(this.openState);
  }

  fetchData() {
    this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
      this.data = result
        .map((parent: any) => ({
          ...parent,
          sites: parent.sites.filter(
            (child: any) =>
              child.proposal.status === 'INVITATION' ||
              child.proposal.status === 'SUBMITTED' ||
              child.proposal.status === 'PROPOSAL' ||
              child.proposal.status === 'BID_LOST' ||
              child.proposal.status === 'BID_WON' ||
              child.proposal.status === 'ACTIVE'
          ),
        }))
        .filter((parent: any) => parent.sites.length > 0 && parent.projectId);
    });
  }

  filterToggle(input: boolean, sortBy: string = '') {
    if(this.data){
    this.data.sort((a: any, b: any) => {
      let res = 0;
      if (sortBy !== 'dueDate') {
        if (a[sortBy] < b[sortBy]) res = -1;
        if (a[sortBy] > b[sortBy]) res = 1;
      } else {
        let dateA = new Date(a[sortBy]);
        let dateB = new Date(b[sortBy]);
        if (dateA < dateB) res = -1;
        if (dateA > dateB) res = 1;
      }
      if (input) res = -res;
      return res;
    });}
    return !input;
  }

  more() {
    this.moreOpen = !this.moreOpen;
  }

  toggleColumn(input: boolean) {
    return !input;
  }

  adjustTable() {
    this.gridLayout = '';
    if (this.showStatus) this.gridLayout += 'var(--status) ';
    if (this.showSite) this.gridLayout += 'var(--site) ';
    if (this.showAcreage) this.gridLayout += 'var(--acreage) ';
    if (this.showLandUse) this.gridLayout += 'var(--land-use) ';
    if (this.showsiteType) this.gridLayout += 'var(--service-type) ';
    //if (this.showDueDate) this.gridLayout += 'var(--due-date) ';commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75
    //if (this.showServiceInfo) this.gridLayout += 'var(--service-info) ';
    this.gridLayout += 'var(--documents) ';
    if (this.showActions) this.gridLayout += 'var(--action) ';
    this.gridLayout += 'var(--edit) ';
    this.gridLayout += 'var(--view) ';
    this.gridLayout = this.gridLayout.trim();
  }
  goBack() {
    window.history.back();
  }

  signATP(event: { fullName: string; title: string }) {
    this.toastr.info('Accepting your signed ATP...');
    this.siteService
      .signAtp(
        {
          projectDetail: this.data[0],
          atpDocument: this.atpDocument,
          atpSignedBy: event.fullName,
          atpSignedByTitle: event.title,
        },
        this.user.id ?? ''
      )
      .subscribe((response:any) => {
        const updatedSingleSiteProjectDetail = response.data;
        this.projectService.updateProjectDataSubjectWithSingleSite(
          updatedSingleSiteProjectDetail
        );
        this.signAtpActive = false;
        this.toggleAtpAcceptedModal();
      });
  }

  declineATP(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    const siteDetail = this.data[0].sites[0];
    const updatedProposal = {
      ...siteDetail.proposal,
      declineReason: event.declineReason,
      declineOtherReason: event.declineOtherReason,
      declineComments: event.declineComments,
    };
    const updatedSiteDetail = { ...siteDetail, proposal: updatedProposal };
    this.siteService
      .cancel({ ...this.data[0], sites: [updatedSiteDetail] })
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProjectDetail = response.data;
          if (updatedProjectDetail) {
            this.projectService.updateProjectDataSubjectWithProjectDetail(
              updatedProjectDetail
            );
            this.toggleDeclineSucessModal();
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  toggleDeclineSucessModal() {
    this.isDeclineSuccessActive = !this.isDeclineSuccessActive;
  }

  toggleAtpAcceptedModal() {
    this.isATPSignedAccepted = !this.isATPSignedAccepted;
  }

  decline(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    const updatedProposal = {
      ...this.data[0].sites[0].proposal,
      declineReason: event.declineReason,
      declineOtherReason: event.declineOtherReason,
      declineComments: event.declineComments,
    };
    this.proposalService
      .declineInvitation(updatedProposal)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const proposal = apiResponse.data;
          if (proposal) {
            this.toggleDeclineSucessModal();
            const updatedSiteDetail = {
              ...this.data[0].sites[0],
              proposal: proposal,
            };
            const updatedProjectDetail = {
              ...this.data[0],
              sites: [updatedSiteDetail],
            };
            this.projectService.updateProjectDataSubjectWithSingleSite(
              updatedProjectDetail
            );
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
