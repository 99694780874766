import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../model/user';
import { CommonModule } from '@angular/common';
import { ModalService } from '../services/modal.service';
import { ProposalService } from '../services/proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'accept-offered-proposal-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accept-offered-proposal-dialog.component.html',
  styleUrl: './accept-offered-proposal-dialog.component.scss',
})
export class AcceptOfferedProposalDialogComponent {
  @Input() active: boolean = false;
  @Input() multiple: boolean = false;
  @Input() data: any = '';
  @Input() siteType: string = '';
  @Input() address: any = {
    streetAddress: '',
    cityStateZip: '',
  };
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() acceptSingleOffer = new EventEmitter<any>();

  numberOfAcceptedProposals: number = 0;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(
    private modalService: ModalService,
    private proposalService: ProposalService,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.numberOfAcceptedProposals = this.data?.sites?.filter(
      (site: { proposal: { status: string } }) =>
        site.proposal?.status === 'OFFERED'
    ).length;
  }

  toggleOpenAcceptedOfferedProposalDialog() {
    this.modalService.toggleOpenOfferAcceptedModal();
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  acceptOffer() {
    this.proposalService
      .acceptOffer(this.data, this.user.id)
      .subscribe((response: any) => {
        let apiResponse: any = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const data = apiResponse.data;
          if (data) {
            if (this.multiple) {
              this.projectService.updateProjectDataSubjectWithProjectDetail(
                data
              );
              this.closeDialog();
              this.toggleOpenAcceptedOfferedProposalDialog();
            } else {
              // emit to proposal detail page to open the confirmation dialog, this
              // prevents the same dialog from being open on the main dashboard
              this.projectService.updateProjectDataSubjectWithSingleSite(data);
              this.acceptSingleOffer.emit();
            }
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
