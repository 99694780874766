import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';
import { SubmitForReviewDialogComponent } from '../submit-for-review-dialog/submit-for-review-dialog.component';
import { CommentsComponent } from '../comments/comments.component';
import { BidSavedDialogComponent } from '../bid-saved-dialog/bid-saved-dialog.component';
import { MapmodalDialogComponent } from '../mapmodal-dialog/mapmodal-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';

@Component({
  selector: 'project-overview-row-item',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './project-overview-row-item.component.html',
  styleUrl: './project-overview-row-item.component.scss',
})
export class ProjectOverviewRowItemComponent {
  serviceInfoToggle: boolean = false;
  documentsActive: boolean = false;
  editToggle: boolean = false;
  @Input() rowIndex: number = 0;
  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  address: any = { streetAddress: '', cityStateZip: '' };
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() serviceType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: string = '';
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';
  @Input() type: string = '';

  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showServiceType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showServiceInfo: boolean = true;
  @Input() showActions: boolean = true;
  @Input() showReviewSubmission: boolean = false;

  isDeclineActive: boolean = false;
  submitForReviewActive: boolean = false;
  commentsActive: boolean = false;
  savedActive: boolean = false;
  mapActive: boolean = false;
  selectedSiteType: string = '';
  selectedSiteId: number = 0;

  @Input() data: any = '';
  @Input() siteType: string = '';
  @Input() showsiteType: boolean = true;
  tableAItemName: string = '';
  tableAItemValue: string = '';
  path: string = '';

  constructor(
    private toastr: ToastrService,
    private utilityService: CommonUtilityServiceService
  ) {
    this.path = window.location.pathname.replace('/', '');
  }

  ngOnInit() {
    this.address = this.utilityService.formatAddress(this.data);
    let response = this.utilityService.formatTableAandItem20(this.data);
    if (response) {
      this.item20 = response[0];
      this.tableA = response[1];
    }
    this.showReviewSubmission = this.documents.some(
      (document: { type: string }) => document.type === 'DELIVERABLE'
    );
  }

  addressInfo() {
    alert('addressInfo');
  }

  serviceInfo() {
    this.serviceInfoToggle = !this.serviceInfoToggle;
  }
  toggleCommentsModal() {
    this.commentsActive = !this.commentsActive;
  }
  toggleDocumentsModal() {
    this.documentsActive = !this.documentsActive;
  }
  toggleDeclineModal(id: any) {
    this.isDeclineActive = !this.isDeclineActive;
  }
  toggleSubmitForReviewModal() {
    this.submitForReviewActive = !this.submitForReviewActive;
  }
  declineProposal() {
    alert('declineProposal');
  }

  editProposal() {
    if (this.editToggle) {
      this.toggleSaveModal();
    }
    this.editToggle = !this.editToggle;
  }

  viewProposal() {
    alert('viewProposal');
  }

  submitProposal() {
    this.toggleSubmitForReviewModal();
  }

  saveTurnTime() {
    alert('saveTurnTime');
  }

  saveBidAmount() {
    alert('saveBidAmount');
  }
  toggleSaveModal() {
    this.savedActive = !this.savedActive;
  }

  toggleMapModal(siteType: string, siteId: number) {
    this.mapActive = !this.mapActive;
    this.selectedSiteType = siteType;
    this.selectedSiteId = siteId;
  }
}
