<div class="back-btn"><span class="material-icons">keyboard_arrow_left</span> <a routerLink="/login"
    class="back-btn">Return To
    Login</a></div>
<div class="login-container">

  <form [formGroup]="forgotPassword">

    <a routerLink="/invitations"><img class="login-logo" src="../../assets/img/logo.png" alt="logo"></a>

    <div class="title">Forgot password</div>
    <hr />
    <div class="sm-text">Please enter your email address, and we will email you a temporary password.</div>
    <input id="email" type="email" class="input" placeholder="Email" formControlName="email" maxlength="100"
      autocomplete="disabled" />
    <div
      *ngIf="forgotPassword.controls['email']?.invalid && (forgotPassword.controls['email'].dirty || forgotPassword.controls['email'].touched)">
      <div *ngIf="forgotPassword.controls['email'].errors?.['required']" class="error-message">
        Email is required.
      </div>
      <div
        *ngIf="forgotPassword.controls['email'].value!='' && !forgotPassword.controls['email'].value?.match(emailRegx)"
        class="error-message">
        Please enter a valid email.
      </div>
    </div>
    <hr />
    <div class="panel-fields">
      <button type="submit" class="btn btn-default w-25" (click)="cancel()">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="password()">Forgot Password</button>
    </div>
  </form>
</div>