<div class="container">
  <div class="left-navigation">
    <div class="back-btn">
      <span class="material-icons">arrow_back_ios</span>
      <a (click)="goBack()" class="back-btn">Back</a>
    </div>
    <hr />
    <div class="my-account">My Account</div>
    <div class="left-nav" [routerLink]="'/manageaccount'">
      <span class="material-icons">person</span>
      <a class="left-links">My Profile</a>
    </div>
    <div class="left-nav" [routerLink]="'/settings'">
      <span class="material-icons">settings</span>
      <a class="left-links">Account Settings</a>
    </div>
    <hr />
    <div
      class="left-nav"
      [routerLink]="'/contact'"
      routerLinkActive="router-link-active"
    >
      <span class="material-icons">info</span>
      <a class="left-links">Contact Us</a>
    </div>
    <div class="left-nav">
      <span class="material-icons">logout</span>
      <a (click)="logout()" class="left-links">Logout</a>
    </div>
  </div>
  <div class="content-area">
    <div class="setting-header">
      <h2><span class="material-icons">settings_suggest</span> SETTINGS</h2>
    </div>

    <div class="settings-container">
      <div class="top-blocks">
        <div class="block top-left">
          <h3>General Notifications</h3>
          <div class="line"></div>
          <p>Please select which Notifications you would like to receive.</p>
          <div class="block">
            <h4>My Projects</h4>
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                formControlName="selectAll"
              />
              <label> Select all</label>
            </div>
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                formControlName="taggedToFollowProjects"
              />
              <label> Tagged to Follow Projects</label>
            </div>
            <button type="button" class="btn btn-default">
              <span class="material-icons">save</span> Save
            </button>
          </div>
        </div>
        <div class="block top-right">
          <h3>Change password</h3>
          <div class="line"></div>
          <div class="name-fields-container">
            <div class="field">
              <label class="form-label" for="current-password"
                >Current Password</label
              >
              <input
                type="password"
                id="current-password"
                name="current-password"
              />
            </div>
            <div class="field">
              <label for="new-password">New Password</label>
              <input type="password" id="new-password" name="new-password" />
            </div>
            <div class="field">
              <label for="confirm-password">Confirm New Password</label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
              />
            </div>
          </div>
          <button type="button" class="btn btn-default">
            <span class="material-icons">save</span> Update
          </button>
        </div>
      </div>

      <div class="notification-block">
        <h3>Notification Settings</h3>
        <div class="line"></div>
        <p>Please select which Notifications you would like to receive.</p>

        <div class="bottom-blocks">
          <div class="block">
            <h4>Notify me thru...</h4>
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                formControlName="selectAll"
              />
              <label> Select all</label>
            </div>
            <div class="line"></div>
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                formControlName="textNotifications"
              />
              <label> Text Notifications</label>
            </div>
            <div class="checkbox">
              <input
                type="checkbox"
                class="checkbox-field"
                formControlName="emailNotifications"
              />
              <label> Email Notifications</label>
            </div>
            <button type="button" class="btn btn-default">
              <span class="material-icons">save</span> Save
            </button>
          </div>

          <div class="block bottom-left">
            <div class="block">
              <h4>Invitations Notifications</h4>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="allInvitationNotifications"
                />
                <label> All Notifications</label>
              </div>
              <div class="line"></div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="newRfpInvitations"
                />
                <label> New RFP invitations</label>
              </div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="emailNotifications"
                />
                <label> Email Notifications</label>
              </div>
              <button type="button" class="btn btn-default">
                <span class="material-icons">save</span> Save
              </button>
            </div>
            <div class="block inner-block">
              <h4>Proposal Notifications</h4>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="allProposalNotifications"
                />
                <label> All Notifications</label>
              </div>
              <div class="line"></div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="wonBids"
                />
                <label>Awarded</label>
              </div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="lostBids"
                />
                <label>Closed</label>
              </div>
              <button type="button" class="btn btn-default">
                <span class="material-icons">save</span> Save
              </button>
            </div>
            <div class="block inner-block">
              <h4>Active Projects</h4>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="allActiveNotifications"
                />
                <label> All Notifications</label>
              </div>
              <div class="line"></div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-field"
                  formControlName="completedReviews"
                />
                <label> Completed Reviews</label>
              </div>
              <button type="button" class="btn btn-default">
                <span class="material-icons">save</span> Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
