import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private timeoutDuration = environment.idleTimeout;
  private timeoutId: any;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private toastr: ToastrService
  ) {
    this.startListening();
  }

  private startListening() {
    this.resetTimer();
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
      window.addEventListener(event, () => this.resetTimer());
    });
  }

  private resetTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => this.onTimeout(), this.timeoutDuration);
    });
  }

  private onTimeout() {
    this.ngZone.run(() => {
      this.toastr.error('Your session has expired, please login again.');
      this.router.navigate(['/login']);
    });
  }

  public stopListening() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
      window.removeEventListener(event, () => this.resetTimer());
    });
  }
}
