import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoHeader } from '../info-header/info-header.component';
import { InfoTable } from '../info-table/info-table.component';
import { ProjectService } from '../services/project.service';

@Component({
  standalone: true,
  selector: 'info-group',
  imports: [CommonModule, InfoHeader, InfoTable],
  templateUrl: './info-group.component.html',
  styleUrl: './info-group.component.scss',
})
export class InfoGroup {
  @Input() data: any = '';
  @Input() type: string = '';
  @Input() expanded: boolean = false;
  @Input() index: number = 0;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    const expanded = localStorage.getItem(this.data.projectId);
    if (this.index === 0 || expanded) {
      this.expanded = true;
    }
  }

  changeOpenState(exapnded: any) {
    if (exapnded) {
      localStorage.setItem(this.data.projectId, 'expanded');
    } else {
      localStorage.removeItem(this.data.projectId);
    }
    this.expanded = exapnded;
  }

  handleSitesUpdate(event: { sites: any }) {
    this.data = { ...this.data, sites: event.sites };
    this.projectService.updateProjectDataSubject(this.data);
  }
}
