<info-navigation [type]="'proposals'" />
<div *ngIf="data?.length > 0; else placeholder">
  <ng-container *ngFor="let item of data; let i = index">
    <info-group [data]="item" [type]="'proposals'" [index]="i"></info-group>
  </ng-container>
  <atp-sign-accepted-dialog
    [active]="isATPSignedAccepted"
    [siteType]="siteType"
    [address]="address"
    (modalClosed)="toggleCloseAtpAcceptedModal()"
    [ngClass]="{ 'documents-dialog--active': isATPSignedAccepted }"
    [modalHeaderTitle]="atpModalHeaderTitle"
    [modalBody]="atpModalBody"
  ></atp-sign-accepted-dialog>
  <decline-success
    [active]="isDeclineSuccessActive"
    [address]="address"
    [siteType]="siteType"
    (modalClosed)="toggleCloseDeclineSuccessModal()"
    [ngClass]="{ 'documents-dialog--active': isDeclineSuccessActive }"
  ></decline-success>
</div>
<ng-template #placeholder>
  <div *ngIf="loading; else notLoading" class="placeholder">
    <p>Loading proposals...</p>
  </div>
  <ng-template #notLoading>
    <div class="placeholder">
      <p>You currently have no submitted proposals.</p>
    </div>
  </ng-template>
</ng-template>
