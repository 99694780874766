<div class="login-container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <a routerlink="/" href="/"
      ><img class="login-logo" src="../../assets/img/logo.png" alt="logo"
    /></a>
    <div class="form-control">
      <input
        type="email"
        class="input"
        formControlName="email"
        placeholder="Email"
        maxlength="50"
        ngModel
        required
        (keydown)="clearValidations()"
        autocomplete="disabled"
      />
      <div
        *ngIf="
          loginForm.controls['email']?.invalid &&
          (loginForm.controls['email'].dirty ||
            loginForm.controls['email'].touched)
        "
      >
        <div
          class="error-message"
          *ngIf="loginForm.controls['email'].errors?.['required']"
        >
          Email is required.
        </div>
      </div>
    </div>
    <div class="form-control">
      <input
        type="password"
        class="input"
        formControlName="password"
        placeholder="Password"
        maxlength="100"
        (keydown)="clearValidations()"
      />
      <div
        *ngIf="
          loginForm.controls['password']?.invalid &&
          (loginForm.controls['password'].dirty ||
            loginForm.controls['password'].touched)
        "
      >
        <div
          *ngIf="loginForm.controls['password'].errors?.['required']"
          class="error-message"
        >
          Password is required.
        </div>
      </div>
      <div class="error-message" *ngIf="!isValid">
        Email or password is incorrect.
      </div>
    </div>
    <div class="panel-fields">
      <div class="checkbox">
        <input
          type="checkbox"
          class="checkbox-field"
          formControlName="keepMeSigned"
        />
        <label>Keep me signed in</label>
      </div>
      <div>
        <a routerLink="/forgotpassword" class="login-links">
          Forgot Password?</a
        >
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Login</button>
    <hr />
    <div class="new-account">
      <a routerLink="/createaccount" class="new-account">
        Don’t have an account?
      </a>
    </div>
    <button type="button" class="btn btn-default" (click)="navigateRegister()">
      Register
    </button>
  </form>
</div>
