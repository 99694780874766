import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoGroup } from '../info-group/info-group.component';
import { InfoNavigation } from '../info-navigation/info-navigation.component';
import { User } from '../model/user';
import { ProjectService } from '../services/project.service';

@Component({
  standalone: true,
  selector: 'info-active',
  imports: [CommonModule, InfoGroup, InfoNavigation],
  templateUrl: './active-page.component.html',
  styleUrl: './active-page.component.scss',
})
export class ActivePage {
  data: any = null;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  loading: boolean = true;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.projectService.fetchData(this.user.id ?? '').subscribe((result) => {
      this.data = result
        .map((parent: any) => ({
          ...parent,
          sites: parent.sites.filter(
            (child: any) =>
              child.proposal.status === 'ACTIVE' ||
              child.proposal.status === 'REVIEW_PENDING' ||
              child.proposal.status === 'REVISION_REQUESTED' ||
              child.proposal.status === 'REVISION_PENDING'
          ),
        }))
        .filter((parent: any) => parent.sites.length > 0 && parent.projectId);
      this.loading = false;
    });
  }
}
