import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'signatp-dialog',
  standalone: true,
  imports: [CommonModule, DeclineDialogComponent, ReactiveFormsModule],
  templateUrl: './sign-atp-dialog.component.html',
  styleUrl: './sign-atp-dialog.component.scss',
  providers: [DatePipe],
})
export class SignAtpDialogComponent {
  @Input() active: boolean = false;
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() modalHeaderTitle: string = '';
  @Input() modalBody: string = '';
  @Input() atpDocumentPath: SafeResourceUrl = '';

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() signedAtpEvent = new EventEmitter<{
    fullName: string;
    title: string;
  }>();
  @Output() declineAtpEvent = new EventEmitter<any>();

  isDeclineActive: boolean = false;
  currentDate: Date = new Date();
  formattedDate: string = '';
  disableSubmitButton: boolean = false;

  protected signedAtpDetails = new FormGroup({
    fullName: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
  });

  constructor(private datePipe: DatePipe, private modalService: ModalService) {
    this.formattedDate =
      this.datePipe.transform(this.currentDate, 'MM/dd/yyyy') || '';
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  submit() {
    if (this.signedAtpDetails.invalid) {
      this.signedAtpDetails.markAllAsTouched();
    }
    if (this.signedAtpDetails.valid) {
      this.disableSubmitButton = true;
      this.signedAtpEvent.emit({
        fullName: this.signedAtpDetails.value.fullName ?? '',
        title: this.signedAtpDetails.value.title ?? '',
      });
    }
  }

  toggleDeclineModal() {
    this.active = false;
    this.isDeclineActive = !this.isDeclineActive;
  }

  declineAtp(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    this.toggleDeclineModal();
    this.declineAtpEvent.emit(event);
  }
}
