import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private tokenKey = 'token';

  constructor(private http: HttpClient) { }

  public getVendorNotifications(userId: string): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    this.http.get(
      environment.apiUrl + `/notification/user-notifications?userId=${userId}`,
      { headers, responseType: 'json' }
    ).pipe((data) => {
      return data
    }).subscribe();
  }

  public markNotificationRead(notificationList: any): any {
    const token = localStorage.getItem(this.tokenKey);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.put(
      environment.apiUrl + '/notification/mark-notification-read',
      JSON.stringify(notificationList),
      {
        headers,
        responseType: 'json',
      }
    );
  }
}
