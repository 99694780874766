<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()"><span
        class="material-icons">close</span>Close</button>
    <h2>Sign ATP</h2>
    <div class="documents-dialog-box">
      <img src="../../assets/img/CreTelligent-Logo.png" alt="CreTelligent-Logo" />
      <p class="signup-address">May 30, 2024 <br> <br>
        Staging Vendor 2 Companay <br>
        Staging Vendor 2 <br>
        123 Main Street <br>
        Boston, ME -048373</p>
    </div>
    <ng-container>
      <h3>e-Signature</h3>

      <div class=" ">
        <div class="signup-body mb-10">Typing your name as it exactly appears below signifies you are completing this
          form using an
          electronic signature. By electronically signing, you are certifying that you have read and understood
          Cretelligent’s Terms and Conditions shown above.</div>
        <div class="form-control mt-20">
          <label for="exampleFormControlInput1" class="form-label">Full Name <span
              class="req pull-right">*Required</span></label><br>
          <input type="email" class="emailinput" id="exampleFormControlInput1">
        </div>



        <div class="create-form">
          <div class="form-control">
            <label for="exampleFormControlInput1" class="form-label">Job Title <span
                class="req pull-right">*Required</span></label><br>
            <input type="text" class="fname" id="exampleFormControlInput1">
          </div>
          <div class="form-control" style="margin-left: 10px;">
            <label for="exampleFormControlInput1" class="form-label">Date of Signing <span class="req">*</span></label>
            <br>
            <input type="email" class="dateofsign" id="exampleFormControlInput1">
          </div>
        </div>

        <div class="panelfields">
          <button type="submit" class="btn btn-primary w-25" (click)="submit()">Agree & Sign
            Electronically</button>
          <button type="submit" style="width: 15%;" class="btn btn-default w-25" (click)="decline()">Decline</button>

        </div>

      </div>

    </ng-container>

  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>

<atp-sign-accepted-dialog [active]="isATPSignedAccepted" [documents]="documents" [serviceType]="serviceType"
  [address]="address" (modalClosed)="toggleATPAcceptedModal()"
  [ngClass]="{'documents-dialog--active': isATPSignedAccepted}" [modalHeaderTitle]="modalHeaderTitle"
  [modalBody]="modalBody"></atp-sign-accepted-dialog>

<decline-dialog [active]="isDeclineActive" [documents]="documents" [serviceType]="serviceType" [address]="address"
  (modalClosed)="toggleDeclineModal()" [ngClass]="{'documents-dialog--active': isDeclineActive}"></decline-dialog>