<div class="info-table-item">
  <ng-container *ngIf="showStatus">
    <ng-container>
      <p
        class="status --proposal"
        *ngIf="status == 'SUBMITTED' || status == 'PROPOSAL'"
      >
        Submitted
      </p>
      <p class="status --bid-won" *ngIf="status == 'BID_WON'">Awarded</p>
      <p class="status --bid-lost" *ngIf="status == 'BID_LOST'">Closed</p>
    </ng-container>
    <ng-container *ngIf="status == 'ACTIVE'">
      <p class="status --active">{{ status }}</p>
    </ng-container>
    <ng-container *ngIf="status == 'INVITATION'">
      <p class="status --invitation">{{ status }}</p>
    </ng-container>
    <ng-container *ngIf="status == 'COMPLETED'">
      <p class="status --completed">{{ status }}</p>
    </ng-container>
  </ng-container>
  <div class="address" *ngIf="showSite">
    <p class="address__site">#{{ site }}</p>
    <div class="address__address">
      <span
        class="material-icons address-pointer"
        (click)="toggleMapModal(type, id)"
        >info_outline</span
      >
      <div class="address-pointer" [routerLink]="['/', type, id]">
        <p>{{ address.streetAddress }}<br />{{ address.cityStateZip }}</p>
      </div>
    </div>
  </div>
  <p *ngIf="showAcreage" class="table-row-font acreage">{{ acreage }}</p>
  <p
    *ngIf="showLandUse"
    class="table-row-font"
    [ngClass]="{
      'landuse-project-history': path === 'projecthistory',
      landuse: path != 'projecthistory'
    }"
  >
    {{ landUse }}
  </p>
  <div class="address-pointer" [routerLink]="['/', type, id]">
    <div *ngIf="showsiteType" class="service-type">
      <span
        class="material-icons"
        (mouseenter)="serviceInfo()"
        (mouseleave)="serviceInfo()"
        >info_outline</span
      >
      <p>{{ siteType }}</p>
      <div class="service-type__info" [ngClass]="{ active: serviceInfoToggle }">
        <p class="service-type__info__label">Table A</p>
        <p class="service-type__info__content">{{ tableA }}</p>
        <p *ngIf="item20" class="service-type__info__label">Item 20</p>
        <p class="service-type__info__content">{{ item20 }}</p>
      </div>
    </div>
  </div>
  <!-- <p class="table-row-font" *ngIf="showDueDate">{{ dueDate|date:'MM/dd/yyyy hh:mm:ss a' }}</p>commented bases on Rainier comments https://escreenlogic.atlassian.net/browse/CON-75 -->
  <div class="docs-info">
    <div class="documents" (click)="toggleDocumentsModal()">
      <span class="material-icons">upload</span>
      <div *ngIf="documents?.length > 0" class="documents__number">
        {{ documents?.length }}
      </div>
    </div>
    <span class="documents-label">Documents</span>
  </div>
  <ng-container *ngIf="showActions">
    <div
      *ngIf="(status == 'PROPOSAL' || status == 'SUBMITTED') && !editToggle"
      [ngClass]="{
        'proposals-actions-width':
          status == 'PROPOSAL' || status == 'SUBMITTED',
        'proposals-actions': status != 'SUBMITTED'
      }"
    >
      <div class="turn-time">
        <p>Turn Time</p>
        <p>{{ turnTime }} Days</p>
      </div>
      <div class="bid-amount">
        <p>Bid Amount</p>
        <p>${{ bidAmount }}</p>
      </div>
    </div>
    <div
      *ngIf="
        type == 'invitations' ||
        ((status == 'PROPOSAL' || status == 'SUBMITTED') && editToggle)
      "
      class="info-table-actions-edit"
    >
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Turn Time</p>
        <div class="info-table-actions-edit__item__input">
          <input
            type="text"
            value="{{ turnTime }} Days"
            (keyup.enter)="saveTurnTime()"
          />
          <button (click)="saveTurnTime()">
            <span class="material-icons">edit</span>
          </button>
        </div>
      </div>
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Bid Amount</p>
        <div class="info-table-actions-edit__item__input">
          <input
            type="text"
            value="${{ bidAmount }}"
            (keyup.enter)="saveBidAmount()"
          />
          <button (click)="saveBidAmount()">
            <span class="material-icons">edit</span>
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="status == 'BID_WON' || status == 'BID_LOST'"
      class="proposals-actions"
    >
      <div class="turn-time">
        <p>Turn Time</p>
        <p>{{ turnTime }} Days</p>
      </div>
      <div class="bid-amount">
        <p>Bid Amount</p>
        <p>${{ bidAmount }}</p>
      </div>
      <div class="decline-icon" (click)="toggleDeclineModal(id)">
        <div class="status --decline">
          <span
            style="margin-bottom: -7px"
            class="material-icons"
            title="Decline"
            >close</span
          >
        </div>
        <span class="decline">Decline</span>
      </div>
    </div>
    <div *ngIf="status == 'INVITATION'" class="info-table-actions-edit">
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Turn Time</p>
        <div class="info-table-actions-edit__item__input">
          <div *ngIf="status == 'INVITATION'">
            <input
              id="invitationTurnTime{{ rowIndex }}"
              type="text"
              value="{{ turnTime }}"
              (keyup.enter)="saveTurnTime()"
            />
            <button (click)="saveTurnTime()">
              <span class="material-icons">edit</span>
            </button>
          </div>
        </div>
      </div>
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Bid Amount</p>
        <div class="info-table-actions-edit__item__input">
          <div *ngIf="status == 'INVITATION'">
            <input
              id="invitationBidAmount{{ rowIndex }}"
              type="text"
              placeholder="$"
              (keyup.enter)="saveBidAmount()"
              value="${{ bidAmount }}"
            />
            <button (click)="saveBidAmount()">
              <span class="material-icons">edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="status == 'ACTIVE'"
      class="info-table-review submit-review-align"
    >
      <button (click)="submitProposal()" [disabled]="!showReviewSubmission">
        Submit Review
      </button>
      <p class="pending-review-label" *ngIf="reviewStatus == 'pending'">
        Pending Review
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="status == 'PROPOSAL' || status == 'SUBMITTED'">
    <div
      class="info-table-button"
      (click)="editProposal()"
      *ngIf="status == 'PROPOSAL' || status == 'SUBMITTED'"
    >
      <span *ngIf="!editToggle" class="material-icons">edit</span>
      <p *ngIf="!editToggle">Edit</p>
      <span *ngIf="editToggle" class="material-icons">check</span>
      <p *ngIf="editToggle">Save</p>
    </div>
  </ng-container>
  <ng-container *ngIf="status == 'COMPLETED'">
    <p class="project-completed">Project Completed</p>
  </ng-container>
  <div
    class="info-table-button address-pointer"
    [routerLink]="['/', 'invitations', id]"
  >
    <span class="material-icons">visibility</span>
    <p>View</p>
  </div>
</div>
