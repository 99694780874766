import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[phoneMask]',
})
export class PhoneMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const input = this.el.nativeElement;
    let formatted = input.value.replace(/\D/g, '');

    if (formatted.length > 3 && formatted.length <= 6) {
      formatted = `(${formatted.slice(0, 3)}) ${formatted.slice(3)}`;
    } else if (formatted.length > 6) {
      formatted = `(${formatted.slice(0, 3)}) ${formatted.slice(
        3,
        6
      )}-${formatted.slice(6, 10)}`;
    }

    input.value = formatted;
  }
}
