import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoGroup } from '../info-group/info-group.component';
import { InfoNavigation } from '../info-navigation/info-navigation.component';

import * as tableData from '../../assets/generated.json';

@Component({
	standalone: true,
  selector: 'info-page',
	imports: [CommonModule, InfoGroup, InfoNavigation],
  templateUrl: './active-page.component.html',
	styleUrl: './active-page.component.scss',
})

export class ActivePage {
	data: any = tableData;

	filteredActive(){
		return this.data.groups.filter((group: any) => group.type === 'active');
	}
}
