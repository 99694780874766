import { Component, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InfoHeader } from '../info-header/info-header.component';
import { InfoTable } from '../info-table/info-table.component';

@Component({
	standalone: true,
  selector: 'info-group',
	imports: [RouterOutlet, CommonModule, InfoHeader, InfoTable],
  templateUrl: './info-group.component.html',
	styleUrl: './info-group.component.scss'
})
export class InfoGroup {
	@Input() data: any = '';
	@Input() type: string = '';
	@Input() expanded: boolean = false;
	@Input() index: number = 0;

	ngOnInit(){
		if(this.index == 0){
			this.expanded = true;
		}
	}

	changeOpenState(state: any){
		this.expanded = state;
	}
}
