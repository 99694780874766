<div class="info-header" [ngClass]="{ expanded: expanded }">
  <div class="info-header__left">
    <button (click)="accordion()" class="info-header__left__button">
      <span class="material-icons">keyboard_arrow_down</span>
    </button>
    <h2 (click)="accordion()">{{ data.groupName }}</h2>
  </div>
  <div class="info-header__right">
    <a class="info-header__right__overview" (click)="projectOverview()"
      >Project Overview</a
    >
    <button
      *ngIf="type == 'invitations'"
      class="info-header__right__atp"
      (click)="toggleSubmitAllBidsModal()"
    >
      <span class="material-icons">gavel</span>Submit All Bids
    </button>
    <button
      *ngIf="type == 'proposals'"
      class="info-header__right__atp"
      (click)="toggleATPModal()"
    >
      <span class="material-icons">login</span>Sign ATP
    </button>
  </div>
</div>
<signatp-dialog
  [active]="signAtpActive"
  [documents]="documents"
  [serviceType]="serviceType"
  [address]="address"
  (modalClosed)="toggleATPModal()"
  [ngClass]="{ 'documents-dialog--active': signAtpActive }"
  [modalHeaderTitle]="childModalHeaderTitle"
  [modalBody]="childModalBody"
></signatp-dialog>

<allbids-dialog
  [data]="data"
  [active]="allBidsActive"
  [documents]="documents"
  [serviceType]="serviceType"
  [address]="address"
  (modalClosed)="toggleSubmitAllBidsModal()"
  [ngClass]="{ 'documents-dialog--active': allBidsActive }"
></allbids-dialog>
