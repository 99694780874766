import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InfoHeader } from '../info-header/info-header.component';
import { InfoTable } from '../info-table/info-table.component';

@Component({
  standalone: true,
  selector: 'documents-dialog',
  imports: [RouterOutlet, CommonModule, InfoHeader, InfoTable],
  templateUrl: './documents-dialog.component.html',
  styleUrl: './documents-dialog.component.scss',
})
export class DocumentsDialog {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    this.closeDialog();
  }
}
