<div class="info-group">
  <info-header
    [data]="data"
    [expanded]="expanded"
    [type]="type"
    (toggled)="changeOpenState($event)"
  ></info-header>
  <info-table
    [data]="data.data"
    [type]="type"
    [expanded]="expanded"
  ></info-table>
</div>
