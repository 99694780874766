<info-navigation [type]="'invitations'" />
<div *ngIf="data?.length > 0; else placeholder">
  <ng-container *ngFor="let item of data; let i = index">
    <info-group [data]="item" [type]="'invitations'" [index]="i"></info-group>
  </ng-container>
  <allbids-submitted-dialog
    [active]="bidSubmittedActive"
    (modalClosed)="toggleCloseSubmittedAllBidsModal()"
    [ngClass]="{ 'documents-dialog--active': bidSubmittedActive }"
  ></allbids-submitted-dialog>
  <decline-success
    [active]="declineSuccessActive"
    [address]="address"
    [siteType]="siteType"
    (modalClosed)="toggleCloseDeclineSuccessModal()"
    [ngClass]="{ 'documents-dialog--active': declineSuccessActive }"
  ></decline-success>
</div>
<ng-template #placeholder>
  <div *ngIf="loading; else notLoading" class="placeholder">
    <p>Loading invitations...</p>
  </div>
  <ng-template #notLoading>
    <div class="placeholder">
      <p>You currently have no invitations.</p>
    </div>
  </ng-template>
</ng-template>
