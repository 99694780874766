import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProposalService } from '../services/proposal.service';
import { ProjectService } from '../services/project.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../services/modal.service';
import { User } from '../model/user';
@Component({
  selector: 'allbids-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './allbids-dialog.component.html',
  styleUrl: './allbids-dialog.component.scss',
})
export class AllbidsDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = '';
  @Input() documents: any = '';
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() numberOfBids: number = 0;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');

  constructor(
    private proposalService: ProposalService,
    private projectService: ProjectService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {}

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  toggleOpenSubmittedAllBidsModal() {
    this.modalService.toggleOpenSubmittedAllBidsModal();
  }

  submit() {
    this.proposalService
      .submitBid(this.data, this.user.id)
      .subscribe((response: any) => {
        let apiResponse: any = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const data = apiResponse.data;
          if (data) {
            this.projectService.updateProjectDataSubjectWithProjectDetail(data);
            this.toggleOpenSubmittedAllBidsModal();
            this.closeDialog();
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
