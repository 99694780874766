import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'decline-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './decline-dialog.component.html',
  styleUrl: './decline-dialog.component.scss',
})
export class DeclineDialogComponent {
  @Input() siteId = '';
  @Input() active: boolean = false;
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() isFixedPrice: boolean = false;
  @Input() isAtp: boolean = false;

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() decline = new EventEmitter<any>();

  title: string = 'Decline RFP Invitation';
  reasonSelectedIndex: number = 0;
  otherReason: string = '';
  comments: string = '';
  isDisabled: boolean = true;

  declineReasons = [
    { value: '0', label: 'Please select a reason' },
    { value: '1', label: 'The site is not within my service area' },
    { value: '2', label: 'This service is not within my scope' },
    { value: '3', label: 'I am currently unavailable' },
    {
      value: '4',
      label: 'The timeline to complete this project is too short',
    },
    { value: '5', label: 'Other' },
  ];

  ngOnInit() {
    if (this.isAtp) {
      this.title = 'Decline To Sign ATP';
    } else if (this.isFixedPrice) {
      this.title = 'Decline Offer';
    }
  }

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  declineInvitation() {
    this.isDisabled = true;
    this.closeDialog();
    this.decline.emit({
      declineReason: this.declineReasons[this.reasonSelectedIndex]?.label,
      declineOtherReason: this.otherReason,
      declineComments: this.comments,
    });
  }

  checkOtherReason() {
    if (this.otherReason.length > 0) {
      this.isDisabled = false;
    }
  }

  selectReason(val: any) {
    this.reasonSelectedIndex = val.target.selectedIndex;
    if (this.reasonSelectedIndex > 0 && this.reasonSelectedIndex < 5) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
}
