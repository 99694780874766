import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SignAtpDialogComponent } from '../sign-atp-dialog/sign-atp-dialog.component';
import { AllbidsDialogComponent } from '../allbids-dialog/allbids-dialog.component';

@Component({
  standalone: true,
  selector: 'info-header',
  imports: [
    RouterOutlet,
    CommonModule,
    RouterLink,
    CommonModule,
    SignAtpDialogComponent,
    AllbidsDialogComponent,
  ],
  templateUrl: './info-header.component.html',
  styleUrl: './info-header.component.scss',
})
export class InfoHeader {
  @Input() expanded: boolean = false;
  @Input() data: any = '';
  @Input() type: string = '';

  @Output() toggled = new EventEmitter<boolean>();
  openState: boolean = this.expanded;
  serviceInfoToggle: boolean = false;
  signAtpActive: boolean = false;
  allBidsActive: boolean = false;
  editToggle: boolean = false;

  @Input() id: number = 0;
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: string = '';
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() serviceType: string = '';
  @Input() tableA: string = '';
  @Input() item20: string = '';
  @Input() dueDate: string = '';
  @Input() documents: any = '';
  @Input() turnTime: string = '';
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';

  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showServiceType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showServiceInfo: boolean = true;
  @Input() showActions: boolean = true;
  childModalHeaderTitle: string = 'ATP Signed & Proposal/S Accepted';
  childModalBody: string =
    "You have signed all ATP's and are available for view in the active projects.";
  ngOnInit() {
    this.openState = this.expanded;
  }

  toggleSubmitAllBidsModal() {
    this.allBidsActive = !this.allBidsActive;
  }

  projectOverview() {
    alert('projectOverview');
  }

  accordion() {
    this.openState = !this.openState;
    this.toggled.emit(this.openState);
  }
  toggleATPModal() {
    this.signAtpActive = !this.signAtpActive;
  }
}
