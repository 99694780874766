<div class="container">
    <div class="leftnavigation">
        <div class="backbtn"><span class="material-icons">arrow_back_ios</span> <a href="invitations"
                class="backbtn">Back</a>
        </div>
        <hr>
        <div class="myact">My Account</div>
        <div class="left-nav"><span class="material-icons">person</span> <a href="#" class="leftlinks">My
                Profile</a>
        </div>
        <div class="left-nav"><span class="material-icons">settings</span> <a href="#" class="leftlinks">Account
                Settings</a></div>
        <hr>
        <div class="left-nav"><span class="material-icons">info</span> <a href="#" class="leftlinks">Contact
                Us</a>
        </div>
        <div class="left-nav"><span class="material-icons">logout</span> <a href="#" class="leftlinks">Logout</a></div>
    </div>
    <div class="content-area">
        <div class=" ">
            <div class="proposal-details__header">
                <div class="proposal-details__header__info mainheader">
                    <h1><span class="material-icons">person</span> STEAVE ROGERS</h1>
                    <span class="material-icons">person</span>
                </div>
                <div class="proposal-details__header__tabs">
                    <button (click)="toggleTab('profile')" [class.active]="activeTab=='profile'">Profile</button>
                    <button (click)="toggleTab('organization')"
                        [class.active]="activeTab=='organization'">Organization</button>
                    <button (click)="toggleTab('prefservicezones')" [class.active]="activeTab=='prefservicezones'">Pref
                        Service Zones</button>
                    <button (click)="toggleTab('serviceCapabilities')"
                        [class.active]="activeTab=='serviceCapabilities'">Service
                        Capabilities</button>
                    <button (click)="toggleTab('documents')" [class.active]="activeTab=='documents'">Documents</button>
                </div>
            </div>
            <div class=" ">
                <div class=" " *ngIf="activeTab=='profile'">
                    <div class="tabcontent">
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">Name</div>
                                            <div class="btndiv"><span
                                                    class="material-icons edit-detals">border_color</span>
                                                Edit</div>
                                        </div>
                                        <div class="user-details">
                                            <div class="left">
                                                <p class="small-txt">First Name</p>
                                                <p class="lbl-value">Steve</p>
                                            </div>
                                            <div class="right">
                                                <p class="small-txt">Last Name</p>
                                                <p class="lbl-value">Rogers</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="10%">&nbsp;</td>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">Username</div>
                                            <div class="btndiv"><span
                                                    class="material-icons edit-detals">border_color</span>
                                                Edit</div>
                                        </div>
                                        <div class="user-details">
                                            <div class="left">
                                                <p class="small-txt">Username</p>
                                                <p class="lbl-value">steve.rogers</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">Phone</div>
                                            <div class="btndiv"><span
                                                    class="material-icons edit-detals">border_color</span>
                                                Edit</div>
                                        </div>
                                        <div class="user-details">
                                            <div class="left">
                                                <p class="small-txt">Phone Number</p>
                                                <p class="lbl-value">(987)654-3210</p>
                                            </div>
                                            <div class="right">
                                                <p class="small-txt">Phone OS</p>
                                                <p class="lbl-value">iOS</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="10%">&nbsp;</td>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">Email</div>
                                            <div class="btndiv"><span
                                                    class="material-icons edit-detals">border_color</span>
                                                Edit</div>
                                        </div>
                                        <div class="user-details">
                                            <div class="left">
                                                <p class="small-txt">Login Email</p>
                                                <p class="lbl-value">s.rogers-testorganization.com</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">Address</div>
                                            <div class="btn-save"><span class="material-icons">save</span>
                                                Save</div>
                                        </div>
                                        <br>
                                        <div class="create-form">
                                            <div class="form-control">
                                                <label for="exampleFormControlInput1" class="form-label">Address 1 <span
                                                        class="req">*</span></label>
                                                <br>
                                                <select name="cars" id="cars" class="form-select">
                                                    <option value="volvo">Volvo</option>
                                                    <option value="saab">Saab</option>
                                                    <option value="mercedes">Mercedes</option>
                                                    <option value="audi">Audi</option>
                                                </select>
                                            </div>
                                            <div class="form-control" style="margin-left: 10px;">
                                                <label for="exampleFormControlInput1" class="form-label">Address 2 <span
                                                        class="req">*</span></label>
                                                <br>
                                                <select name="cars" id="cars" class="form-select">
                                                    <option value="volvo">Volvo</option>
                                                    <option value="saab">Saab</option>
                                                    <option value="mercedes">Mercedes</option>
                                                    <option value="audi">Audi</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="create-form">
                                            <div class="form-control">
                                                <label for="exampleFormControlInput1" class="form-label">City <span
                                                        class="req">*</span></label>
                                                <br>
                                                <select name="cars" id="cars" class="form-select">
                                                    <option value="volvo">Volvo</option>
                                                    <option value="saab">Saab</option>
                                                    <option value="mercedes">Mercedes</option>
                                                    <option value="audi">Audi</option>
                                                </select>
                                            </div>
                                            <div class="form-control" style="margin-left: 10px;">
                                                <label for="exampleFormControlInput1" class="form-label">State/Province
                                                    <span class="req">*</span></label>
                                                <br>
                                                <select name="cars" id="cars" class="form-select">
                                                    <option value="volvo">Volvo</option>
                                                    <option value="saab">Saab</option>
                                                    <option value="mercedes">Mercedes</option>
                                                    <option value="audi">Audi</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="create-form">
                                            <div class="form-control">
                                                <label for="exampleFormControlInput1" class="form-label">Zip Code <span
                                                        class="req">*</span></label>
                                                <br>
                                                <select name="cars" id="cars" class="form-select">
                                                    <option value="volvo">Volvo</option>
                                                    <option value="saab">Saab</option>
                                                    <option value="mercedes">Mercedes</option>
                                                    <option value="audi">Audi</option>
                                                </select>
                                            </div>
                                        </div>
                                </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <div class="tabcard">
                                            <div class="tab-header">CC Info</div>
                                            <div class="btndiv"><span
                                                    class="material-icons edit-detals">border_color</span>
                                                Edit</div>
                                        </div>
                                        <div class="user-details">
                                            <div class="left">
                                                <p class="small-txt">CC Name 1</p>
                                            </div>
                                            <div class="right">
                                                <p class="small-txt">CC Name 2</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class=" " *ngIf="activeTab=='organization'">
                <div class="tabcontent">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="100%">
                                    <div class="tabcard">
                                        <div class="tab-header">organization</div>
                                        <div class="btndiv"><span class="material-icons edit-detals">border_color</span>
                                            Edit
                                        </div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <p class="small-txt">Organization Name</p>
                                            <p class="lbl-value">TestOrganization LLC.</p>
                                        </div>

                                    </div>
                                </td>


                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Phone</div>
                                        <div class="btndiv"><span class="material-icons edit-detals">border_color</span>
                                            Edit
                                        </div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <p class="small-txt">Phone Number</p>
                                            <p class="lbl-value">(987)654-3210</p>
                                        </div>
                                        <div class="right">
                                            <p class="small-txt">Phone OS</p>
                                            <p class="lbl-value">iOS</p>
                                        </div>
                                    </div>
                                </td>
                                <td width="10%">&nbsp;</td>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Email</div>
                                        <div class="btndiv"><span class="material-icons edit-detals">border_color</span>
                                            Edit
                                        </div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <p class="small-txt">Login Email</p>
                                            <p class="lbl-value">s.rogers-testorganization.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Address</div>
                                        <div class="btndiv"><span
                                                class="material-icons edit-detals">border_color</span>Edit
                                        </div>
                                    </div>

                                    <div class="user-details">
                                        <div class="left">
                                            <p class="small-txt">Address 1</p>
                                            <p class="lbl-value">212 Judah St.
                                                Roseville, California
                                                95678</p>
                                        </div>

                                    </div>



                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Additional Information</div>
                                        <div class="btndiv"><span class="material-icons edit-detals">border_color</span>
                                            Edit
                                        </div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <p class="small-txt">Website</p>
                                            <p class="lbl-value">https://testorganizations.com</p>
                                        </div>
                                        <div class="right">
                                            <p class="small-txt">Description</p>
                                            <p class="lbl-value">Test Account Only</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class=" " *ngIf="activeTab=='prefservicezones'">
                <div class="tabcontent">
                    <p class="psz-text">Please show the areas that you are willing to perform services by entering
                        below. You may enter zip codes with radii around those zip codes. You may also enter entire
                        states.<br> Note: Services that are not location specific (i.e. Report Reviews, Report Writing)
                        are not affected by the service zones listed below.
                    </p>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%" valign="top">
                                    <div class="tabcard">
                                        <div class="tab-header">Zip Code/Zones</div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <div class="create-form bdr-btm">
                                                <div class="form-control">
                                                    <label for="exampleFormControlInput1" class="form-label">Zone Name
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">Zone 1</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px;">
                                                    <label for="exampleFormControlInput1" class="form-label">Zip Code
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">95678</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px; width:130px">
                                                    <label for="exampleFormControlInput1" class="form-label">Service
                                                        Range(Miles) <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">200</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>

                                                </div>
                                                <div class="close-btn"><span class="material-icons">close</span>
                                                </div>

                                            </div>

                                            <div class="create-form mt-15 bdr-btm">
                                                <div class="form-control ">
                                                    <label for="exampleFormControlInput1" class="form-label">Zone Name
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">Zone 1</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px;">
                                                    <label for="exampleFormControlInput1" class="form-label">Zip Code
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">95678</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px; width:170px">
                                                    <label for="exampleFormControlInput1" class="form-label">Service
                                                        Range Radius(Miles) <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-psz">
                                                        <option value="volvo">200</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>

                                                </div>
                                                <div class="close-btn ml-40"><span class="material-icons">close</span>
                                                </div>

                                            </div>
                                            <div class="add-row">
                                                <span class="material-icons">add</span>
                                                <p class="add-row-text">Add Row</p>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td width="10%">&nbsp;</td>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Country/State</div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <div class="create-form bdr-btm">
                                                <div class="form-control">
                                                    <label for="exampleFormControlInput1" class="form-label">Country
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">Zone 1</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px;">
                                                    <label for="exampleFormControlInput1" class="form-label">State <span
                                                            class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">95678</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>

                                                <div class="close-btn ml-20"><span class="material-icons">close</span>
                                                </div>

                                            </div>

                                            <div class="create-form mt-15 bdr-btm">
                                                <div class="form-control">
                                                    <label for="exampleFormControlInput1" class="form-label">Country
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">Zone 1</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px;">
                                                    <label for="exampleFormControlInput1" class="form-label">State <span
                                                            class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">95678</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>

                                                <div class="close-btn ml-20"><span class="material-icons">close</span>
                                                </div>

                                            </div>

                                            <div class="create-form mt-15 bdr-btm">
                                                <div class="form-control">
                                                    <label for="exampleFormControlInput1" class="form-label">Country
                                                        <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">Zone 1</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>
                                                <div class="form-control" style="margin-left: 10px;">
                                                    <label for="exampleFormControlInput1" class="form-label">State <span
                                                            class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-cs">
                                                        <option value="volvo">95678</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>

                                                <div class="close-btn ml-20"><span class="material-icons">close</span>
                                                </div>

                                            </div>


                                            <div class="add-row">
                                                <span class="material-icons">add</span>
                                                <p class="add-row-text">Add Row</p>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class=" " *ngIf="activeTab=='serviceCapabilities'">
                <div class="tabcontent">

                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%" valign="top">
                                    <div class="tabcard">
                                        <div class="tab-header">Insurance</div>
                                    </div>
                                    <div class="insurance-details">

                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="80%">
                                                        <div class="txt">Do you have Errors and Omissions Insurance Y/N?
                                                        </div>
                                                    </td>
                                                    <td width="20%"><input type="radio" name="address" class=""
                                                            value="yes"><span class="txt">yes</span><input type="radio"
                                                            name="address" value="No"><span class="txt">No</span></td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                </td>
                                <td width="10%">&nbsp;</td>
                                <td width="40%">
                                    <div class="tabcard">
                                        <div class="tab-header">Profile Visibility</div>
                                    </div>
                                    <div class="user-details">
                                        <div class="left">
                                            <div class="create-form">
                                                <div class="form-control">
                                                    <label for="exampleFormControlInput1" class="form-label">Appear
                                                        online/offline? <span class="req">*</span></label>
                                                    <br>
                                                    <select name="cars" id="cars" class="form-select-pv">
                                                        <option value="volvo">preferred clients only</option>
                                                        <option value="saab">Saab</option>
                                                        <option value="mercedes">Mercedes</option>
                                                        <option value="audi">Audi</option>
                                                    </select>
                                                </div>

                                            </div>






                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="100%" valign="top">
                                    <div class="tabcard">
                                        <div class="tab-header">Capabilities</div>
                                    </div>
                                    <div class="insurance-details">
                                        <div class="txt">Please select your service capabilities below:</div>


                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class=" " *ngIf="activeTab=='documents'">
                <div class="tabcontent">

                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td width="40%" valign="top">
                                    <div class="tabcard">
                                        <div class="tab-header">Profile Documents</div>
                                    </div>
                                    <p class="psz-text">Profile Documents (Please upload your resume and any other
                                        documents that will help Clients understand your capabilities.)</p>
                                    <div class="documents-dialog__content__form">
                                        <div class="documents-dialog__content__form__upload">
                                            <label for="documents-dialog-file-upload" class="btn"><span
                                                    class="material-icons">upload</span>Browse File</label>
                                            <input id="documents-dialog-file-upload" type="file" required />
                                        </div>

                                    </div>
                                    <div class="blue-card">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="100%">
                                                        <div class="tabcard1">
                                                            <div class="tab-header">CrR_TestFile_1.csv</div>
                                                            <div class="btn-delete"><span class="material-icons"
                                                                    title="Delete">delete</span>
                                                            </div>
                                                        </div>
                                                        <div class="user-details">
                                                            <div class="btndiv"> Download </div>
                                                        </div>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="blue-card">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="100%">
                                                        <div class="tabcard1">
                                                            <div class="tab-header">BirdsEyeViewProperty2X9112.tiff
                                                            </div>
                                                            <div class="btn-delete"><span class="material-icons"
                                                                    title="Delete">delete</span>
                                                            </div>
                                                        </div>
                                                        <div class="user-details">
                                                            <div class="btndiv"> Download </div>
                                                        </div>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="blue-card">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="100%">
                                                        <div class="tabcard1">
                                                            <div class="tab-header">Test_document.pdf</div>
                                                            <div class="btn-delete"><span class="material-icons"
                                                                    title="Delete">delete</span>
                                                            </div>
                                                        </div>
                                                        <div class="user-details">
                                                            <div class="btndiv"> Download </div>
                                                        </div>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td width="10%">&nbsp;</td>
                                <td width="40%" valign="top">
                                    <div class="tabcard">
                                        <div class="tab-header">Licenses & Insurance</div>
                                    </div>
                                    <p class="psz-text">Licenses and Insurance (Please upload proof of any licenses and
                                        insurance along with their expiration dates.)</p>
                                    <div class="documents-dialog__content__form">
                                        <div class="documents-dialog__content__form__upload">
                                            <label for="documents-dialog-file-upload" class="btn"><span
                                                    class="material-icons">upload</span>Browse File</label>
                                            <input id="documents-dialog-file-upload" type="file" required />
                                        </div>

                                    </div>
                                    <div class="blue-card">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="100%">
                                                        <div class="tabcard1">
                                                            <div class="tab-header">Contractor_License.pdf</div>
                                                            <div class="btn-delete"><span class="material-icons"
                                                                    title="Delete">delete</span>
                                                            </div>
                                                        </div>
                                                        <div class="user-details">
                                                            <div class="btndiv"> Download </div>
                                                        </div>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="blue-card">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <td width="100%">
                                                        <div class="tabcard1">
                                                            <div class="tab-header">Shield_InsuranceCoverage.pdf</div>
                                                            <div class="btn-delete"><span class="material-icons"
                                                                    title="Delete">delete</span>
                                                            </div>
                                                        </div>
                                                        <div class="user-details">
                                                            <div class="btndiv"> Download </div>
                                                        </div>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>