<div class="info-table-item">
  <ng-container *ngIf="showStatus">
    <ng-container *ngIf="type === 'proposals'">
      <p
        class="status --proposal"
        *ngIf="status === 'SUBMITTED' || status === 'PROPOSAL'"
      >
        Submitted
      </p>
      <p class="status --bid-won" *ngIf="status === 'BID_WON'">Awarded</p>
      <p class="status --bid-lost" *ngIf="status === 'BID_LOST'">Closed</p>
    </ng-container>
    <ng-container *ngIf="type === 'active'">
      <p class="status --active">Active</p>
    </ng-container>
    <ng-container *ngIf="type === 'invitations'">
      <p class="status --invitation" *ngIf="status === 'INVITATION'">
        Invitation
      </p>
      <p class="status --offered" *ngIf="status === 'OFFERED'">Offered</p>
    </ng-container>
  </ng-container>
  <div class="address" *ngIf="showSite">
    <p class="address__site">#{{ site }}</p>
    <div class="address__address">
      <span
        class="material-icons address-pointer"
        (click)="toggleMapModal(type, id)"
        >info_outline</span
      >
      <div class="address-pointer" [routerLink]="['/', type, id]">
        <p>
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>
    </div>
  </div>
  <p *ngIf="showAcreage" class="table-row-font">{{ acreage }}</p>
  <div
    *ngIf="showSiteType"
    class="address-pointer"
    [routerLink]="['/', type, id]"
  >
    <div class="service-type">
      <p>{{ siteType }}</p>
    </div>
  </div>
  <p *ngIf="showLandUse" class="table-row-font">
    {{ landUse }}
  </p>

  <div *ngIf="showTableA">
    <div *ngIf="isAlta; else noTableA" class="service-info">
      <span
        *ngIf="item20"
        class="material-icons"
        (mouseenter)="serviceInfoToggle = true"
        (mouseleave)="serviceInfoToggle = false"
        >info_outline</span
      >
      <div class="service-details">
        <p class="table-row-font">
          {{ tableA }}
        </p>
      </div>

      <div class="service-info__info" [ngClass]="{ active: serviceInfoToggle }">
        <p class="service-info__info__label">Table</p>

        <p class="service-details__content">
          {{ tableA }}
        </p>
        <div *ngIf="item20">
          <p class="service-info__info__label">Item 20</p>
          <p class="service-info__info__content">
            {{ item20 ? item20 : "N/A" }}
          </p>
        </div>
      </div>
    </div>

    <ng-template #noTableA>
      <div class="service-details">
        <p class="table-row-font">N/A</p>
      </div>
    </ng-template>
  </div>

  <div *ngIf="data.proposal.status === 'BID_LOST'; else notBidLost">
    <div class="info-table-item__docs-info">
      <span class="info-table-item__icon-text">
        <div class="documents-disabled">
          <span class="material-icons">upload</span>
          <div *ngIf="documents?.length > 0" class="documents__number">
            {{ documents?.length }}
          </div>
        </div>
        <span class="documents-label-disabled">Documents</span>
      </span>
      <span class="info-table-item__icon-text">
        <div class="documents-disabled">
          <span class="material-icons">notes</span>
        </div>
        <span class="documents-label-disabled">Notes</span>
      </span>
    </div>
  </div>
  <ng-template #notBidLost>
    <div class="info-table-item__docs-info">
      <span class="info-table-item__icon-text">
        <div class="documents" (click)="toggleOpenDocumentsModal()">
          <span class="material-icons">upload</span>
          <div *ngIf="documents?.length > 0" class="documents__number">
            {{ documents?.length }}
          </div>
        </div>
        <span class="documents-label">Documents</span>
      </span>
      <span class="info-table-item__icon-text">
        <div class="documents" (click)="toggleNotesModal()">
          <span class="material-icons">notes</span>
        </div>
        <span class="documents-label">Notes</span>
      </span>
    </div>
  </ng-template>

  <ng-container *ngIf="showActions">
    <div
      *ngIf="
        (type === 'invitations' && status === 'OFFERED') ||
        (type === 'proposals' && !editToggle)
      "
      class="proposals-actions"
    >
      <div class="turn-time">
        <p>
          <span *ngIf="isFixedPriced; else tat">Delivery Date</span>
          <ng-template #tat>Turn Time</ng-template>
        </p>
        <span *ngIf="isFixedPriced; else tt"
          ><p>
            {{
              data.proposal.proposedDeliverDate | date : "MM/dd/yyyy" : locale
            }}
          </p></span
        >
        <ng-template #tt
          ><p>{{ turnTime }} Days</p></ng-template
        >
      </div>
      <div class="bid-amount">
        <p>Bid Amount</p>
        <p>${{ bidAmount }}</p>
      </div>
    </div>
    <div
      *ngIf="
        (type === 'invitations' && status !== 'OFFERED') ||
        (type === 'proposals' && editToggle)
      "
      class="info-table-actions-edit"
    >
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Turn Time</p>
        <div class="info-table-actions-edit__item__input">
          <div class="input-container">
            <input
              id="invitationTurnTime{{ rowIndex }}"
              type="number"
              placeholder="Days"
              [(ngModel)]="turnTime"
              (blur)="saveTurnTime()"
            />
          </div>
        </div>
      </div>
      <div class="info-table-actions-edit__item">
        <p class="info-table-actions-edit__item__label">Bid Amount</p>
        <div class="info-table-actions-edit__item__input">
          <div *ngIf="type === 'invitations'">
            <input
              id="invitationBidAmount{{ rowIndex }}"
              type="number"
              placeholder="$"
              value=""
              [(ngModel)]="bidAmount"
              (blur)="saveBidAmount()"
            />
          </div>
          <div *ngIf="type === 'proposals'">
            <input
              id="proposalBidAmount{{ rowIndex }}"
              type="number"
              value="{{ bidAmount }}"
              [(ngModel)]="bidAmount"
              (blur)="saveBidAmount()"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="type === 'active'"
      class="info-table-review submit-review-align"
    >
      <button
        class="submit-review-revision-button"
        [disabled]="!showReviewSubmission"
        *ngIf="data.proposal.status === 'ACTIVE'"
        [ngStyle]="{
          background: showReviewSubmission ? 'var(--text)' : '#cfcfcf',
          color: showReviewSubmission ? '#ffffff' : 'var(--text)'
        }"
        (click)="submitProposal()"
      >
        {{
          !showReviewSubmission
            ? "Deliverable Document Required"
            : "Submit Review"
        }}
      </button>
      <button
        class="submit-review-revision-button"
        *ngIf="data.proposal.status === 'REVISION_REQUESTED'"
        [disabled]="!showRevisionSubmission"
        [ngStyle]="{
          background: showRevisionSubmission ? 'var(--text)' : '#cfcfcf',
          color: showRevisionSubmission ? '#ffffff' : 'var(--text)'
        }"
        (click)="submitProposal()"
      >
        {{
          !showRevisionSubmission
            ? "Revision Document Required"
            : "Submit Revision"
        }}
      </button>
      <p
        class="pending-review-label"
        *ngIf="data.proposal.status === 'REVIEW_PENDING'"
      >
        Review Pending
      </p>
      <p
        class="pending-review-label"
        *ngIf="data.proposal.status === 'REVISION_PENDING'"
      >
        Revision Pending
      </p>
      <div class="proposals-actions">
        <span class="active-delivery-date">
          Delivery Date:
          {{ data.proposal.proposedDeliverDate | date : "MM/dd/yyyy" : locale }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'proposals'">
    <div
      class="info-table-button"
      (click)="editProposal()"
      *ngIf="status === 'SUBMITTED' && !isFixedPriced"
    >
      <div *ngIf="!editToggle">
        <span class="material-icons">edit</span>
        <p>Edit</p>
      </div>
      <div *ngIf="editToggle">
        <span class="material-icons">check</span>
        <p>Save</p>
      </div>
    </div>
    <div *ngIf="status === 'lost-bid' || status === 'bid-won'"></div>
  </ng-container>
  <div
    *ngIf="
      type === 'invitations' ||
      (type === 'proposals' && status === 'SUBMITTED' && isFixedPriced)
    "
    class="info-table-button"
    (click)="toggleDeclineModal()"
  >
    <span class="material-icons decline-icon">close</span>
    <p>Decline</p>
  </div>
  <div class="info-table-button address-pointer" [routerLink]="['/', type, id]">
    <span class="material-icons">visibility</span>
    <p>View</p>
  </div>

  <mapmodal-dialog
    [active]="mapActive"
    [siteType]="selectedSiteType"
    [siteId]="selectedSiteId"
    [data]="data"
    [address]="address"
    (modalClosed)="toggleMapModal(type, id)"
    [ngClass]="{ 'mapmodal-dialog--active': mapActive }"
  ></mapmodal-dialog>
</div>

<decline-dialog
  [siteId]="data.site.id"
  [active]="isDeclineActive"
  [siteType]="siteType"
  [address]="address"
  [isFixedPrice]="isFixedPriced"
  (decline)="declineProposal($event)"
  (modalClosed)="toggleDeclineModal()"
  [ngClass]="{ 'documents-dialog--active': isDeclineActive }"
></decline-dialog>

<submit-for-review-dialog
  [active]="submitForReviewActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  [status]="data.proposal.status"
  [siteId]="data.site.id"
  (modalClosed)="toggleSubmitForReviewModal()"
  (submitReview)="submitReview()"
  (submitRevision)="submitRevision()"
  [ngClass]="{ 'documents-dialog--active': submitForReviewActive }"
></submit-for-review-dialog>

<documents-dialog
  [data]="data"
  [active]="documentsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleCloseDocumentsModal()"
  (update)="updateDocuments($event)"
  [ngClass]="{ 'documents-dialog--active': documentsActive }"
></documents-dialog>

<notes-dialog
  [active]="notesActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleNotesModal()"
  [ngClass]="{ 'documents-dialog--active': notesActive }"
  [bidNotes]="bidNotes"
  [data]="data"
  (saveBidNotes)="saveBidNote($event)"
></notes-dialog>

<comments-dialog
  [active]="commentsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleCommentsModal()"
  [ngClass]="{ 'documents-dialog--active': commentsActive }"
></comments-dialog>

<bid-saved-dialog
  [active]="savedActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleSaveModal()"
  [ngClass]="{ 'documents-dialog--active': savedActive }"
></bid-saved-dialog>
