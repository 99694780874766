<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close</button>
    <br>
    <table width="100%">
      <tbody>
        <tr>
          <td>
            <div class="tab-card">
              <div class="tab-header">742 Evergreen Terrace, Springfield, IL 62704</div>
              <div class="btn-view-details" [routerLink]="['/', siteType, siteId]"> View Details</div>
            </div>
            <div class="user-details">

              <p class="small-txt">Site Map</p>


            </div>
            <!-- Add map related place holder here -->
          </td>

        </tr>
      </tbody>
    </table>

  </div>
  <div class="documents-dialog__background"></div>
</div>