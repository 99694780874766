import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonUtilityServiceService {
  constructor() {}

  stateMap = new Map<string, string>([
    ['ALABAMA', 'Alabama'],
    ['ALASKA', 'Alaska'],
    ['ARIZONA', 'Arizona'],
    ['ARKANSAS', 'Arkansas'],
    ['CALIFORNIA', 'California'],
    ['COLORADO', 'Colorado'],
    ['CONNECTICUT', 'Connecticut'],
    ['DELAWARE', 'Delaware'],
    ['FLORIDA', 'Florida'],
    ['GEORGIA', 'Georgia'],
    ['HAWAII', 'Hawaii'],
    ['IDAHO', 'Idaho'],
    ['ILLINOIS', 'Illinois'],
    ['INDIANA', 'Indiana'],
    ['IOWA', 'Iowa'],
    ['KANSAS', 'Kansas'],
    ['KENTUCKY', 'Kentucky'],
    ['LOUISIANA', 'Louisiana'],
    ['MAINE', 'Maine'],
    ['MARYLAND', 'Maryland'],
    ['MASSACHUSETTS', 'Massachusetts'],
    ['MICHIGAN', 'Michigan'],
    ['MINNESOTA', 'Minnesota'],
    ['MISSISSIPPI', 'Mississippi'],
    ['MISSOURI', 'Missouri'],
    ['MONTANA', 'Montana'],
    ['NEBRASKA', 'Nebraska'],
    ['NEVADA', 'Nevada'],
    ['NEW_HAMPSHIRE', 'New Hampshire'],
    ['NEW_JERSEY', 'New Jersey'],
    ['NEW_MEXICO', 'New Mexico'],
    ['NEW_YORK', 'New York'],
    ['NORTH_CAROLINA', 'North Carolina'],
    ['NORTH_DAKOTA', 'North Dakota'],
    ['OHIO', 'Ohio'],
    ['OKLAHOMA', 'Oklahoma'],
    ['OREGON', 'Oregon'],
    ['PENNSYLVANIA', 'Pennsylvania'],
    ['RHODE_ISLAND', 'Rhode Island'],
    ['SOUTH_CAROLINA', 'South Carolina'],
    ['SOUTH_DAKOTA', 'South Dakota'],
    ['TENNESSEE', 'Tennessee'],
    ['TEXAS', 'Texas'],
    ['UTAH', 'Utah'],
    ['VERMONT', 'Vermont'],
    ['VIRGINIA', 'Virginia'],
    ['WASHINGTON', 'Washington'],
    ['WEST_VIRGINIA', 'West Virginia'],
    ['WISCONSIN', 'Wisconsin'],
    ['WYOMING', 'Wyoming'],
    ['PUERTO_RICO', 'Puerto Rico'],
    ['GUAM', 'Guam'],
    ['AMERICAN_SAMOA', 'American Samoa'],
    ['UNITED_STATES_VIRGIN_ISLANDS', 'United States Virgin Islands'],
    [
      'COMMONWEALTH_OF_THE_NORTHERN_MARIANA_ISLANDS',
      'Commonwealth of the Northern Mariana Islands',
    ],
  ]);

  formatAddress(data: any) {
    let streetAddress: string = '';
    let cityStateZip: string = '';
    if (data.site.parcels) {
      let addressDetails = data.site.parcels[0];
      if (addressDetails) {
        if (addressDetails.street) {
          streetAddress = addressDetails.street;
          streetAddress = streetAddress
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }

        if (addressDetails.city) {
          cityStateZip = cityStateZip + addressDetails.city + ', ';
        }
        if (addressDetails.state) {
          cityStateZip =
            cityStateZip + this.decodeUsState(addressDetails.state) + ', ';
        }
        if (addressDetails.zipcode) {
          cityStateZip = cityStateZip + addressDetails.zipcode;
        }
        cityStateZip = cityStateZip
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
    }
    return { streetAddress: streetAddress, cityStateZip: cityStateZip };
  }

  formatTableAandItem20(data: any) {
    let tableAItemName: string = '';
    let tableAItemValue: string = '';
    let item20textValue: string = '';
    data.site.tableA?.forEach((element: any) => {
      if (element.checked) {
        if (element.itemName?.trim() === '20') {
          tableAItemName += ', ' + element.itemName;
          item20textValue = element.textFieldValue;
        } else if (element.itemName?.trim() != '20') {
          tableAItemName += ', ' + element.itemName;
          if (element.value && element.itemName?.trim() === '19') {
            tableAItemName += ` ($${
              element.value === 1000000 ? '1M' : element.value
            } E&O)`;
          }
        }
      }
    });
    return [item20textValue, tableAItemName.replace(',', '')];
  }

  determineMapCenter(parcels: any) {
    if (parcels && parcels[0] && parcels[0].center) {
      return { lat: parcels[0].center[0], lng: parcels[0].center[1] };
    } else {
      // center of US
      return { lat: 39.8283, lng: -98.5795 };
    }
  }

  determineMapZoomLevel(acreage: number) {
    let zoom = 14;
    if (acreage < 1) {
      zoom = 18;
    } else if (acreage <= 100) {
      zoom = 17;
    } else if (acreage > 100) {
      zoom = 16;
    } else if (acreage > 200) {
      zoom = 15;
    }
    return zoom;
  }

  detemineMapBounderies(siteDetail: any): google.maps.LatLngLiteral[] {
    let boundaries: google.maps.LatLngLiteral[] = [];
    siteDetail.site.parcels.forEach((parcel: any) => {
      let currentSiteBoundaries = parcel.boundary.map((coordindates: any) => ({
        lat: coordindates[0],
        lng: coordindates[1],
      }));
      boundaries.push(currentSiteBoundaries);
    });
    return boundaries;
  }

  determineDocumentTypeByProposalStatus(status: string): string {
    switch (status) {
      case 'INVITATION':
      case 'PROPOSAL':
      case 'SUBMITTED':
      case 'OFFERED':
        return 'PROPOSAL';
      case 'ACTIVE':
        return 'DELIVERABLE';
      case 'REVISION_REQUESTED':
      case 'REVIEW_PENDING':
      case 'REVISION_PENDING':
        return 'REVISION';
      default:
        return '';
    }
  }

  decodeUsState(state: any): string {
    return this.stateMap.get(state) || '';
  }
}
