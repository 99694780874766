<div class="documents-dialog" *ngIf="active">
    <div class="documents-dialog__content">
        <button class="documents-dialog__content__close" (click)="closeDialog()"><span
                class="material-icons">close</span>Close</button>
        <h2>Order Submitted For Review.</h2>
        <br> <br>

        <div class="documents-dialog__content__footer">
            <div class="documents-dialog__content__footer__service-type">
                <p class="documents-dialog__content__footer__service-type__label">Service Type</p>
                <p class="service-type">ALTA/NSPS Land <br>Title Survey</p>
            </div>
            <div class="documents-dialog__content__footer__service-type">
                <p class="documents-dialog__content__footer__service-type__label">Location</p>
                <p class="location">3600 Prospect Street,<br>
                    Hill Valley, CA 95420</p>
            </div>
            <div class="btnalign">
                <button class="documents-dialog__content__footer__submit pull-right"
                    (click)="closeDialog()">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </div>
        </div>
    </div>
    <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>