<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <br />
    <table width="100%">
      <tbody>
        <tr>
          <td>
            <div class="tab-card">
              <div class="tab-header">
                <h2>
                  <span *ngIf="address.streetAddress"
                    >{{ address.streetAddress }}<br /></span
                  >{{ address.cityStateZip }}
                </h2>
              </div>
              <div
                class="btn-view-details"
                [routerLink]="['/', siteType, siteId]"
              >
                View Details
              </div>
            </div>
            <div class="user-details">
              <p class="small-txt">Site Map</p>
            </div>
            <google-map
              height="400px"
              width="100%"
              [center]="center"
              [zoom]="zoom"
              [options]="mapOptions"
              ><map-polygon
                [paths]="polygonPaths"
                [options]="polygonOptions"
              ></map-polygon
            ></google-map>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="documents-dialog__background"></div>
</div>
