import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'projectreview-completed',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: './project-review-completed.component.html',
  styleUrl: './project-review-completed.component.scss',
})
export class ProjectReviewCompletedComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    alert('submit');
    this.closeDialog();
  }
}
