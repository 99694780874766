import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { DocumentsDialog } from '../../documents-dialog/documents-dialog.component';
import { DeclineDialogComponent } from '../../decline-dialog/decline-dialog.component';
import { SubmitForReviewDialogComponent } from '../../submit-for-review-dialog/submit-for-review-dialog.component';
import { CommentsComponent } from '../../comments/comments.component';
import { NotesComponent } from '../../notes-dialog/notes.component';
import { BidSavedDialogComponent } from '../../bid-saved-dialog/bid-saved-dialog.component';
import { MapmodalDialogComponent } from '../../mapmodal-dialog/mapmodal-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonUtilityServiceService } from '../../services/common-utility-service.service';
import { ProposalService } from '../../services/proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../services/modal.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'project-overview-table-row',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    DocumentsDialog,
    DeclineDialogComponent,
    SubmitForReviewDialogComponent,
    CommentsComponent,
    NotesComponent,
    BidSavedDialogComponent,
    MapmodalDialogComponent,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './project-overview-table-row.component.html',
  styleUrl: './project-overview-table-row.component.scss',
})
export class ProjectOverviewTableRowComponent {
  @Input() data: any = '';
  @Input() id: string = '';
  @Input() status: string = '';
  @Input() site: number = 0;
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() acreage: number = 0;
  @Input() landUse: string = '';
  @Input() siteType: string = '';
  @Input() tableA: string = '';
  @Input() item20: any = '';
  @Input() dueDate: string = '';
  @Input() turnTime: number = 0;
  @Input() proposedDeliverDate: Date = new Date();
  @Input() bidAmount: number = 0;
  @Input() reviewStatus: string = '';
  @Input() type: string = '';
  @Input() showStatus: boolean = true;
  @Input() showSite: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() showAcreage: boolean = true;
  @Input() showLandUse: boolean = true;
  @Input() showSiteType: boolean = true;
  @Input() showDueDate: boolean = true;
  @Input() showTableA: boolean = true;
  @Input() showActions: boolean = true;
  @Input() bidNotes: string = '';
  @Input() rowIndex: number = 0;

  @Output() handleSiteUpdate = new EventEmitter<any>();
  @Output() handleEditBidAndTurnTime = new EventEmitter<any>();
  @Output() handleDeclineInvitation = new EventEmitter<any>();
  @Output() handleReviewSubmitted = new EventEmitter<any>();
  @Output() saveBidNotes = new EventEmitter<any>();
  @Output() handleUpdateDocuments = new EventEmitter<any>();

  serviceInfoToggle: boolean = false;
  documentsActive: boolean = false;
  editToggle: boolean = false;
  isDeclineActive: boolean = false;
  submitForReviewActive: boolean = false;
  commentsActive: boolean = false;
  notesActive: boolean = false;
  savedActive: boolean = false;
  mapActive: boolean = false;
  showReviewSubmission: boolean = false;
  showRevisionSubmission: boolean = false;
  isAlta: boolean = false;
  isFixedPrice: boolean = false;
  selectedSiteType: string = '';
  selectedSiteId: string = '';
  tableAItemName: string = '';
  tableAItemValue: string = '';
  currentBidAmount: number = 0;
  currentTurnTime: number = 0;
  minDate: Date = new Date();
  documents: any = [];

  constructor(
    private utilityService: CommonUtilityServiceService,
    private proposalService: ProposalService,
    private toastr: ToastrService,
    private modalService: ModalService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    this.address = this.utilityService.formatAddress(this.data);
    let response = this.utilityService.formatTableAandItem20(this.data);
    if (response) {
      this.item20 = response[0];
      this.tableA = response[1];
    }
    this.currentTurnTime = this.data.proposal.proposedTurnTime;
    this.currentBidAmount = this.data.proposal.bid;

    this.isAlta = this.data.site.serviceType === 'NEXUS_ALTA_SURVEY';
    this.isFixedPrice = this.data.proposal.fixedAmount;
    let documents: any = [];
    if (this.data.site.documents) {
      documents = [...documents, ...this.data.site.documents];
    }
    if (this.data.proposal.documents) {
      documents = [...documents, ...this.data.proposal.documents];
      this.showReviewSubmission = this.data.proposal.documents.some(
        (document: { type: string }) => document.type === 'DELIVERABLE'
      );
      this.showRevisionSubmission = this.data.proposal.documents.some(
        (document: { type: string }) => document.type === 'REVISION'
      );
    }
    this.documents = documents;
    this.modalService.isDocumentModalOpen.subscribe((open) => {
      if (this.data.site.id === open.siteId) {
        this.documentsActive = open.isOpen;
      }
    });
  }

  serviceInfo() {
    this.serviceInfoToggle = !this.serviceInfoToggle;
  }

  toggleCommentsModal() {
    this.commentsActive = !this.commentsActive;
  }

  toggleNotesModal() {
    this.notesActive = !this.notesActive;
  }

  toggleOpenDocumentsModal() {
    this.documentsActive = true;
    this.modalService.toogleOpenDocumentModal(this.data.site.id);
  }

  toggleCloseDocumentsModal() {
    this.documentsActive = false;
    this.modalService.toogleCloseDocumentModal();
  }

  toggleDeclineModal() {
    this.isDeclineActive = !this.isDeclineActive;
  }

  toggleSubmitForReviewModal() {
    this.submitForReviewActive = !this.submitForReviewActive;
  }

  toggleSaveModal() {
    this.savedActive = !this.savedActive;
  }

  toggleMapModal(siteType: string, siteId: string) {
    this.mapActive = !this.mapActive;
    this.selectedSiteType = siteType;
    this.selectedSiteId = siteId;
  }

  editProposal() {
    const change =
      this.bidAmount !== this.currentBidAmount ||
      this.turnTime !== this.currentTurnTime;

    if (this.editToggle && change) {
      if (this.bidAmount > 0 && this.turnTime > 0) {
        this.handleEditBidAndTurnTime.emit({
          siteId: this.id,
          bid: this.bidAmount,
          turnTime: this.turnTime,
        });
        this.currentBidAmount = this.bidAmount;
        this.currentTurnTime = this.turnTime;
        this.editToggle = !this.editToggle;
      } else {
        this.toastr.error(
          'Please provide a Bid Amount and Delivery Date/Turn Time.'
        );
      }
    } else {
      this.editToggle = !this.editToggle;
    }
  }

  declineProposal(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    this.handleDeclineInvitation.emit({
      proposal: {
        ...this.data.proposal,
        declineReason: event.declineReason,
        declineOtherReason: event.declineOtherReason,
        declineComments: event.declineComments,
      },
    });
  }

  submitProposal() {
    this.toggleSubmitForReviewModal();
  }

  saveTurnTime() {
    if (
      this.data.proposal?.status === 'INVITATION' &&
      this.turnTime !== this.currentTurnTime
    ) {
      this.handleSiteUpdate.emit({
        siteId: this.id,
        fieldName: 'turnTime',
        value: this.turnTime,
      });
      this.currentTurnTime = this.turnTime;
    }
  }

  saveBidAmount() {
    if (
      this.data.proposal?.status === 'INVITATION' &&
      this.bidAmount !== this.currentBidAmount
    ) {
      this.handleSiteUpdate.emit({
        siteId: this.id,
        fieldName: 'bidAmount',
        value: this.bidAmount,
      });
      this.currentBidAmount = this.bidAmount;
    }
  }

  saveBidNote(event: { fieldName: String; value: any }) {
    this.handleSiteUpdate.emit({
      siteId: this.id,
      fieldName: event.fieldName,
      value: event.value,
    });
  }

  submitReview() {
    this.proposalService
      .submitReadyForReview(this.data.proposal.id)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProposal = response.data;
          if (updatedProposal) {
            this.handleReviewSubmitted.emit({
              siteId: this.id,
              updatedProposal: updatedProposal,
            });
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  updateDocuments(event: { siteDetail: any }) {
    this.handleUpdateDocuments.emit({ updatedSiteDetail: event.siteDetail });
  }

  submitRevision() {
    this.proposalService
      .submitRevision(this.data.proposal.id)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProposal = response.data;
          if (updatedProposal) {
            this.handleReviewSubmitted.emit({
              siteId: this.id,
              updatedProposal: updatedProposal,
            });
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }
}
