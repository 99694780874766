<signatp-dialog [active]="signAtpActive" [address]="address" (modalClosed)="toggleATPModal()"
    [ngClass]="{'documents-dialog--active': signAtpActive}" [modalHeaderTitle]="childModalHeaderTitle"
    [modalBody]="childModalBody"></signatp-dialog>

<allbids-dialog [active]="allBidsActive" [documents]="documents" [address]="address"
    (modalClosed)="toggleSubmitAllBidsModal()" [ngClass]="{'documents-dialog--active': allBidsActive}"></allbids-dialog>
<div class="back-btn">
    <button (click)="goBack()">
        <span class="material-icons">keyboard_arrow_left</span>Back
    </button>
</div>
<div *ngFor="let group of data; let i = index">
    <div class="info-header" [ngClass]="{'expanded': expanded}">
        <div class="info-header__left">
            <button (click)="accordion()" class="info-header__left__button"><span
                    class="material-icons">keyboard_arrow_down</span></button>
            <h2 (click)="accordion()">{{ group?.projectName }}</h2>
        </div>
        <div class="info-header__right">
            <button class="info-header__right__atp" (click)="toggleSubmitAllBidsModal()"><span
                    class="material-icons">gavel</span>Submit All Bids</button>
            <button class="info-header__right__atp" (click)="toggleATPModal()"><span
                    class="material-icons">login</span>Sign
                ATP</button>
        </div>
    </div>
    <div class="info-table info-table--{{type}}" [style.grid-template-columns]="gridLayout">
        <ng-container *ngIf="expanded">
            <div class="info-table-header">
                <button *ngIf="showStatus" (click)="statusSort = filterToggle(statusSort, 'status')">Status<span
                        class="material-icons">filter_list</span></button>
                <button *ngIf="showSite" (click)="siteSort = filterToggle(siteSort, 'site')">Site<span
                        class="material-icons">filter_list</span></button>
                <button *ngIf="showAcreage" (click)="acreageSort = filterToggle(acreageSort, 'acreage')">Acreage<span
                        class="material-icons">filter_list</span></button>
                <button class="info-table-header__land-use" *ngIf="showLandUse"
                    (click)="landUseSort = filterToggle(landUseSort, 'landUse')">Land Use<span
                        class="material-icons">filter_list</span></button>
                <button class="info-table-header__service-type" *ngIf="showServiceType"
                    (click)="serviceTypeSort = filterToggle(serviceTypeSort, 'serviceType')">Service Type<span
                        class="material-icons">filter_list</span></button>
                <!-- <button *ngIf="showDueDate" (click)="dueDateSort = filterToggle(dueDateSort, 'dueDate')">Due Date<span
                        class="material-icons">filter_list</span></button> -->
                <button class="info-table-header__docs-info">Docs & Info</button>
                <button *ngIf="showActions" class="info-table-header__action"
                    (click)="actionsSort = filterToggle(actionsSort, 'actions')">Action<span
                        class="material-icons">filter_list</span></button>
                <div class="info-table-header__more">
                    <button (click)="more()"><span class="material-icons">more_vert</span></button>
                    <div *ngIf="moreOpen" class="info-table-header__more__menu">
                        <div class="info-table-header__more__menu__item"
                            (click)="showStatus = toggleColumn(showStatus); adjustTable()">
                            <span *ngIf="showStatus" class="material-icons">check</span>
                            <p>Status</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showSite = toggleColumn(showSite); adjustTable()">
                            <span *ngIf="showSite" class="material-icons">check</span>
                            <p>Location</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showAcreage = toggleColumn(showAcreage); adjustTable()">
                            <span *ngIf="showAcreage" class="material-icons">check</span>
                            <p>Acreage</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showLandUse = toggleColumn(showLandUse); adjustTable()">
                            <span *ngIf="showLandUse" class="material-icons">check</span>
                            <p>Land Use</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showServiceType = toggleColumn(showServiceType); adjustTable()">
                            <span *ngIf="showServiceType" class="material-icons">check</span>
                            <p>Service Type</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showDueDate = toggleColumn(showDueDate); adjustTable()">
                            <span *ngIf="showDueDate" class="material-icons">check</span>
                            <p>Proposal Due</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showServiceInfo = toggleColumn(showServiceInfo); adjustTable()">
                            <span *ngIf="showServiceInfo" class="material-icons">check</span>
                            <p>Service Info</p>
                        </div>
                        <div class="info-table-header__more__menu__item"
                            (click)="showActions = toggleColumn(showActions); adjustTable()">
                            <span *ngIf="showActions" class="material-icons">check</span>
                            <p>Action</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <project-overview-row-item *ngFor="let item of group.sites; let i = index" [id]="item.site.id" [rowIndex]="i"
        [data]="item" [status]="item.proposal.status" [site]="item.site.siteNumber"
        [acreage]="item.site.totalAcreage" [landUse]="item.site.landUse" [siteType]="item.site.siteType"
        [tableA]="tableA" [item20]="item20" [dueDate]="item.site.bidDueDate" [documents]="item.site.documents"
        [turnTime]="item.proposal.proposedTurnTime" [bidAmount]="item.proposal.bid" [reviewStatus]="item.reviewStatus"
        [type]="type" [showStatus]="showStatus" [showSite]="showSite" [showAcreage]="showAcreage"
        [showLandUse]="showLandUse" [showsiteType]="showsiteType" [showDueDate]="showDueDate"
        [showServiceInfo]="showServiceInfo" [showActions]="showActions">
    </project-overview-row-item>
</div>