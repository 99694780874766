import { Component, Inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'pdf-viewer',
  standalone: true,
  imports: [MatDialogModule, CommonModule],
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfComponent {
  pdfSrc: SafeResourceUrl; 
  filename: string = '';
 
  constructor(
    public dialogRef: MatDialogRef<PdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer 
  ) {
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(data.pdfSrc); 
    this.filename = data.filename;
  }

  handleClose() {
    this.dialogRef.close();
  }
}
