<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>{{ multiple ? "Accept All Offers?" : "Accept Offer?" }}</h2>

    <ng-container>
      <div class="">
        <div class="mb-10">
          {{
            multiple
              ? "By clicking “Accept All Offers,” you will be accepting " +
                numberOfAcceptedProposals +
                " offers."
              : "By clicking “Accept Offer”, you will be accepting the offer."
          }}
          <br /><br />
          Do you wish to continue?
        </div>
        <div *ngIf="data.specialQuoteRequirements" class="mb-10">
          <h3>Special Request Notes</h3>
          {{ data.specialQuoteRequirements }}
        </div>
        <br />
        <div class="documents-dialog__content__footer">
          <div *ngIf="multiple; else single">
            <div class="documents-dialog__content__footer__service-type">
              <p class="documents-dialog__content__footer__service-type__label">
                Project
              </p>
              <p class="service-type">{{ data.projectName }}</p>
            </div>
          </div>
          <ng-template #single>
            <div class="documents-dialog__content__footer__service-type">
              <p class="documents-dialog__content__footer__service-type__label">
                Service Type
              </p>
              <p class="documents-dialog__content__footer__service-type__text">
                {{ siteType }}
              </p>
            </div>
            <div class="documents-dialog__content__footer__location">
              <p class="documents-dialog__content__footer__location__label">
                Location
              </p>
              <p class="documents-dialog__content__footer__location__text">
                <span *ngIf="address.streetAddress"
                  >{{ address.streetAddress }}<br /></span
                >{{ address.cityStateZip }}
              </p>
            </div>
          </ng-template>
          <div class="right-columns panelfields">
            <div class="column">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="acceptOffer()"
              >
                {{ multiple ? "Accept All Offers" : "Accept Offer" }}
              </button>
            </div>
            <div class="column">
              <button
                type="submit"
                class="btn btn-default"
                (click)="closeDialog()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
