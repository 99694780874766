<div class="documents-dialog" *ngIf="active">
	<div class="documents-dialog__content">
		<button class="documents-dialog__content__close" (click)="closeDialog()"><span
				class="material-icons">close</span>Close</button>
		<h2>Decline RFP Invitation</h2>

		<div class="form-control">
			<label for="exampleFormControlInput1" class="reason-label">Reason </label>
			<br> <br>
			<select name="reason" id="reason" class="form-select" (change)="selectReason($event)">
				<option value="0">Please select a reason</option>
				<option value="1">The site is not within my service area.</option>
				<option value="2">This service is not within my scope.</option>
				<option value="3">I am currently unavailable.</option>
				<option value="4">The timeline to complete this project is too short.</option>
				<option value="5">Other (Please give more information below)</option>
			</select>

		</div>
		<input type="text" *ngIf="reasonSelectedIndex==5" placeholder="Reason" />
		<textarea class="text-area" id="documents-dialog-file-notes" placeholder="Comments" required></textarea>

		<div class="documents-dialog__content__footer">
			<div class="documents-dialog__content__footer__service-type">
				<p class="documents-dialog__content__footer__service-type__label">Service Type</p>
				<p class="service-type">ALTA/NSPS Land <br>Title Survey</p>
			</div>
			<div class="documents-dialog__content__footer__service-type">
				<p class="documents-dialog__content__footer__service-type__label">Location</p>
				<p class="location">742 Evergreen Terrace,<br> Springfield, IL 62704</p>
			</div>
			<div class="btnalign">
				<button class="documents-dialog__content__footer__submit pull-right" [disabled]="isDisabled"
					(click)="declineInvitation()">Decline</button>
				<button class="defaultbtn" (click)="closeDialog()">Cancel</button>
			</div>
		</div>
	</div>
	<div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>

<decline-success [active]="declineSuccessActive" (modalClosed)="toggleDeclineSuccessModal()"
	[ngClass]="{'documents-dialog--active': declineSuccessActive}"></decline-success>