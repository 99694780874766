import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DocumentsDialog } from '../documents-dialog/documents-dialog.component';
import { NotesComponent } from '../notes-dialog/notes.component';
import { BidDialogComponent } from '../bid-dialog/bid-dialog.component';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';
import { BidSavedDialogComponent } from '../bid-saved-dialog/bid-saved-dialog.component';
import { SignAtpDialogComponent } from '../sign-atp-dialog/sign-atp-dialog.component';
import { SubmitForReviewDialogComponent } from '../submit-for-review-dialog/submit-for-review-dialog.component';
import { ProjectService } from '../services/project.service';
import { User } from '../model/user';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';
import { SiteService } from '../services/site.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProposalService } from '../services/proposal.service';
import { FormsModule } from '@angular/forms';
import { AtpSignAcceptedDialogComponent } from '../atp-sign-accepted-dialog/atp-sign-accepted-dialog.component';
import { DeclineSuccessComponent } from '../decline-success/decline-success.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { environment } from '../../environments/environment';
import { DocumentService } from '../services/document.service';

@Component({
  standalone: true,
  selector: 'proposal-details',
  imports: [
    CommonModule,
    DocumentsDialog,
    NotesComponent,
    BidDialogComponent,
    DeclineDialogComponent,
    BidSavedDialogComponent,
    SignAtpDialogComponent,
    SubmitForReviewDialogComponent,
    AtpSignAcceptedDialogComponent,
    DeclineSuccessComponent,
    FormsModule,
    GoogleMapsModule,
  ],
  templateUrl: './proposal-details.component.html',
  styleUrl: './proposal-details.component.scss',
})
export class ProposalDetails {
  @Input() address: any = { streetAddress: '', cityStateZip: '' };
  @Input() siteType: string = '';
  @Input() documents: any = [];
  @Input() modalHeaderTitle: string = '';
  @Input() modalBody: string = '';
  @Input() atpDocumentPath: SafeResourceUrl = '';
  @Input() isDeclineSuccessActive: boolean = false;
  @Input() bidNotes: string = '';

  id: string = '';
  data: any = {};
  projectName: string = '';
  tabState: string = 'rfp';
  activeTab: string = 'rfp-info';
  type: string = '';
  activeBreadcrumb: any = [false, false, false, false, false];
  currentConversation: string = '';
  documentsActive: boolean = false;
  notesActive: boolean = false;
  bidActive: boolean = false;
  isDeclineActive: boolean = false;
  savedActive: boolean = false;
  signAtpActive: boolean = false;
  submitForReviewActive: boolean = false;
  showReviewSubmission: boolean = false;
  showRevisionSubmission: boolean = false;
  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  tableA: string = '';
  tableAItemName: string = '';
  tableAItemValue: string = '';
  item20: string = '';
  project: any = null;
  atpDocument: any;
  bidAmount: number = 0;
  turnTime: number = 0;
  isATPSignedAccepted: boolean = false;
  childModalHeaderTitle: string = 'ATP Signed & Proposal Accepted';
  childModalBody: string =
    'You have signed the ATP and proposal is available in Active projects.';

  // google map
  center: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 };
  zoom: number = 4;
  mapOptions: google.maps.MapOptions = {};
  polygonPaths: google.maps.LatLngLiteral[] = [];
  polygonOptions: google.maps.PolygonOptions = {
    fillColor: '#6296ff',
    fillOpacity: 0.25,
    strokeColor: '#537ed5',
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    editable: false,
    draggable: false,
  };
  submitBidDisabled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private projectService: ProjectService,
    private utilityService: CommonUtilityServiceService,
    private siteService: SiteService,
    private proposalService: ProposalService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.activeTab = params['tab'] || 'rfp-info';
    });

    this.route.data.subscribe((data) => {
      this.type = data['type'];
    });

    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id') || '';
      this.projectService.fetchData(this.user.id ?? '').subscribe({
        next: (result) => {
          this.data = result
            .map((parent: any) => ({
              ...parent,
              sites: parent.sites.filter(
                (child: any) => child.site.id === this.id
              ),
            }))
            .filter(
              (parent: any) => parent.sites.length > 0 && parent.projectId
            );
          this.mapOptions = {
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControl: false,
          };
          if (this.data[0]) {
            this.address = this.utilityService.formatAddress(
              this.data[0].sites[0]
            );
            let response = this.utilityService.formatTableAandItem20(
              this.data[0].sites[0]
            );
            if (response) {
              this.item20 = response[0];
              this.tableA = response[1];
            }
            this.dataConversations();
            if (this.type === 'invitation') {
              // after bid is submitted
              if (this.data[0].sites[0].proposal.status === 'SUBMITTED') {
                this.activeBreadcrumb[1] = true;
                this.activeBreadcrumb[0] = false;
              } else {
                this.activeBreadcrumb[0] = true;
              }
            }
            if (this.type === 'proposal') {
              // after signing atp, status will be active
              if (
                this.data[0].sites[0].proposal.status === 'ACTIVE' ||
                this.data[0].sites[0].proposal.status === 'BID_LOST'
              ) {
                this.activeBreadcrumb[2] = true;
                this.activeBreadcrumb[1] = false;
              } else {
                this.activeBreadcrumb[1] = true;
              }
            }
            if (this.type === 'active') {
              this.activeBreadcrumb[2] = true;
            }
            if (
              this.type === 'active' &&
              (this.data[0].sites[0].proposal.status === 'IN_REVIEW' ||
                this.data[0].sites[0].proposal.status === 'REVISION_REQUESTED')
            ) {
              this.activeBreadcrumb[3] = true;
            }
            if (
              this.type === 'active' &&
              this.data[0].sites[0].proposal.status === 'COMPLETED'
            ) {
              this.activeBreadcrumb[4] = true;
            }
            this.bidAmount = this.data[0].sites[0].proposal.bid;
            this.turnTime = this.data[0].sites[0].proposal.proposedTurnTime;
            this.bidNotes = this.data[0].sites[0].proposal.bidNotes;
            if (this.bidAmount > 0 && this.turnTime > 0) {
              this.submitBidDisabled = false;
            } else {
              this.submitBidDisabled = true;
            }

            this.siteType = this.data[0].sites[0].site.siteType;

            this.center = this.utilityService.determineMapCenter(
              this.data[0].sites[0].site.parcels
            );
            this.zoom = this.utilityService.determineMapZoomLevel(
              this.data[0].sites[0].site.totalAcreage
            );
            this.polygonPaths = this.utilityService.detemineMapBounderies(
              this.data[0].sites[0]
            );
            let documents: any = [];
            if (this.data[0].sites[0].site.documents) {
              documents = [
                ...documents,
                ...this.data[0].sites[0].site.documents,
              ];
            }
            if (this.data[0].sites[0].proposal.documents) {
              documents = [
                ...documents,
                ...this.data[0].sites[0].proposal.documents,
              ];
              this.showReviewSubmission =
                this.data[0].sites[0].proposal.documents.some(
                  (document: { type: string }) =>
                    document.type === 'DELIVERABLE'
                );
              this.showRevisionSubmission =
                this.data[0].sites[0].proposal.documents.some(
                  (document: { type: string }) => document.type === 'REVISION'
                );
            }
            this.documents = documents;
          }
        },
      });
    });
  }

  dataConversations() {
    if (this.data.conversations) {
      // order messages by date
      for (let conversation of this.data.conversations) {
        conversation.messages.sort((a: any, b: any) => {
          let res = 0;
          let aDate = new Date(a.date);
          let bDate = new Date(b.date);
          if (aDate < bDate) res = -1;
          if (aDate > bDate) res = 1;
          return res;
        });
      }

      // order conversations by date of last message
      this.data.conversations.sort((a: any, b: any) => {
        let res = 0;
        let aDate = new Date(a.messages[a.messages.length - 1].date);
        let bDate = new Date(b.messages[b.messages.length - 1].date);
        if (aDate < bDate) res = 1;
        if (aDate > bDate) res = -1;
        return res;
      });

      this.currentConversation = this.data.conversations[0].id;
    }
  }

  goBack() {
    window.history.back();
  }

  changeConversation(conversation: string) {
    this.currentConversation = conversation;
  }

  toggleTab(tab: string) {
    this.activeTab = tab;
    // add selected tab to end of url without adding to history
    this.location.replaceState(
      this.location.path().split('?')[0],
      `tab=${tab}`
    );
  }

  toggleDocumentsModal() {
    this.documentsActive = !this.documentsActive;
  }

  toggleNotesModal() {
    this.notesActive = !this.notesActive;
  }

  toggleSubmitBidModal() {
    this.bidActive = !this.bidActive;
  }

  sendMessage() {
    alert('send message');
  }

  toggleATPModal() {
    this.signAtpActive = !this.signAtpActive;
  }

  toggleAtpAcceptedModal() {
    this.isATPSignedAccepted = !this.isATPSignedAccepted;
  }

  saveTurnTime() {
    const siteDetail = this.data[0].sites[0];
    const projectDetail = this.data[0];
    if (this.turnTime !== siteDetail.proposal.proposedTurnTime) {
      const updatedProposal = {
        ...siteDetail.proposal,
        proposedTurnTime: this.turnTime,
      };
      this.proposalService.saveProposal(updatedProposal);
      const updatedSiteDetail = { ...siteDetail, proposal: updatedProposal };
      this.data = updatedSiteDetail;
      const updatedProjectDetail = {
        ...projectDetail,
        sites: [updatedSiteDetail],
      };
      this.projectService.updateProjectDataSubjectWithSingleSite(
        updatedProjectDetail
      );
    }
  }

  saveBidAmount() {
    const siteDetail = this.data[0].sites[0];
    const projectDetail = this.data[0];
    if (this.bidAmount !== siteDetail.proposal.bid) {
      const updatedProposal = { ...siteDetail.proposal, bid: this.bidAmount };
      this.proposalService.saveProposal(updatedProposal);
      const updatedSiteDetail = { ...siteDetail, proposal: updatedProposal };
      this.data = updatedSiteDetail;
      const updatedProjectDetail = {
        ...projectDetail,
        sites: [updatedSiteDetail],
      };
      this.projectService.updateProjectDataSubjectWithSingleSite(
        updatedProjectDetail
      );
    }
  }

  submitBid() {
    this.toggleSubmitBidModal();
  }

  saveBid() {
    if (this.bidAmount > 0 && this.turnTime > 0) {
      const updatedProposal = {
        ...this.data[0].sites[0].proposal,
        bid: this.bidAmount,
        proposedTurnTime: this.turnTime,
      };
      this.proposalService.editBid(updatedProposal);
      const updatedSiteDetail = {
        ...this.data[0].sites[0],
        proposal: updatedProposal,
      };
      const updatedProjectDetail = {
        ...this.data[0],
        sites: [updatedSiteDetail],
      };
      this.projectService.updateProjectDataSubjectWithSingleSite(
        updatedProjectDetail
      );
      this.toggleSaveModal();
    } else {
      this.toastr.error('Please provide a Bid Amount and Turn Time.');
    }
  }

  decline(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    const updatedProposal = {
      ...this.data[0].sites[0].proposal,
      declineReason: event.declineReason,
      declineOtherReason: event.declineOtherReason,
      declineComments: event.declineComments,
    };
    this.proposalService
      .declineInvitation(updatedProposal)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const proposal = apiResponse.data;
          if (proposal) {
            this.toggleDeclineSucessModal();
            const updatedSiteDetail = {
              ...this.data[0].sites[0],
              proposal: proposal,
            };
            const updatedProjectDetail = {
              ...this.data[0],
              sites: [updatedSiteDetail],
            };
            this.projectService.updateProjectDataSubjectWithSingleSite(
              updatedProjectDetail
            );
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  generateATP() {
    this.toastr.info(
      'Please wait a moment while we generate your ATP document. This process may take a few seconds.'
    );
    let atpDocumentResponse: any = null;
    this.siteService
      .generateAtp(this.data[0], this.user.id ?? '')
      .subscribe((response) => {
        if (response) {
          atpDocumentResponse = response;
          if (
            atpDocumentResponse.status === 'SUCCESS' &&
            atpDocumentResponse.data
          ) {
            this.atpDocument = atpDocumentResponse.data;
            if (this.atpDocument.documentPath) {
              this.atpDocumentPath =
                this.sanitizer.bypassSecurityTrustResourceUrl(
                  this.atpDocument.documentPath
                );
              this.toggleATPModal();
            } else {
              this.toastr.error(
                'There was an issue with genereating your ATP document. Please try again.'
              );
            }
          } else {
            this.toastr.error(atpDocumentResponse.message);
          }
        } else {
          this.toastr.error(
            'There was an issue with genereating your ATP document. Please try again.'
          );
        }
      });
  }

  signATP(event: { fullName: string; title: string }) {
    this.toastr.info('Accepting your signed ATP...');
    this.siteService
      .signAtp(
        {
          projectDetail: this.data[0],
          atpDocument: this.atpDocument,
          atpSignedBy: event.fullName,
          atpSignedByTitle: event.title,
        },
        this.user.id ?? ''
      )
      .subscribe((response) => {
        const updatedSingleSiteProjectDetail = response.data;
        this.projectService.updateProjectDataSubjectWithSingleSite(
          updatedSingleSiteProjectDetail
        );
        this.signAtpActive = false;
        this.toggleAtpAcceptedModal();
      });
  }

  declineATP(event: {
    declineReason: string;
    declineOtherReason: string;
    declineComments: string;
  }) {
    const siteDetail = this.data[0].sites[0];
    const updatedProposal = {
      ...siteDetail.proposal,
      declineReason: event.declineReason,
      declineOtherReason: event.declineOtherReason,
      declineComments: event.declineComments,
    };
    const updatedSiteDetail = { ...siteDetail, proposal: updatedProposal };
    this.siteService
      .cancel({ ...this.data[0], sites: [updatedSiteDetail] })
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProjectDetail = response.data;
          if (updatedProjectDetail) {
            this.projectService.updateProjectDataSubjectWithProjectDetail(
              updatedProjectDetail
            );
            this.toggleDeclineSucessModal();
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  submitForReview() {
    this.toggleSubmitForReviewModal();
  }

  toggleDeclineModal() {
    this.isDeclineActive = !this.isDeclineActive;
  }

  toggleDeclineSucessModal() {
    this.isDeclineSuccessActive = !this.isDeclineSuccessActive;
  }

  toggleSaveModal() {
    this.savedActive = !this.savedActive;
  }

  toggleSubmitForReviewModal() {
    this.submitForReviewActive = !this.submitForReviewActive;
  }

  submitReview() {
    this.proposalService
      .submitReadyForReview(this.data[0].sites[0].proposal.id)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProposal = response.data;
          if (updatedProposal) {
            const updatedSiteDetail = {
              ...this.data[0].sites[0],
              proposal: updatedProposal,
            };
            const updatedProjectDetail = {
              ...this.data[0],
              sites: [updatedSiteDetail],
            };
            this.projectService.updateProjectDataSubjectWithSingleSite(
              updatedProjectDetail
            );
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  submitRevision() {
    this.proposalService
      .submitRevision(this.data[0].sites[0].proposal.id)
      .subscribe((response: any) => {
        const apiResponse = response;
        if (apiResponse.status === 'FAILURE') {
          if (apiResponse.message) {
            this.toastr.error(apiResponse.message);
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        } else {
          const updatedProposal = response.data;
          if (updatedProposal) {
            const updatedSiteDetail = {
              ...this.data[0].sites[0],
              proposal: updatedProposal,
            };
            const updatedProjectDetail = {
              ...this.data[0],
              sites: [updatedSiteDetail],
            };
            this.projectService.updateProjectDataSubjectWithSingleSite(
              updatedProjectDetail
            );
          } else {
            this.toastr.error('An error occurred. Please try again.');
          }
        }
      });
  }

  saveBidNote(event: { fieldName: String; value: any }) {
    const siteDetail = this.data[0].sites[0];
    const projectDetail = this.data[0];
    if (event.value !== siteDetail.proposal.bidNotes) {
      const updatedProposal = {
        ...siteDetail.proposal,
        bidNotes: event.value,
      };
      this.proposalService.saveProposal(updatedProposal);
      const updatedSiteDetail = { ...siteDetail, proposal: updatedProposal };
      this.data = updatedSiteDetail;
      const updatedProjectDetail = {
        ...projectDetail,
        sites: [updatedSiteDetail],
      };
      this.projectService.updateProjectDataSubjectWithSingleSite(
        updatedProjectDetail
      );
    }
  }

  updateDocuments(event: { siteDetail: any }) {
    const updatedProjectDetail = {
      ...this.data[0],
      sites: [event.siteDetail],
    };
    this.data = updatedProjectDetail;
    this.projectService.updateProjectDataSubjectWithSingleSite(
      updatedProjectDetail
    );
  }

  downloadDocument(document: any) {
    this.toastr.info('Downloading document...');
    this.documentService.downloadDocument(document);
  }
}
