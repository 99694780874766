import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'accepted-offered-proposal-dialog',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './accepted-offered-proposal-dialog.component.html',
  styleUrl: './accepted-offered-proposal-dialog.component.scss',
})
export class AcceptedOfferedProposalDialogComponent {
  @Input() active: boolean = false;
  @Input() multiple: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }

  submit() {
    this.closeDialog();
  }
}
