import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { User } from '../model/user';
import { CommonUtilityServiceService } from '../services/common-utility-service.service';
import { DocumentService } from '../services/document.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  selector: 'documents-dialog',
  imports: [CommonModule, DragDropModule],
  templateUrl: './documents-dialog.component.html',
  styleUrl: './documents-dialog.component.scss',
})
export class DocumentsDialog {
  @Input() data: any = {};
  @Input() active: boolean = false;
  @Input() documents: any = [];
  @Input() documentType: string = '';
  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<any>();

  user: User = {};
  pendingUploadDocumentRequests: any = [];

  constructor(
    private utilityService: CommonUtilityServiceService,
    private documentService: DocumentService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  }

  closeDialog() {
    this.active = false;
    this.pendingUploadDocumentRequests = [];
    this.modalClosed.emit(this.active);
  }

  onFileSelect(event: any) {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      this.processDocumentList(event?.target?.files);
    }
  }

  onDragOver(event: DragEvent) {
    // preventing the default browser action on drag over
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    // preventing the default browser action on drop
    event.preventDefault();
    if (event?.dataTransfer?.files && event?.dataTransfer?.files?.length > 0) {
      this.processDocumentList(event.dataTransfer.files);
    }
  }

  download(document: any) {
    this.toastr.info('Downloading document...');
    this.documentService.downloadDocument(document);
  }

  deleteExistingDocument(document: any) {
    this.toastr.info('Deleting document...');
    this.documentService
      .deleteProposalDocument(document.id)
      .subscribe((response: any) => this.processResponse(response));
  }

  deletePendingDocument(document: any) {
    this.pendingUploadDocumentRequests =
      this.pendingUploadDocumentRequests.filter(
        (doc: any) => doc.file.name !== document.file.name
      );
  }

  submit() {
    if (this.pendingUploadDocumentRequests?.length > 0) {
      this.toastr.info('Uploading document(s)...');
      this.documentService
        .uploadPropsalDocuments(
          this.pendingUploadDocumentRequests,
          this.data.proposal
        )
        .subscribe((response: any) => this.processResponse(response));
    }
    this.pendingUploadDocumentRequests = [];
    this.closeDialog();
  }

  private processDocumentList(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      // creating object for display purposes
      this.pendingUploadDocumentRequests.push({
        fileName: files[i].name,
        type: this.utilityService.determineDocumentTypeByProposalStatus(
          this.data.proposal.status
        ),
        file: files[i],
      });
    }
  }

  private processResponse(response: any) {
    const apiResponse = response;
    if (apiResponse.status === 'FAILURE') {
      if (apiResponse.message) {
        this.toastr.error(apiResponse.message);
      } else {
        this.toastr.error('An error occurred. Please try again.');
      }
    } else {
      const updatedProposal = response.data;
      if (updatedProposal) {
        this.toastr.success(apiResponse.message);
        this.data = { ...this.data, proposal: updatedProposal };
        this.update.emit({ siteDetail: this.data });
      } else {
        this.toastr.error('An error occurred. Please try again.');
      }
    }
  }
}
