import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'notes-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
})
export class NotesComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = {};
  @Input() bidNotes: string = '';
  @Output() saveBidNotes = new EventEmitter<any>();

  @Input() siteType: string = '';
  @Input() address: any = { streetAddress: '', cityStateZip: '' };

  ngOnInit() {
    this.bidNotes = this.data?.proposal?.bidNotes;
  }

  closeDialog() {
    this.active = false;
    this.bidNotes = this.data.proposal.bidNotes
    this.modalClosed.emit(this.active);
  }

  submit() {
    this.saveBidNotes.emit({
      fieldName: 'bidNotes',
      value: this.bidNotes,
    });
    this.closeDialog();
  }
}
