<div class="documents-dialog" *ngIf="active">
  <div class="documents-dialog__content">
    <button class="documents-dialog__content__close" (click)="closeDialog()">
      <span class="material-icons">close</span>Close
    </button>
    <h2>Submit Bid?</h2>

    <ng-container>
      <div class="">
        <p>Special Quote Requirements:</p>
        <div>
          <p [ngStyle]="{ display: 'inline' }">
            {{ data.orderInformation.specialQuoteRequirements }}
          </p>
        </div>
        <br /><br />

        <div class="mb-10">
          Clicking “Submit Bid” will send project for review. <br /><br />
          Do you wish to continue?
        </div>

        <br />
        <br />
        <div class="container mt-30">
          <div class="column column-left">
            <div class="documents-dialog__content__footer__service-type">
              <p class="documents-dialog__content__footer__service-type__label">
                Project
              </p>
              <p class="location">Taco Bell Holdings</p>
            </div>
          </div>
          <div class="right-columns panelfields">
            <div class="column">
              <button type="submit" class="btn btn-primary" (click)="submit()">
                Submit Bid
              </button>
            </div>
            <div class="column">
              <button
                type="submit"
                class="btn btn-default"
                (click)="closeDialog()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="documents-dialog__background" (click)="closeDialog()"></div>
</div>
<bidsubmitted-dialog
  [active]="bidsubmittedActive"
  (modalClosed)="toggleBidsubmittedModal()"
  [ngClass]="{ 'documents-dialog--active': bidsubmittedActive }"
></bidsubmitted-dialog>
