<div class="pdf-header">
  <h1>{{ filename }}</h1>
  <button class="close-button" (click)="handleClose()">
    <span class="material-icons">close</span>Close
  </button>
</div>
<div class="pdf-viewer-content">
  <iframe [src]="pdfSrc"  type="application/pdf"></iframe>
</div>

