import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
  selector: 'info-navigation',
	imports: [RouterLink, CommonModule],
  templateUrl: './info-navigation.component.html',
	styleUrl: './info-navigation.component.scss'
})
export class InfoNavigation {
	@Input() type: string = '';

	projectHistory(){
		alert('projectHistory');
	}
}
