<div class="info-table-item">
  <ng-container *ngIf="showStatus">
    <p class="status --completed">Completed</p>
  </ng-container>
  <div class="address" *ngIf="showSite">
    <p class="address__site">#{{ site }}</p>
    <div class="address__address">
      <span
        class="material-icons address-pointer"
        (click)="toggleMapModal(type, id)"
        >info_outline</span
      >
      <div class="address-pointer" [routerLink]="['/', type, id]">
        <p>
          <span *ngIf="address.streetAddress"
            >{{ address.streetAddress }}<br /></span
          >{{ address.cityStateZip }}
        </p>
      </div>
    </div>
  </div>
  <p *ngIf="showAcreage" class="table-row-font">{{ acreage }}</p>
  <div
    *ngIf="showSiteType"
    class="address-pointer"
    [routerLink]="['/', type, id]"
  >
    <div class="service-type">
      <p>{{ siteType }}</p>
    </div>
  </div>
  <p *ngIf="showLandUse" class="table-row-font">
    {{ landUse }}
  </p>

  <div *ngIf="showTableA">
    <div *ngIf="isAlta; else noTableA" class="service-info">
      <span
        *ngIf="item20"
        class="material-icons"
        (mouseenter)="serviceInfoToggle = true"
        (mouseleave)="serviceInfoToggle = false"
        >info_outline</span
      >
      <div class="service-details">
        <p class="table-row-font">
          {{ tableA }}
        </p>
      </div>

      <div class="service-info__info" [ngClass]="{ active: serviceInfoToggle }">
        <p class="service-info__info__label">Table</p>

        <p class="service-details__content">
          {{ tableA }}
        </p>
        <div *ngIf="item20">
          <p class="service-info__info__label">Item 20</p>
          <p class="service-info__info__content">
            {{ item20 ? item20 : "N/A" }}
          </p>
        </div>
      </div>
    </div>

    <ng-template #noTableA>
      <div class="service-details">
        <p class="table-row-font">N/A</p>
      </div>
    </ng-template>
  </div>

  <div class="info-table-item__docs-info">
    <span class="info-table-item__icon-text">
      <div class="documents" (click)="toggleOpenDocumentsModal()">
        <span class="material-icons">upload</span>
        <div *ngIf="documents?.length > 0" class="documents__number">
          {{ documents?.length }}
        </div>
      </div>
      <span class="documents-label">Documents</span>
    </span>
    <span class="info-table-item__icon-text">
      <div class="documents" (click)="toggleNotesModal()">
        <span class="material-icons">notes</span>
      </div>
      <span class="documents-label">Notes</span>
    </span>
  </div>
  <ng-container *ngIf="showActions">
    <div class="proposals-actions">
      <div class="turn-time">
        <p>Delivery Date</p>
        <p>{{ deliveryDate | date : "MM/dd/yyyy" : locale }}</p>
      </div>
      <div class="bid-amount">
        <p>Bid Amount</p>
        <p>${{ bidAmount }}</p>
      </div>
    </div>
  </ng-container>
  <div class="info-table-button address-pointer" [routerLink]="['/', type, id]">
    <span class="material-icons">visibility</span>
    <p>View</p>
  </div>

  <mapmodal-dialog
    [active]="mapActive"
    [siteType]="selectedSiteType"
    [siteId]="selectedSiteId"
    [data]="data"
    [address]="address"
    (modalClosed)="toggleMapModal(type, id)"
    [ngClass]="{ 'mapmodal-dialog--active': mapActive }"
  ></mapmodal-dialog>
</div>

<documents-dialog
  [data]="data"
  [active]="documentsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleCloseDocumentsModal()"
  (update)="updateDocuments($event)"
  [ngClass]="{ 'documents-dialog--active': documentsActive }"
></documents-dialog>

<notes-dialog
  [active]="notesActive"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleNotesModal()"
  [ngClass]="{ 'documents-dialog--active': notesActive }"
  [bidNotes]="bidNotes"
  [data]="data"
></notes-dialog>

<comments-dialog
  [active]="commentsActive"
  [documents]="documents"
  [siteType]="siteType"
  [address]="address"
  (modalClosed)="toggleCommentsModal()"
  [ngClass]="{ 'documents-dialog--active': commentsActive }"
></comments-dialog>
