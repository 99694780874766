import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'bidsubmitted-dialog',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink],
  templateUrl: './bid-submitted-dialog.component.html',
  styleUrl: './bid-submitted-dialog.component.scss',
})
export class BidSubmittedDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';

  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  submit() {
    alert('submit');
    this.closeDialog();
  }
}
