import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BidSubmittedDialogComponent } from '../bid-submitted-dialog/bid-submitted-dialog.component';
@Component({
  selector: 'bid-dialog',
  standalone: true,
  imports: [RouterOutlet, CommonModule, BidSubmittedDialogComponent],
  templateUrl: './bid-dialog.component.html',
  styleUrl: './bid-dialog.component.scss',
})
export class BidDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() data: any = '';
  @Input() documents: any = '';
  @Input() serviceType: string = '';
  @Input() address: string = '';
  bidsubmittedActive: boolean = false;
  closeDialog() {
    this.active = false;
    this.modalClosed.emit(this.active);
  }
  toggleBidsubmittedModal() {
    this.bidsubmittedActive = !this.bidsubmittedActive;
  }
  submit() {
    this.toggleBidsubmittedModal();
    this.closeDialog();
  }
}
