import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule,Location } from '@angular/common';
import { NotificationService } from '../services/notification.service';
import { User } from '../model/user';

@Component({
  selector: 'notification-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-dialog.component.html',
  styleUrl: './notification-dialog.component.scss'
})

export class NotificationDialogComponent {
  @Input() active: boolean = false;
  @Output() modalClosed = new EventEmitter<boolean>();

  user = <User>JSON.parse(localStorage.getItem('user') ?? '');
  activeTab: string = 'unread';

	constructor(
		private location: Location,
    private notificationService: NotificationService
	  ) {}


    readNotifications = [
      { icon: 'edit', message: 'Read 1 message', timeAgo: '3 days ago' },
      { icon: 'person', message: 'Read 2 message', timeAgo: '36 days ago' },
      { icon: 'note_alt', message: 'Read 3 message', timeAgo: '16 days ago' },
    ];

    unreadNotifications = [
      { icon: 'edit', message: 'Unread 1 message', timeAgo: '3 days ago' },
      { icon: 'person', message: 'Unread 2 message', timeAgo: '36 days ago' },
      { icon: 'note_alt', message: 'Unread 3 message', timeAgo: '16 days ago' },
    ];

    markAllNotifications = [
      { icon: 'edit', message: 'Proposal Declined for project turn time 2', timeAgo: '3 days ago' },
      { icon: 'person', message: 'Sean Roche posted message for THREE TWO NINE TESTING', timeAgo: '36 days ago' },
      { icon: 'note_alt', message: 'Invitation to Submit Proposal from sroche for THREE TWO NINE TESTING', timeAgo: '16 days ago' },
    ];

    ngOnInit() {
      this.fetchData()
    }

    fetchData() {
      this.notificationService.getVendorNotifications(this.user.id ?? '').subscribe((result: any) => {
        if (result.length > 0) {
        this.unreadNotifications = result.filter((notification: any) => !notification.read)
        this.readNotifications = result.filter((notification: any) => notification.read)
        }
      })
    }

	toggleTab(tab: string) {
		this.activeTab = tab;
		this.location.replaceState(
		  this.location.path().split('?')[0],
		  `tab=${tab}`
		);
	  }

    closeDialog() {
      this.active = false;
      this.modalClosed.emit(this.active);
    }
    
  markAllAsRead() {
    if( confirm("Are you sure to mark all notifications as read?.")){
    alert('All notifications marked as read');
    this.notificationService.markNotificationRead(this.unreadNotifications)
   }
  }

  markAsRead(notification: any) {
    if (!notification.read) {
      this.notificationService.markNotificationRead([notification])
    }
  }

  unread(){
    alert("Unread");
  }

  read(){
    alert("Read");
  }
}
