import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
  selector: 'page-header',
	imports: [RouterLink, CommonModule],
  templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class Header{

	calendar(){
		alert('calendar');
	}

	notifications(){
		alert('notifications');
	}

	myAccount(){
		alert('myAccount');
	}

	search(){
		alert('search');
	}
	
}
